import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'firebase/auth';
import * as Types from 'modules/auth/types';
import { IStore } from 'store';

export interface IAuth {
  user: Types.IEntity.User.Main | null;
  isLogined: boolean;
  isFetched: boolean;
  accessToken: string | null;
  isVerified: boolean;
  userID: string;
}

const initialState: IAuth = {
  user: null,
  isLogined: false,
  isVerified: false,
  isFetched: false,
  accessToken: '',
  userID: ''
};

/**
 * Actions and Reducers
 */
const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(auth: IAuth, { payload }: PayloadAction<IAuth['user']>) {
      auth.user = payload;
      auth.isFetched = true;
      auth.isLogined = true;
    },
    logout(auth: IAuth) {
      auth.user = null;
      auth.isFetched = true;
      auth.isLogined = false;
      auth.isVerified = false;
      auth.accessToken = null;
    },
    changeToken(auth: IAuth, { payload }: PayloadAction<IAuth['accessToken']>) {
      auth.accessToken = payload;
    },
    changeVerified(auth: IAuth, { payload }: PayloadAction<IAuth['isVerified']>) {
      auth.isVerified = payload;
    },
    changeFirebaseData(auth: IAuth, { payload }: PayloadAction<User & { accessToken: string }>) {
      auth.userID = payload.uid;
      auth.accessToken = payload.accessToken;
      auth.isVerified = payload.emailVerified;
      auth.isFetched = true;
      auth.isLogined = true;
    }
  }
});

export const { login, logout, changeToken, changeVerified, changeFirebaseData } = authSlice.actions;
export default authSlice.reducer;

// Selectors
export const getUser = (store: IStore) => store.auth.user;
export const getAccessToken = (store: IStore) => store.auth.accessToken;
export const getIsFetched = (store: IStore) => store.auth.isFetched;
export const getIsLogined = (store: IStore) => store.auth.isLogined;
export const getIsVerified = (store: IStore) => store.auth.isVerified;
export const getUserID = (store: IStore) => store.auth.userID;
