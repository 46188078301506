import React from 'react';
import { Switch } from 'antd';
import cx from 'classnames';
import { Control, Controller } from 'react-hook-form';

import Icon from 'components/icon';

import cls from './switcher.module.scss';

export interface SwitchProps {
  name: string;
  control: Control<any>;
  errorMsg?: string;
}

const Switcher: React.FC<SwitchProps> = ({ control, name, errorMsg }) => (
  <div className={cls.wrapper}>
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label className={cx(cls.container, errorMsg && cls.error, cls.active, cls.disabled)}>
      Requires styled simulation?
      <Controller control={control} name={name} render={({ field }) => <Switch {...field} checked={field.value} />} />
    </label>
    {errorMsg && (
      <div className={cls.message}>
        <Icon name="exclamation" /> <span>{errorMsg}</span>
      </div>
    )}
  </div>
);

export default Switcher;
