import React from 'react';

const Suitcase2: React.FC = () => (
  <svg width="24" height="21" viewBox="0 0 24 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.42471 0.675547C8.73542 0.826173 8.20776 1.3656 8.07738 2.05304C8.05296 2.18199 8.03294 2.74943 8.03294 3.31395V4.34045L5.46264 4.34112C2.67505 4.34184 2.60394 4.34714 2.0844 4.59312C1.7924 4.7314 1.40113 5.071 1.2044 5.35698C1.13068 5.46406 1.01565 5.68827 0.948804 5.85524C0.828482 6.15555 0.82718 6.16987 0.828527 7.21379C0.829605 8.06309 0.845049 8.32905 0.907724 8.57755C1.18455 9.67494 1.89454 10.5646 2.89235 11.0645C3.04051 11.1387 3.39406 11.2712 3.67803 11.3588C4.62704 11.6517 11.149 13.5395 11.4628 13.6121C11.9101 13.7157 12.7308 13.7075 13.2038 13.5946C14.1726 13.3635 21.3131 11.2474 21.6269 11.0983C22.656 10.6096 23.3731 9.74875 23.6658 8.65046C23.7364 8.38553 23.7604 8.11746 23.7782 7.39337C23.8033 6.37334 23.7639 6.08457 23.5342 5.60589C23.3725 5.26885 22.8633 4.75946 22.5265 4.59788C22.0047 4.34759 21.9286 4.34184 19.1335 4.34112L16.5632 4.34045V3.33855C16.5632 1.94395 16.4956 1.65024 16.0665 1.18081C15.8208 0.911924 15.4911 0.734046 15.107 0.6632C14.7223 0.592265 9.75667 0.60304 9.42471 0.675547ZM14.9995 2.16995C15.0746 2.23788 15.0816 2.334 15.0816 3.29235V4.34045H12.2981H9.5145V3.30016C9.5145 2.39515 9.52416 2.24919 9.58881 2.17777C9.65916 2.10001 9.80215 2.09565 12.2902 2.09565C14.6718 2.09565 14.9251 2.10261 14.9995 2.16995ZM0.805361 14.9464C0.806079 18.8847 0.803745 18.8457 1.0689 19.3698C1.33522 19.8962 1.81727 20.3058 2.41241 20.5114L2.71277 20.6152H12.2981C21.5772 20.6152 21.8912 20.6125 22.1303 20.5305C22.9725 20.2413 23.532 19.6446 23.7238 18.8311C23.7793 18.5956 23.7902 17.9644 23.7907 14.941L23.7914 11.3319L23.5782 11.5307C23.1202 11.9577 22.5282 12.3311 21.9507 12.5574C21.5387 12.7188 14.6742 14.7234 13.6079 14.9937C13.076 15.1285 12.989 15.1378 12.2756 15.1368L11.5124 15.1356L10.7267 14.9264C9.17294 14.5128 2.83991 12.6387 2.4983 12.4914C2.00426 12.2783 1.57551 12.0088 1.16192 11.6514L0.804688 11.3427L0.805361 14.9464Z"
      fill="currentColor"
      fillOpacity="0.4"
    />
  </svg>
);

export default Suitcase2;
