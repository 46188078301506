import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import * as Layouts from 'layouts';
import { Context, Types } from 'modules/firebase';

import { Email, Login, Messages, Register, Reset } from './components';

import cls from './auth.module.scss';

const Auth: React.FC = () => {
  const [params] = useSearchParams();
  const requestID = params.get('id') || '';

  const [state, setState] = React.useState<Types.IContext.State>(() => ({ step: 'email', email: '' }));

  const getComponent = () => {
    switch (state.step) {
      case 'email':
        return <Email />;
      case 'login':
        return <Login />;
      case 'register':
        return <Register />;
      case 'verification-message':
        return <Messages.Verification />;
      case 'reset-message':
        return <Messages.Reset />;
      case 'reset':
        return <Reset />;
      default:
        return <Navigate to="/templates" />;
    }
  };

  return (
    <>
      <Context.AuthProvider state={state} setState={setState}>
        <Layouts.Login>
          <div className={cls.wrapper}>{getComponent()}</div>
        </Layouts.Login>
      </Context.AuthProvider>
    </>
  );
};

export default Auth;
