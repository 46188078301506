import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { TableColumnsType } from 'antd';
import { Button, Space, Typography } from 'antd';
import { Table, TableSkeleton, Tag } from 'comfi-ui-kit/lib/components';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import * as Types from 'modules/animations/types';
import { blobPromise } from 'utils/blobpromise';

import Empty from 'components/empty/empty';

import cls from './list.module.scss';

interface ListProps {
  items: Types.IEntity.Simulation[];
  loading: boolean;
  tableLoading: boolean;
  onDelete: (selectedRowKeys: any) => void;
  selectedRowKeys: string[];
  handleRowChange: (selectedRowKeys: any) => void;
  setTableLoading: (state: boolean) => void;
}

interface ExpandedDataType {
  key: React.Key;
  id: string;
  style: string;
  alias: string;
  kind: string;
  url: string;
}

const List: FC<ListProps> = ({ items, loading, tableLoading, onDelete, selectedRowKeys, handleRowChange, setTableLoading }) => {
  const [data, setData] = useState<any>([]);
  const navigate = useNavigate();

  console.log('itemsNow', items);

  useEffect(() => {
    // Filter out items without valid subitems (animations with id)
    const filteredItems = items
      .map(item => ({
        ...item,
        animations: item.animations.filter(anim => anim.id)
      }))
      .filter(item => item.animations.length > 0);

    setData(filteredItems);
  }, [items]);

  const handleDownload = async (item: any) => {
    const zip = new JSZip();
    const folder = zip.folder(`${item.template_alias}_${item.name}`);
    const filename = `${item.template_alias}_${item.name}_${item.id}.zip`;

    setTableLoading(true);

    if (item.url !== '') {
      const name = `${item.template_alias}_${item.name}.obj`;

      // @ts-ignore
      folder?.file(name, blobPromise(item.url));
    }
    setTableLoading(false);
    zip.generateAsync({ type: 'blob', mimeType: 'application/octet-stream' }).then(blob => saveAs(blob, filename));
  };

  const expandedRowRender = (record: any) => {
    const columns: TableColumnsType<ExpandedDataType> = [
      {
        title: 'Name',
        fixed: true,
        dataIndex: 'alias',
        render: (alias: string) => <Typography>{alias}</Typography>
      },
      {
        title: 'Type',
        dataIndex: 'kind',
        render: (kind: string) => <Tag variant="green">{kind}</Tag>
      },
      {
        title: 'Style',
        dataIndex: 'style',
        render: (style: string) => <Tag variant="orange">{style}</Tag>
      },
      {
        title: 'Action',
        dataIndex: '',
        key: 'operation',
        render: (item: any) => (
          <Space size="middle">
            <Button onClick={() => handleDownload(item)}>Download</Button>
            <Button onClick={() => onDelete([item.id])}>Delete</Button>
          </Space>
        )
      }
    ];

    return <Table columns={columns} dataSource={record.animations} pagination={false} loading={tableLoading} rowKey="id" />;
  };

  const columns: TableColumnsType<any> = [
    {
      title: 'Template name',
      key: 'template_alias',
      fixed: true,
      dataIndex: '',
      render: (item: any) => (
        <a href={`/animations/editor/${item.id_template}`} rel="noreferrer">
          <Typography>{item.template_alias}</Typography>
        </a>
      )
    },
    { title: 'Animations', dataIndex: 'animations', key: 'count', render: (animations: any[]) => animations.length }
  ];

  return loading ? (
    <TableSkeleton />
  ) : (
    <div className={cls.wrapper}>
      {data?.length ? (
        <Table
          className={cls.table}
          columns={columns}
          expandable={{ expandedRowRender }}
          dataSource={data}
          scroll={{ x: true }}
          pagination={false}
          size="small"
          loading={tableLoading}
          rowKey="id_template"
        />
      ) : (
        <div className={cls.empty}>
          <Empty
            buttonTitle="Add an animation"
            subtitle="Easily create an animation"
            title="Create your first animation"
            onButtonClick={() => navigate('/animations/editor')}
          />
        </div>
      )}
    </div>
  );
};

export default List;
