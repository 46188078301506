import React from 'react';
import { message } from 'antd';
import { sendVerification } from 'modules/firebase/api';
import { useContext } from 'modules/firebase/context';

import { Button, Icon } from 'components';

import cls from './message.module.scss';

const Verification: React.FC = () => {
  const { state, setState } = useContext();
  const [time, setTime] = React.useState(0);
  const intervalRef = React.useRef<NodeJS.Timer>();

  const onSend = async () => {
    try {
      setTime(30);
      await sendVerification();
      let counter = 30;

      intervalRef.current = setInterval(() => {
        if (counter === 0) clearInterval(intervalRef.current);
        else {
          counter -= 1;
          setTime(counter);
        }
      }, 1000);
    } catch (err: any) {
      setTime(0);
      message.error(err.message);
    }
  };

  React.useEffect(() => {
    onSend().catch(err => message.error(err.message));
    return () => intervalRef.current! && clearInterval(intervalRef.current!);
  }, []);

  return (
    <div className={cls.wrapper}>
      <div className={cls.icons}>
        <Icon name="email" size={80} />
      </div>
      <h2>Check your Email</h2>
      <div className={cls.description}>
        We sent an email to you at <br /> <span>{state.email}</span>
      </div>
      <Button block className={cls.btn} disabled={time !== 0} onClick={onSend}>
        Send link again {time !== 0 && `(${time})`}
      </Button>

      <Button block className={cls.btn} type="outline" onClick={() => setState(p => ({ ...p, step: 'email' }))}>
        Change Email
      </Button>
    </div>
  );
};

export default Verification;
