import { UploadFile } from 'antd';

import { SimulationType, StyleType } from './constants';

export namespace IEntity {
  export interface Simulation {
    id?: string;
    id_template: string;
    url: string | UploadFile[];
    kind?: SimulationType;
    createdat?: {
      value: string | undefined;
      label: string;
    };
    updatedat?: {
      value: string | undefined;
      label: string;
    };
    alias?: string;
    template_alias?: string;
    style?: StyleType;
  }
  export interface UpdateSimulation {
    id?: string;
    createdat?: {
      value: string;
      label: string;
    };
    updatedat?: {
      value: string;
      label: string;
    };
    id_template: string;
    kind: SimulationType;
    url?: string;
    alias: string;
    style?: StyleType;
  }
}

export namespace IQuery {
  export interface List {
    items: IEntity.Simulation[];
  }
  export type DeleteList = Array<string>;
}

export namespace IApi {
  export namespace List {
    export interface Request {}
    export interface Response extends Array<IEntity.Simulation[] | []> {}
  }

  export namespace DeleteList {
    export type Request = Array<string>;
    export type Response = Array<string>;
    export interface Error {
      error: string;
    }
  }
}

export namespace IForm {
  export interface Update extends IEntity.Simulation {}
}

export namespace IUpdateApi {
  export namespace Update {
    export interface Request extends IEntity.UpdateSimulation {}
    export interface Response extends IEntity.UpdateSimulation {}

    export interface Error {
      error: string;
    }
  }
}
