import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';

import * as List from './list';

import classes from './icon.module.scss';

export interface IIconProps {
  name: keyof typeof List;
  size?: number;
  className?: string;
  color?: string;
  onClick?: () => void;
}

const Icon: React.FC<IIconProps> = ({ name, size = 15, className, color, onClick }) => {
  const Component = get(List, `[${name}]`);

  if (!Component) {
    console.log('Icon component not found');

    return null;
  }

  return (
    <span
      className={cx(classes.wrapper, className)}
      onClick={onClick}
      style={{ ...{ '--size': `${size}px` }, color } as React.CSSProperties}
    >
      {/* @ts-ignore */}
      <Component />
    </span>
  );
};

export default Icon;
