import React from 'react';
import { Col, InputNumber, Row, Slider } from 'antd';
import { InputProps as BaseInputProps } from 'antd/lib/input';
import { Icon, Typography } from 'comfi-ui-kit/lib/components';
import { Control, Controller } from 'react-hook-form';

import cls from './input-slider.module.scss';

export interface InputSliderProps
  extends Pick<BaseInputProps, 'type' | 'disabled' | 'placeholder' | 'maxLength' | 'min' | 'pattern' | 'step'> {
  errorMsg?: string;
  control: Control<any>;
  name: string;
  placeholder: string;
  min: number;
  max: number;
}

const InputSlider: React.FC<InputSliderProps> = ({ errorMsg, control, name, placeholder, min, max }) => (
  <div className={cls.wrapper}>
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <>
          <Typography>{placeholder}</Typography>
          <Row>
            <Col span={12}>
              <Slider min={min} max={max} step={0.1} {...field} />
            </Col>
            <Col span={4}>
              <InputNumber min={min} max={max} style={{ margin: '0 16px' }} step={0.1} {...field} />
            </Col>
          </Row>
        </>
      )}
    />
    {errorMsg && (
      <div className={cls.message}>
        <Icon name="exclamation" /> <span>{errorMsg}</span>
      </div>
    )}
  </div>
);

export default InputSlider;
