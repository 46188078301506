import { useQuery } from 'react-query';

import * as Api from '../api';
import * as Constants from '../constants';
import * as Mappers from '../mappers';
import * as Types from '../types';

const useTemplateList = () => {
  const initialData = { items: [] } as Types.IQuery.List;

  const { data = initialData, ...args } = useQuery<Types.IQuery.List, string, Types.IQuery.List>(
    [Constants.TEMPLATES, 'list'],
    async () => {
      const { data } = await Api.List();

      const items = data.map(Mappers.Template);

      console.log('items data', items, 'data', data);
      return { items };
    },
    { keepPreviousData: true }
  );

  return { ...data, ...args };
};

export default useTemplateList;
