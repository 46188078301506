import get from 'lodash/get';

import type * as Types from './types';

export const AccountItem = (item: any): Types.IEntity.User.Account => ({
  id: get(item, 'id') || '',
  name: get(item, 'name') || '',
  description: get(item, 'avatarUrl') || '',
  logo_url: get(item, 'avatarUrl') || ''
});

export const User = (item: any): Types.IEntity.User.Main => ({
  id: get(item, 'id') || '',
  name: get(item, 'name') || '',
  uid: get(item, 'uid') || '',
  phone: get(item, 'phone') || '',
  email: get(item, 'uid') || '',
  isAdmin: get(item, 'isAdmin') || '',
  avatarUrl: get(item, 'avatarUrl') || '',
  accounts: (get(item, 'accounts') || []).map(AccountItem)
});
