import React, { ReactNode } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { message } from 'antd';
import { getSignInMethod } from 'config/firebase';
import { SignInMethod } from 'firebase/auth';
import { useContext } from 'modules/firebase/context';
import { useForm, UseFormReturn } from 'react-hook-form';

import { IForm } from '../types';

import { emailSchema } from './schema';

interface EmailProps {
  children: (data: UseFormReturn<IForm.Email>) => ReactNode;
  onSuccess?: (value: IForm.Email) => void;
}

const Email: React.FC<EmailProps> = ({ onSuccess, children }) => {
  const data = useForm<IForm.Email>({ resolver: yupResolver(emailSchema), defaultValues: { email: '' } });

  const auth = useContext();

  const onSubmit = async (data: IForm.Email) => {
    try {
      const methods = await getSignInMethod(data.email);

      if (methods.includes(SignInMethod.EMAIL_PASSWORD)) {
        auth.setState({ email: data.email, step: 'login' });
        if (onSuccess) onSuccess(data);
      } else auth.setState({ email: data.email, step: 'register' });
    } catch (err: any) {
      message.error(err.message);
    }
  };

  return <form onSubmit={data.handleSubmit(onSubmit)}>{children(data)}</form>;
};

export default Email;
