import React from 'react';
import { auth } from 'config/firebase';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { getIsVerified, login } from 'store';

import * as Api from '../api';
import * as Constants from '../constants';
import { useAuthStateChange } from '../hooks';
import * as Mappers from '../mappers';
import * as Types from '../types';

interface AuthProps {
  children: JSX.Element;
}

const Auth: React.FC<AuthProps> = ({ children }) => {
  useAuthStateChange();
  const dispatch = useDispatch();
  const isVerified = useSelector(getIsVerified);

  const { refetch } = useQuery<Types.IQuery.User, any, Types.IQuery.User>(
    [Constants.USER],
    async () => {
      const { data } = await Api.User();

      const user = Mappers.User(data);

      console.log('token', await auth.currentUser?.getIdToken());

      console.log('account_id', user.accounts[0].id);

      return { user };
    },
    {
      enabled: !!isVerified,
      onSuccess: data => dispatch(login(data.user)),
      onError: () => Api.CreateUser({ name: '', phone: '', email: '', avatarUrl: '' }).then(() => refetch()),
      retry: false
    }
  );

  return children;
};

export default Auth;
