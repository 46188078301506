/* eslint-disable react/no-unknown-property */
import { FC, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import { Center, ContactShadows, Html, OrbitControls, PerspectiveCamera, Plane, useTexture } from '@react-three/drei';
import { Canvas, useLoader } from '@react-three/fiber';
import { button, Leva, useControls } from 'leva';
import { Types } from 'modules/templates-main';
import { Mesh, Texture } from 'three';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { degrees_to_radians, downloadGLB } from 'utils/helpers';

// @ts-ignore
import MARIA_A_POSE from 'assets/objects/Maria/MARIA_A_POSE.obj';
// @ts-ignore
import MARIA_DEFAULT_POSE from 'assets/objects/Maria/MARIA_DEFAULT_POSE.obj';

import cls from './model-preview.module.scss';

interface ModelPreviewProps {
  data: IModelData;
}

export interface IModelData {
  variants: Types.IEntity.Variant[];
  materials: Types.IEntity.Material[];
}

const sizeMap: any = {
  S: 0
};

const modelPosesOptions: any[] = [
  {
    label: 'A-pose',
    value: 'objectUrl',
    icon: <BarsOutlined />
  },
  {
    label: 'Stale pose',
    value: 'objectUrlDefaultPose',
    icon: <AppstoreOutlined />
  }
];
const ModelPreview: FC<ModelPreviewProps> = ({ data }) => {
  const currentSize = 'S';
  const [object, setObject] = useState<any>();
  const [isAvatarVisible, setAvatarVisibility] = useState<boolean>(true);
  const [currentAvatar, setCurrentAvatar] = useState<string>(MARIA_A_POSE);
  const [currentMaterialIndex, setMaterialIndex] = useState<number>(0);
  const [currentPose, setCurretPose] = useState<string>('objectUrl');
  const [textures, setTextures] = useState<any>({});
  const meshRef = useRef();

  useControls(
    'Materials',
    () => {
      const res = {} as any;

      // eslint-disable-next-line array-callback-return
      data.materials.map((material: any, index: any) => {
        res[material.name] = {
          value: index === currentMaterialIndex,
          onChange: (v: boolean) => setMaterialIndex(v ? index : 0)
        };
      });

      return res;
    },
    [data.materials.length, currentMaterialIndex]
  );

  useControls({
    'Download garment': button(() => downloadGLB(meshRef))
  });

  useEffect(() => {
    const { objectUrl } = data.variants[sizeMap[currentSize]];

    if (objectUrl) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      objectUrl.name ? setObject(URL.createObjectURL(objectUrl)) : setObject(objectUrl);
    }
  }, [data]);

  function handleModelSwitch(e: 'objectUrl' | 'objectUrlDefaultPose') {
    const variant = data.variants[0];
    const objMapping: any = {
      objectUrl: MARIA_A_POSE,
      objectUrlDefaultPose: MARIA_DEFAULT_POSE
    };

    if (variant[e]) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      variant[e].name ? setObject(URL.createObjectURL(variant[e])) : setObject(variant[e]);
    }
    setCurretPose(e);
    setCurrentAvatar(objMapping[e]);
  }

  useControls('Avatar', () => ({
    'A-Pose?': {
      value: currentPose === 'objectUrl',
      onChange: (v: boolean) => handleModelSwitch(v ? 'objectUrl' : 'objectUrlDefaultPose')
    },
    'Avatar?': {
      value: isAvatarVisible,
      onChange: (v: boolean) => setAvatarVisibility(v)
    }
  }));

  useMemo(() => {
    if (!data.materials.length) {
      return;
    }
    // const tempMats: any[] = [];

    // data.materials.map((material: any, index: any) => {
    //   tempMats.push({
    //     label: (
    //       <div style={{ padding: 4 }}>
    //         <Avatar style={{ backgroundColor: material.color }}>{material.name.charAt(0)}</Avatar>
    //         <div>{material.name}</div>
    //       </div>
    //     ),
    //     value: index
    //   });
    // });
    // setMaterialsOptions(tempMats);

    const textures: any = {};
    const { materials } = data;

    const renameTextures: any = {
      metallicMap: 'metalnessMap',
      albedoMap: 'map'
    };

    Object.keys(materials[currentMaterialIndex])
      .filter(x => x.includes('Map'))
      // eslint-disable-next-line array-callback-return
      .map((x: string) => {
        /* @ts-ignore */
        const texture = materials[currentMaterialIndex][x];

        if (texture !== '') {
          textures[renameTextures[x]] = texture instanceof Object ? URL.createObjectURL(texture) : texture;
        }
      });
    setTextures(textures);
  }, [data, currentMaterialIndex]);

  function Geometry() {
    const ref = useRef();
    const garmentObj = useLoader(OBJLoader, object);
    const avatarObj = useLoader(OBJLoader, currentAvatar);

    // console.log('garmentObj', garmentObj);
    const garmentGeometry = useMemo(() => {
      let g;
      /* @ts-ignore */

      garmentObj.traverse(c => {
        if (c.type === 'Mesh') {
          // eslint-disable-next-line no-underscore-dangle
          const _c = c as Mesh;

          // console.log('_c === Mesh', _c);
          _c.matrixWorldAutoUpdate = true;
          _c.castShadow = true;
          g = _c.geometry;
        }
      });
      return g;
    }, [garmentObj]);

    const avatarGeometry = useMemo(() => {
      let a;
      /* @ts-ignore */

      avatarObj.traverse(c => {
        if (c.type === 'Mesh') {
          // eslint-disable-next-line no-underscore-dangle
          const _c = c as Mesh;

          // console.log('_c === Mesh', _c);
          _c.matrixWorldAutoUpdate = true;
          _c.castShadow = true;
          a = _c.geometry;
        }
      });
      return a;
    }, [avatarObj]);

    const textureMaps = useTexture(textures, (textures: Texture | Texture[]) => {
      const { materials } = data;

      if (materials.length) {
        const { scale, offset, rotation } = materials[currentMaterialIndex];
        /* @ts-ignore */

        // eslint-disable-next-line array-callback-return
        textures.map((texture: Texture) => {
          texture.needsUpdate = true;
          texture.wrapS = THREE.RepeatWrapping;
          texture.wrapT = THREE.RepeatWrapping;
          texture.repeat.set(scale * 1, scale * 1);
          texture.offset.set(offset * 1, offset * 1);
          texture.rotation = degrees_to_radians(rotation);
        });
      }

      /* @ts-ignore */
    });

    const props = {
      color: !Object.keys(textures).length && data.materials.length ? data.materials[currentMaterialIndex].color : 'transparent',
      ...(Object.keys(textures).length ? textureMaps : null)
    };

    return (
      <Center getObjectsByProperty="obj" top>
        {/* @ts-ignore */}
        <group ref={ref} dispose={null}>
          {/* @ts-ignore */}
          <mesh ref={meshRef} receiveShadow castShadow geometry={garmentGeometry}>
            {/* @ts-ignore */}
            <meshPhysicalMaterial side={THREE.DoubleSide} needsUpdate={true} transparent={true} metalness={0.5} {...props} />
          </mesh>
          <mesh visible={isAvatarVisible} receiveShadow castShadow geometry={avatarGeometry}>
            {/* @ts-ignore */}
            <meshPhysicalMaterial color="gray" side={THREE.DoubleSide} needsUpdate={true} transparent={true} metalness={0.5} />
          </mesh>
        </group>
      </Center>
    );
  }
  return (
    <div className={cls.wrapper}>
      <Leva hidden={window.screen.width <= 750} titleBar={{ position: { y: 80, x: 0 } }} />
      <Canvas camera={{ position: [6, 9, 18], fov: 5, zoom: 0.5 }} shadows>
        <ContactShadows getObjectsByProperty="obj" resolution={512} position={[0, -0.8, 0]} opacity={1} scale={10} blur={2} far={0.8} />
        <color attach="background" args={['#cccccc']} />
        <fog attach="fog" args={['#cccccc', 30, 40]} />
        <directionalLight castShadow shadow-mapSize={[512, 512]} receiveShadow intensity={0.7} args={[0xffffff, 1]} position={[1, 1, 1]} />
        <hemisphereLight position={[10, 10, 10]} args={[0xffffff, 0x444444, 0.5]} />
        <ambientLight intensity={0.7} color="#FFFFFF" />
        <spotLight position={[10, 10, 10]} intensity={1} angle={0.5} penumbra={1} castShadow />
        <Suspense
          fallback={
            <Html center>
              <p style={{ width: '100%' }}>LOADING 3D model</p>
            </Html>
          }
        >
          <PerspectiveCamera getObjectsByProperty="obj" args={[45, window.innerWidth / window.innerHeight, 1, 100]}>
            <Geometry />
          </PerspectiveCamera>
          {/* <ContactShadows getObjectsByProperty="fbx" position={[0, -0.8, 0]} opacity={0.25} scale={10} blur={1.5} far={0.8} /> */}
        </Suspense>
        <OrbitControls enableZoom enablePan enableRotate target={[0, 0.1, 0]} />

        <gridHelper args={[50, 100, '#9B9B9B', '#808080']} />
        {/* @ts-ignore */}
        <Plane args={[50, 50]} receiveShadow={true} rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
          <meshStandardMaterial color="#9B9B9B" depthWrite={false} />
        </Plane>
      </Canvas>
    </div>
  );
};

export default ModelPreview;
