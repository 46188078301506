import { createContext } from 'react';

import * as Types from '../types';

const context = createContext<Types.IContext.Value>({
  state: { step: 'email', email: '' }
} as Types.IContext.Value);

context.displayName = 'AuthContext';

export default context;
