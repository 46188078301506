import { FC, ReactNode } from 'react';

import { Overview } from './components';

import classes from './login.module.scss';

interface IProps {
  children: ReactNode;
}

const Login: FC<IProps> = ({ children }) => (
  <div className={classes.container}>
    <div className={classes.left_card}>
      <Overview />
    </div>
    <div className={classes.right_card}>{children}</div>
  </div>
);

export default Login;
