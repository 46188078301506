import React from 'react';
import { Form } from 'modules/firebase';

import { Input } from 'containers/fields';

import { Button } from 'components';

import cls from './email.module.scss';

interface EmailProps {}

const Email: React.FC<EmailProps> = () => (
  <Form.Email>
    {({ control, watch, formState: { errors, isSubmitting } }) => (
      <div className={cls.wrapper}>
        <h1 className={cls.title}>Let’s get you started</h1>
        <Input errorMsg={errors.email?.message} placeholder="Email" control={control} className={cls.input} name="email" />
        <Button className={cls.btn} htmlType="submit" loading={isSubmitting} disabled={!watch('email') || isSubmitting}>
          Continue
        </Button>
      </div>
    )}
  </Form.Email>
);

export default Email;
