import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const List = (): AxiosPromise<Types.IApi.List.Response> => http.request.post(`/fabrics`);
// @ts-ignore
export const DeleteFabrics = (fabricIds: Types.IApi.DeleteList.Request): AxiosPromise<Types.IApi.DeleteList.Response> =>
  http.request.delete(`/fabrics`, { data: fabricIds });

export const UpdateFabric = (data: Types.IUpdateApi.Update.Request): Promise<Types.IUpdateApi.Update.Response> =>
  http.request.put(`/fabric`, data);
