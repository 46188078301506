import { UploadFile } from 'antd';

import { MaterialType } from './constants';

export namespace IEntity {
  export interface Pattern {
    id?: string;
    title: string;
    createdat?: {
      value: string;
      label: string;
    };
    updatedat?: {
      value: string;
      label: string;
    };
    name: string;
    ispublic: boolean;
    material: Material;
    thumbnailurl: string;
    tags: string[];
    masks: string[];
  }

  export interface UpdatePattern {
    id?: string;
    createdat?: {
      value: string;
      label: string;
    };
    updatedat?: {
      value: string;
      label: string;
    };
    title: string;
    name: string;
    ispublic: boolean;
    type: MaterialType;
    thumbnailurl: string;
    scale: number;
    off_set: number;
    rotation: number;
    albedomap?: string | UploadFile | null;
    tags: string[];
    masks: string[];
  }

  export interface Material {
    type: MaterialType;
    scale: number;
    off_set: number;
    rotation: number;
    albedomap?: string | UploadFile | null;
  }
}

export namespace IQuery {
  export interface List {
    items: IEntity.Pattern[];
  }
  export type DeleteList = Array<string>;
}

export namespace IApi {
  export namespace List {
    export interface Request {}
    export interface Response extends Array<IEntity.Pattern[]> {}
  }

  export namespace DeleteList {
    export type Request = Array<string>;
    export type Response = Array<string>;
    export interface Error {
      error: string;
    }
  }
}

export namespace IForm {
  export interface Update extends IEntity.Pattern {}
}

export namespace IUpdateApi {
  export namespace Update {
    export interface Request extends IEntity.UpdatePattern {}
    export interface Response extends IEntity.UpdatePattern {}

    export interface Error {
      error: string;
    }
  }
}
