import React, { ReactNode, useRef } from 'react';
import { ButtonProps as BaseButtonProps } from 'antd/lib/button/button';
import cx from 'classnames';
import debounce from 'lodash/debounce';

import classes from './button.module.scss';

export interface ButtonProps extends Pick<BaseButtonProps, 'block' | 'className' | 'htmlType' | 'onClick' | 'icon'> {
  type?: 'green' | 'black' | 'link' | 'outline' | 'grey' | 'purple';
  loading?: boolean;
  disabled?: boolean;
  children: ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  type = 'green',
  children,
  disabled = false,
  loading = false,
  onClick,
  className,
  htmlType = 'button',
  block = false,
  icon
}) => {
  const ref = useRef<HTMLButtonElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const pos = ref.current?.getBoundingClientRect();
    // @ts-ignore-start
    const x: number = e.clientX - pos?.left;
    // @ts-ignore-start
    const y: number = e.clientY - pos?.top;
    // @ts-ignore-end

    const span: HTMLSpanElement = document.createElement('span') as HTMLSpanElement;

    span.className = classes.ripples;
    span.style.left = `${x}px`;
    span.style.top = `${y}px`;
    ref.current?.appendChild(span);

    setTimeout(() => span.remove(), 1000);
  };

  return (
    <button
      ref={ref}
      onClick={debounce(e => {
        handleClick(e);
        if (onClick) onClick(e);
      }, 400)}
      className={cx(
        classes.wrapper,
        classes[`wrapper--type-${type}`],
        (disabled || loading) && classes['wrapper-disabled'],
        block && classes['wrapper-block'],
        className
      )}
      disabled={disabled}
      type={htmlType}
    >
      {loading ? (
        <div className={classes.wrap}>
          <div className={classes['dot-pulse']} />
        </div>
      ) : (
        <div className={classes.wrap}>
          <p className={classes.p}>{children}</p>
          {icon && icon}
        </div>
      )}
    </button>
  );
};

export default Button;
