import { Types } from 'modules/templates-main';
import { SelectOption } from 'pages/template-editor/template-editor';

export enum GenderType {
  f = 'f',
  m = 'm'
}

export const TEMPLATES = 'TEMPLATES';

export enum MaterialType {
  main = 'main',
  detail = 'detail'
}

export enum TextureType {
  albedoMap = 'albedoMap',
  normalMap = 'normalMap',
  roughnessMap = 'roughnessMap',
  metallicMap = 'metallicMap'
}

export enum FabricType {
  cotton_gabardine = 'cotton_gabardine',
  cotton_heavy_twill = 'cotton_heavy_twill',
  cotton_stretch_sateen = 'cotton_stretch_sateen',
  cotton_40s_stretch_poplin = 'cotton_40s_stretch_poplin',
  denim_stretch = 'denim_stretch',
  knit_cotton_rayon_jersey = 'knit_cotton_rayon_jersey',
  leather_lambskin = 'leather_lambskin',
  linen = 'linen',
  muslin = 'muslin',
  wool = 'wool'
}

export const sampleTemplate: Types.IEntity.Template = {
  title: '',
  title_ru: '',
  name: '',
  alias: '',
  gender: GenderType.f,
  garmenttype: 'top',
  category: '',
  requires_styled_simulation: false,
  subcategory: '',
  fabrics: [],
  images: [],
  materials: [],
  ispublic: false,
  options: [
    {
      name: 'Size',
      values: ['S']
    }
  ],
  silhouette: { front: '', back: '' },
  variants: [
    {
      options: ['S'],
      objectUrl: '',
      materialId: '',
      imageId: '',
      zrpjProjectUrl: '',
      zrpjProjectUrlDefaultPose: '',
      objectUrlDefaultPose: ''
    }
  ]
};

export const garmentTypeSelectOptions: SelectOption[] = [
  {
    label: 'Top',
    value: 'top'
  },
  {
    label: 'Bottom',
    value: 'bottom'
  },
  {
    label: 'Full',
    value: 'full'
  }
];
