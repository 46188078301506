import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const List = (): AxiosPromise<Types.IApi.List.Response> => http.request.post(`/patterns`);
// @ts-ignore
export const DeletePatterns = (patternIds: Types.IApi.DeleteList.Request): AxiosPromise<Types.IApi.DeleteList.Response> =>
  http.request.delete(`/patterns`, { data: patternIds });

export const UpdatePattern = (data: Types.IUpdateApi.Update.Request): Promise<Types.IUpdateApi.Update.Response> =>
  http.request.put(`/pattern`, data);
