import React, { ReactNode } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';

import { useContext } from '../context';
import { IForm } from '../types';

import { resetSchema } from './schema';

interface ResetProps {
  onSuccess?: (value: IForm.Email) => void;
  children: (data: UseFormReturn<IForm.Email>) => ReactNode;
  defaultValues?: IForm.Email;
}

const Reset: React.FC<ResetProps> = ({ children, defaultValues = {} }) => {
  const { setState } = useContext();
  const data = useForm<IForm.Email>({ resolver: yupResolver(resetSchema), defaultValues: { email: '', ...defaultValues } });

  const onSubmit: SubmitHandler<IForm.Email> = ({ email }) => {
    setState(p => ({ step: 'reset-message', email }));
  };

  return <form onSubmit={data.handleSubmit(onSubmit)}>{children(data)}</form>;
};

export default Reset;
