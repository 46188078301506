import get from 'lodash/get';
import moment from 'moment';

import { MaterialProps } from 'components/material-preview/material-preview';

import * as Types from './types';

export const Pattern = (item: any): Types.IEntity.Pattern => ({
  id: get(item, 'id'),
  createdat: {
    value: get(item, 'createdat'),
    label: moment(get(item, 'createdat')).format('MMMM DD, YYYY')
  },
  updatedat: {
    value: get(item, 'updateat'),
    label: moment(get(item, 'updateat')).format('MMMM DD, YYYY')
  },
  title: get(item, 'title') || '',
  name: get(item, 'name') || '',
  material: {
    type: get(item, 'type') || 'main',
    albedomap: get(item, 'albedomap') || '',
    scale: get(item, 'scale') || 0,
    off_set: get(item, 'off_set') || 0,
    rotation: get(item, 'rotation') || 0
  },
  thumbnailurl: get(item, 'thumbnailurl') || '',
  ispublic: get(item, 'ispublic') || false,
  tags: get(item, 'tags') || [],
  masks: get(item, 'masks') || []
});

export const getData = (item: Types.IEntity.Pattern): MaterialProps => ({
  material: get(item, 'material')
});
