import { FC } from 'react';

const Refresh: FC = () => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8437 9.0331C13.0189 8.71606 13.2982 8.50038 13.6592 8.40331C13.8444 8.3535 14.2301 8.39036 14.4249 8.47646C14.6764 8.58763 14.9385 8.84289 15.0647 9.09966L15.1722 9.31837L15.1704 10.3844C15.1685 11.5701 15.1273 11.8765 14.8866 12.497C14.3488 13.8833 13.2764 14.7702 11.7183 15.1171C11.352 15.1986 11.2243 15.204 9.28809 15.2195L7.24455 15.2358L7.568 15.727C7.74595 15.9972 7.9216 16.3109 7.95834 16.424C8.14028 16.9839 7.82078 17.6534 7.24579 17.9171C7.01532 18.0228 6.55919 18.0287 6.32732 17.9289C5.95483 17.7687 5.90778 17.711 4.82428 16.0878C4.24972 15.227 3.75495 14.4528 3.72481 14.3674C3.6601 14.1842 3.65355 13.7981 3.71162 13.5892C3.76764 13.3876 5.78957 10.361 5.97931 10.1947C6.60078 9.64987 7.56405 9.89203 7.92703 10.6843C8.01133 10.8683 8.02196 10.9361 8.0066 11.1929C7.98604 11.5368 7.93561 11.6501 7.54193 12.2365C7.39479 12.4557 7.2641 12.6619 7.25153 12.6946C7.23109 12.7478 7.4356 12.7526 9.16376 12.7395C11.2788 12.7235 11.2847 12.7229 11.7971 12.481C12.1307 12.3235 12.3394 12.1578 12.4574 11.9565C12.6477 11.6321 12.6747 11.4505 12.7027 10.3068L12.7289 9.24084L12.8437 9.0331Z"
      fill="currentColor"
    />
    <path
      d="M7.98741 1.24041C7.98741 0.706477 8.35101 0.20848 8.85622 0.0504797C9.20175 -0.0575934 9.60484 0.0100492 9.94087 0.232436C10.1009 0.338377 10.2667 0.565687 11.1771 1.92804C11.7999 2.85984 12.2497 3.57146 12.2804 3.67337C12.3498 3.90386 12.3458 4.21676 12.2707 4.43802C12.1868 4.68491 10.1616 7.71332 9.99604 7.83957C9.64701 8.10557 9.19988 8.17224 8.79073 8.01928C8.23394 7.81112 7.87781 7.21214 7.98931 6.67147C8.04649 6.39431 8.08315 6.32333 8.45882 5.76273C8.62625 5.51286 8.76327 5.2983 8.76327 5.2859C8.76327 5.27353 7.88604 5.26939 6.81391 5.27671C5.10073 5.28842 4.83671 5.29819 4.63463 5.35753C3.96863 5.55302 3.55436 5.88375 3.40749 6.33713C3.32963 6.5775 3.31835 6.71306 3.2965 7.67072C3.27249 8.72431 3.27102 8.73896 3.17031 8.93053C3.0324 9.19285 2.8131 9.40543 2.55637 9.52563C2.2738 9.65793 1.85092 9.66499 1.57813 9.54188C1.32279 9.42671 1.0306 9.14106 0.921552 8.90007C0.832444 8.70319 0.830078 8.66985 0.830078 7.61257C0.830078 6.69848 0.841445 6.47521 0.902233 6.1977C1.09713 5.30737 1.51066 4.55765 2.1274 3.97627C2.70305 3.43366 3.2863 3.132 4.18568 2.91174C4.5927 2.81208 4.59468 2.812 6.67709 2.79657L8.76121 2.78111L8.44454 2.30897C8.07566 1.75891 7.98741 1.55261 7.98741 1.24041Z"
      fill="currentColor"
    />
  </svg>
);

export default Refresh;
