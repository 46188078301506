export const FABRICS = 'FABRICS';

export enum MaterialType {
  main = 'main',
  detail = 'detail'
}

export enum TextureType {
  albedoMap = 'albedoMap',
  normalMap = 'normalMap',
  roughnessMap = 'roughnessMap',
  metallicMap = 'metallicMap'
}

export enum TextureTypeNew {
  albedomap = 'albedomap',
  normalmap = 'normalmap',
  roughnessmap = 'roughnessmap',
  metallicmap = 'metallicmap'
}
