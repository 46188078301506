import { SelectOption } from 'pages/template-editor/template-editor';

export enum SimulationType {
  static = 'static',
  dynamic = 'dynamic'
}

export enum StyleType {
  flat = 'flat',
  middle = 'middle',
  high = 'high'
}

export const SIMULATIONS = 'SIMULATIONS';
export const SINGLESSIMULATIONS = 'SINGLESIMULATIONS';

export const styleTypeOptions: SelectOption[] = [
  {
    label: StyleType.flat,
    value: StyleType.flat
  },
  {
    label: StyleType.middle,
    value: StyleType.middle
  },
  {
    label: StyleType.high,
    value: StyleType.high
  }
];
