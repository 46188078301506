import React from 'react';
import Select, { BaseOptionType } from 'antd/lib/select';
import cx from 'classnames';
import { Icon } from 'comfi-ui-kit/lib/components';
import { Control, Controller, useWatch } from 'react-hook-form';

import cls from './multi-select.module.scss';

const { Option } = Select;

export interface MultiSelectProps extends Pick<BaseOptionType, 'showSearch'> {
  options: { label: string; value: any }[];
  errorMsg?: string;
  placeholder: string;
  control: Control<any>;
  name: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({ options, errorMsg, placeholder, control, name }) => {
  const value = useWatch({ control, name }) || [];

  return (
    <div className={cls.wrapper}>
      <Controller
        name={name}
        control={control}
        rules={{ required: { value: true, message: 'required field type' } }}
        render={({ field }) => (
          <Select mode="tags" className={cx(cls[`multi-select`], errorMsg && cls.error, value.length !== 0 && cls.active)} {...field}>
            {options.map(({ label, value }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        )}
      />

      <span className={cx(cls.label, value.length !== 0 && cls.active, errorMsg && cls.error)}>{placeholder}</span>

      {errorMsg && (
        <div className={cls.message}>
          <Icon name="exclamation" /> <span>{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
