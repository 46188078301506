import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { message } from 'antd';
import { useForm, UseFormReturn } from 'react-hook-form';

import { login } from '../api';
import { useContext } from '../context';
import { IForm } from '../types';

import { loginSchema } from './schema';

interface RegisterProps {
  onSuccess?: (value: IForm.Login) => void;
  children: (data: UseFormReturn<IForm.Login>) => React.ReactNode;
  onError?: (error: any) => void;
}

const Login: React.FC<RegisterProps> = ({ onSuccess, children }) => {
  const { state, setState } = useContext();
  const data = useForm<IForm.Login>({ resolver: yupResolver(loginSchema), defaultValues: { email: state.email, password: '' } });

  const onSubmit = async ({ email, password }: IForm.Login) => {
    try {
      const { user } = await login(email, password);

      if (!user.emailVerified) setState(p => ({ ...p, step: 'verification-message' }));
    } catch (err: any) {
      message.error(err.message);
    }
  };

  return <form onSubmit={data.handleSubmit(onSubmit)}>{children(data)}</form>;
};

export default Login;
