import React from 'react';
import { InputProps as BaseInputProps } from 'antd/lib/input';
import { Icon } from 'comfi-ui-kit/lib/components';
import { HexColorInput } from 'react-colorful';
import { Control, Controller } from 'react-hook-form';

import cls from './input-hex.module.scss';

export interface InputProps extends Pick<BaseInputProps, 'type' | 'disabled' | 'placeholder' | 'maxLength' | 'min' | 'pattern' | 'step'> {
  className?: string;
  errorMsg?: string;
  control: Control<any>;
  name: string;
}

const InputHex: React.FC<InputProps> = ({ placeholder, errorMsg, className, control, name, disabled, maxLength, type, ...inputArgs }) => (
  <div>
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <>
          <HexColorInput disabled={disabled} color={field.value} onChange={(e: any) => field.onChange(e)} />
        </>
      )}
    />
    {errorMsg && (
      <div className={cls.message}>
        <Icon name="exclamation" /> <span>{errorMsg}</span>
      </div>
    )}
  </div>
);

export default InputHex;
