import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Collapse, Icon, TopBar, Typography } from 'comfi-ui-kit/lib/components';
import { Col, Row } from 'comfi-ui-kit/lib/components/grid';
import { Form, Types } from 'modules/patterns';
import { MaterialType } from 'modules/patterns/constants';
import usePatternsList from 'modules/patterns/hooks/use-list';
import { getData } from 'modules/patterns/mappers';

import { PageLoader } from 'components';
import { Input } from 'components/fields';
import MaterialEditor from 'components/material-editor';
import MaterialPreview from 'components/material-preview';

import cls from './patterns-editor.module.scss';

export interface Options {
  categories: SelectOption[];
  subcategories: SelectOption[];
  fabrics: SelectOption[];
}

export interface SelectOption {
  label: string;
  value: string;
}

const samplePattern: Types.IEntity.Pattern = {
  name: '',
  title: '',
  ispublic: false,
  thumbnailurl: '',
  material: {
    type: MaterialType.main,
    albedomap: '',
    scale: 1.0,
    rotation: 0.0,
    off_set: 0.0
  },
  tags: [],
  masks: []
};

const PatternsEditor: FC = () => {
  const { id } = useParams();
  const { isLoading, items, refetch } = usePatternsList();
  const [currentItem, setCurrentItem] = useState<Types.IEntity.Pattern>();

  useEffect(() => {
    if (id) {
      if (items.length) {
        refetch();
      }
      const pattern = items.find(item => item.id === id);

      setCurrentItem(pattern);
    } else {
      setCurrentItem(samplePattern);
    }
  }, [isLoading]);

  return isLoading || !currentItem ? (
    <PageLoader />
  ) : (
    <div className={cls.wrapper}>
      <Form.Update
        item={currentItem}
        // @ts-ignore
        component={({ control, register, formState: { errors, isSubmitting }, resetField, watch, setValue }) => (
          <>
            <TopBar
              childrenLeft={
                <div className={cls['content-left--title']}>
                  <Link to="/patterns">
                    <Icon name="back" size={14} />
                  </Link>
                  <div className={cls['content-left--divider']} />
                  <Typography size={22} weight={500} lineHeight="25" className={cls.title}>
                    Edit pattern
                  </Typography>
                </div>
              }
              className={cls['top-bar']}
              childrenRight={
                <Button loading={isSubmitting} className={cls['top-bar--btn']} type="green" htmlType="submit" disabled={isSubmitting}>
                  {id ? 'Update' : 'Create'}
                </Button>
              }
            />
            <div className={cls.content}>
              <div className={cls['content-left']}>
                <div className={cls['content-left-form']}>
                  <Row gutter={[24, 24]}>
                    <Typography
                      className={cls.subtitle}
                      size={22}
                      infoContent="Fill in information about the template you are trying to create/update"
                    >
                      Pattern details
                    </Typography>
                    <Col span={24}>
                      <Input
                        control={control}
                        name="title"
                        type="text"
                        withCount
                        maxLength={50}
                        placeholder="Title"
                        errorMsg={errors?.title?.message}
                      />
                    </Col>
                    <Col span={24}>
                      <Input
                        control={control}
                        name="name"
                        type="text"
                        withCount
                        maxLength={50}
                        placeholder="Name"
                        errorMsg={errors?.name?.message}
                      />
                    </Col>
                    <Collapse
                      content={
                        <Col span={24} className={cls.addUpload}>
                          <MaterialEditor
                            control={control}
                            name="material"
                            resetField={resetField}
                            register={register}
                            errorMsg={errors?.material?.message}
                          />
                        </Col>
                      }
                    >
                      <Typography className={cls.subtitle} size={22} weight={500}>
                        Material
                      </Typography>
                    </Collapse>
                  </Row>
                  <div style={{ margin: 20 }} />
                </div>
              </div>
              <div className={cls['content-right']}>
                <MaterialPreview data={getData({ ...watch() })} />
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default PatternsEditor;
