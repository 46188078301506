import { FC } from 'react';
import { Form } from 'modules/firebase';

import { Password } from 'containers/fields';

import { Button } from 'components';

import cls from './new.module.scss';

export interface NewProps {
  oobCode: string;
}
const New: FC<NewProps> = ({ oobCode }) => (
  <>
    <Form.New oobCode={oobCode}>
      {({ control, formState: { errors, isSubmitting }, watch }) => (
        <div className={cls.wrapper}>
          <h1 className={cls.title}>Enter new password</h1>
          <div className={cls.description}>
            Password must contain at least 1 letter, 1 number <br /> and 1 character. Minimum length is 8 characters
          </div>
          <Password errorMsg={errors.password?.message} placeholder="Password" control={control} className={cls.input} name="password" />
          <Password
            errorMsg={errors.confirm?.message}
            placeholder="Confirm Password"
            control={control}
            className={cls.input}
            name="confirm"
          />
          <Button className={cls.btn} htmlType="submit" loading={isSubmitting} disabled={!watch(['confirm', 'password'])}>
            Continue
          </Button>
        </div>
      )}
    </Form.New>
  </>
);

export default New;
