import { useEffect } from 'react';
import { auth } from 'config/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { changeFirebaseData, changeToken, getAccessToken, logout } from 'store';

function useAuthStateChange() {
  const dispatch = useDispatch();
  const accessToken = useSelector(getAccessToken);

  useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        dispatch(changeFirebaseData(user as any));
      } else {
        dispatch(accessToken ? changeToken(null) : logout());
      }
    });
  }, []);
}

export default useAuthStateChange;
