const ENV = process.env;

const config = {
  app: {
    env: ENV.NODE_ENV,
    isDev: ENV.NODE_ENV !== 'production',
    public_url: ENV.PUBLIC_URL
  },
  api: {
    baseURL: ENV.REACT_APP_BASE_URL,
    tokenKEY: ENV.REACT_APP_TOKEN_KEY,
    redirectURL: ENV.REACT_APP_REDIRECT_URL
  }
};

export default config;
