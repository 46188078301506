import { map } from 'modern-async';
import { useQuery } from 'react-query';

import * as Api from '../api';
import * as Constants from '../constants';
import * as Mappers from '../mappers';
import * as Types from '../types';

const useSimulationList = () => {
  const initialData = { items: [] } as Types.IQuery.List;

  const { data = initialData, ...args } = useQuery<Types.IQuery.List, string, Types.IQuery.List>(
    [Constants.SIMULATIONS, 'list'],
    async () => {
      const { data } = await Api.List();

      const items = await map(data, Mappers.Simulation);

      console.log('items data', items, 'data', data);
      return { items };
    },
    { keepPreviousData: true }
  );

  return { ...data, ...args };
};

export default useSimulationList;
