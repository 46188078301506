import React from 'react';

const Copy: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4348 1.85507H2.31884C2.06271 1.85507 1.85507 2.06271 1.85507 2.31884V10.4348C1.85507 10.6909 2.06271 10.8986 2.31884 10.8986H10.4348C10.6909 10.8986 10.8986 10.6909 10.8986 10.4348V2.31884C10.8986 2.06271 10.6909 1.85507 10.4348 1.85507ZM2.31884 0C1.03818 0 0 1.03818 0 2.31884V10.4348C0 11.7154 1.03818 12.7536 2.31884 12.7536H10.4348C11.7154 12.7536 12.7536 11.7154 12.7536 10.4348V2.31884C12.7536 1.03818 11.7154 0 10.4348 0H2.31884Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0725 3.13043C15.5847 3.13043 16 3.54571 16 4.05797V11.5942C16 14.0275 14.0275 16 11.5942 16H2.89855C2.38629 16 1.97101 15.5847 1.97101 15.0725C1.97101 14.5602 2.38629 14.1449 2.89855 14.1449H11.5942C13.0029 14.1449 14.1449 13.0029 14.1449 11.5942V4.05797C14.1449 3.54571 14.5602 3.13043 15.0725 3.13043Z"
      fill="currentColor"
    />
  </svg>
);

export default Copy;
