import React, { ComponentType } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { message } from 'antd';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { getFileName } from 'services/functions';
import uploadObject from 'services/functions/upload-object';

import * as Constants from '../constants';
import * as Types from '../types';
import { Api } from '..';

import { updateSchema } from './schema';

interface UpdateProps {
  defaultValues?: Partial<Types.IForm.Update>;
  onSuccess?: (values: Types.IForm.Update) => void;
  onError?: (values: Types.IUpdateApi.Update.Error) => void;
  component: ComponentType<UseFormReturn<Types.IForm.Update>>;
  item: Types.IForm.Update;
}

const Update: React.FC<UpdateProps> = ({ onSuccess, onError, defaultValues = {}, component: Component, item }) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const data = useForm<Types.IForm.Update>({
    resolver: yupResolver(updateSchema),
    defaultValues: {
      id: item?.id,
      id_template: item?.id_template,
      kind: item?.kind,
      alias: item?.alias,
      url: item?.url,
      style: item?.style
    }
  });

  const mutate = useMutation<Types.IForm.Update, Types.IUpdateApi.Update.Error, Types.IUpdateApi.Update.Request, any>(
    async (values: any) => {
      await Api.UpdateSimulation(values);
      console.log('onSubmit', values);

      return values;
    },
    {
      onError: (err: any) => {
        message.error(err.data.error);
        onError?.(err);
      },
      onSuccess: values => {
        onSuccess?.(values);

        queryClient.invalidateQueries([Constants.SIMULATIONS, 'list']);
        message.success('Simulation was successfully updated');
        navigate('/simulations');
      }
    }
  );

  const onSubmit = async (data: Types.IForm.Update) => {
    console.log('payload onSubmit', data);

    try {
      /* @ts-ignore */
      const payloadPromises = data.url.map(async (url: any, index: number): Promise<Types.IEntity.UpdateSimulation> => {
        let newUrl: string = '';

        const { kind } = url;

        console.log('url', url);

        // check if it's a new upload
        if (url.uid.includes('rc-upload')) {
          // First, create an empty record with an empty `url` field
          const emptyRecord: Types.IEntity.UpdateSimulation = {
            id_template: data.id_template,
            // @ts-ignore
            alias: data.url.length > 1 ? getFileName(url.name) : data.alias,
            kind: Constants.SimulationType.static,
            style: data.style
          };

          // Create the empty record on the backend and obtain the record ID
          const response = await Api.UpdateSimulation(emptyRecord);

          console.log('response', response);
          // @ts-ignore
          const recordId = response.data.id;

          // Upload the file to a path containing the obtained record ID
          newUrl = await uploadObject(`uploads/simulations/${recordId}`, url.name, url);

          // Now, update the record with the new URL
          const updatedRecord: Types.IEntity.UpdateSimulation = {
            ...emptyRecord,
            id: recordId,
            url: newUrl
          };

          await Api.UpdateSimulation(updatedRecord);

          return updatedRecord;
        }
        // If it's not a new upload, just return the existing URL
        return {
          ...(data.id && index === 0 && { id: data.id }),
          // @ts-ignore
          alias: data.url.length > 1 ? getFileName(url.name) : data.alias,
          kind,
          id_template: data.id_template,
          style: data.style
        };
      });

      const payload = await Promise.all(payloadPromises);

      await mutate.mutateAsync(payload);
    } catch (error) {
      console.error('Error in onSubmit:', error);
      message.error('Couldnt bundle request successfully');
    }
  };

  return (
    <form onSubmit={data.handleSubmit(onSubmit)}>
      <Component {...data} />
    </form>
  );
};

export default Update;
