import React from 'react';

const Visa: React.FC = () => (
  <svg width="50" height="30" viewBox="0 0 50 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.9778 9.21094C37.4017 9.21094 36.9167 9.54674 36.7003 10.0624L32.1953 20.8184H35.3466L35.9734 19.0857H39.8247L40.1888 20.8184H42.9655L40.5418 9.21094H37.9778ZM39.3277 16.7052H36.8368L38.4181 12.3466L39.3277 16.7052Z"
      fill="url(#paint0_linear_3349_3729)"
    />
    <path
      d="M15.0489 20.8184L19.9143 9.21094H16.7607L13.6348 17.1112L12.3707 10.3937C12.2222 9.64375 11.6364 9.21094 10.9857 9.21094H5.87632L5.80469 9.54823C6.85388 9.77583 8.04559 10.143 8.76719 10.5355C9.20895 10.7758 9.33507 10.9855 9.48058 11.5556L11.8752 20.8184H15.0489Z"
      fill="url(#paint1_linear_3349_3729)"
    />
    <path d="M21.7215 20.8184L24.205 9.21094H21.2029L18.7188 20.8184H21.7215Z" fill="url(#paint2_linear_3349_3729)" />
    <path
      d="M32.3518 17.0301C32.3637 13.9669 28.1147 13.7975 28.1438 12.4281C28.1535 12.0132 28.5498 11.57 29.4184 11.4573C29.8482 11.4006 31.034 11.3566 32.3787 11.9759L32.9062 9.51564C32.1831 9.25222 31.2533 9 30.096 9C27.1275 9 25.0396 10.5783 25.0231 12.8363C25.0022 14.5079 26.5126 15.4406 27.6513 15.9951C28.8207 16.5645 29.2139 16.9294 29.2094 17.4383C29.2005 18.2173 28.2767 18.5606 27.4118 18.574C25.9029 18.5972 25.0261 18.1666 24.3284 17.8412L23.7852 20.3844C24.4851 20.7067 25.7806 20.9881 27.1238 21C30.2773 21.0007 32.3406 19.4441 32.3518 17.0301Z"
      fill="url(#paint3_linear_3349_3729)"
    />
    <rect x="0.5" y="0.5" width="49" height="29" rx="7.5" stroke="black" strokeOpacity="0.15" />
    <defs>
      <linearGradient id="paint0_linear_3349_3729" x1="11.0783" y1="25.8038" x2="42.3978" y2="14.2695" gradientUnits="userSpaceOnUse">
        <stop stopColor="#222357" />
        <stop offset="1" stopColor="#254AA5" />
      </linearGradient>
      <linearGradient id="paint1_linear_3349_3729" x1="7.50946" y1="16.1104" x2="38.829" y2="4.57611" gradientUnits="userSpaceOnUse">
        <stop stopColor="#222357" />
        <stop offset="1" stopColor="#254AA5" />
      </linearGradient>
      <linearGradient id="paint2_linear_3349_3729" x1="8.82431" y1="19.6808" x2="40.1439" y2="8.14654" gradientUnits="userSpaceOnUse">
        <stop stopColor="#222357" />
        <stop offset="1" stopColor="#254AA5" />
      </linearGradient>
      <linearGradient id="paint3_linear_3349_3729" x1="9.67971" y1="22.0057" x2="40.9993" y2="10.4714" gradientUnits="userSpaceOnUse">
        <stop stopColor="#222357" />
        <stop offset="1" stopColor="#254AA5" />
      </linearGradient>
    </defs>
  </svg>
);

export default Visa;
