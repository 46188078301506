import { uuidv4 } from '@firebase/util';
import { UploadFile } from 'antd';
import imageCompression from 'browser-image-compression';
import { storage } from 'config';
import { deleteObject, getBlob, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import Resizer from 'react-image-file-resizer';

const mimeToExt: { [key: string]: string } = {
  'image/jpeg': 'jpeg',
  'image/png': 'png'
};

const resizeFile = (
  file: Blob,
  resolution: number,
  fileExtension: string,
  quality: number = 100,
  rotation: number = 0,
  outputType = 'blob'
) =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      resolution,
      resolution,
      fileExtension,
      quality,
      rotation,
      (uri: any) => {
        resolve(uri);
      },
      outputType,
      resolution,
      resolution
    );
  });

const uploadFile = async (
  path: string,
  file: UploadFile | Blob,
  maxSizeMB: number = 2,
  maxWidthOrHeight: number | undefined = undefined
) => {
  let fileExtension: string = '';

  // @ts-ignore
  if (!file.name) {
    // @ts-ignore
    fileExtension = mimeToExt[file.type];
  } else {
    // @ts-ignore
    fileExtension = file.name.split('.').pop();
  }
  try {
    let newFile: UploadFile | Blob = file;

    // @ts-ignore
    if (file.size / 1024 / 1024 >= maxSizeMB) {
      // @ts-ignore
      newFile = await imageCompression(file, { maxSizeMB, useWebWorker: true, alwaysKeepResolution: true });
    }

    if (maxWidthOrHeight) {
      // @ts-ignore
      newFile = await resizeFile(newFile, maxWidthOrHeight, fileExtension);
    }

    const uploadRef = ref(storage, `${path}/${uuidv4()}.${fileExtension}`);

    // @ts-ignore
    const snapshot = await uploadBytes(uploadRef, newFile);

    return await getDownloadURL(snapshot.ref);
  } catch (error) {
    console.log('error = ', error);
    return Error('Error');
  }
};

export const deleteAndReuploadFirebaseFile = async (file: string, path: string) => {
  const toDelete = ref(storage, file);
  const toUpload = await getBlob(toDelete);

  const newlink = await uploadFile(path, toUpload).then(async (e: any) => {
    await deleteObject(toDelete);
    return e;
  });

  return newlink;
};

export default uploadFile;
