import React from 'react';
import Icon from 'comfi-ui-kit/lib/components/icon';
import BaseProfile from 'comfi-ui-kit/lib/components/profile';
import { useAuth } from 'modules/auth/hooks';

interface IProps {}

const Profile: React.FC<IProps> = () => {
  const { user, methods } = useAuth();

  return (
    <BaseProfile
      username={`${user?.name}`}
      info={`Hi 👏🏻 ${user?.email!}`}
      menus={[
        {
          icon: <Icon name="logout" />,
          title: 'Log Out',
          onClick: methods.logout
        }
      ]}
    />
  );
};

export default Profile;
