import { FC } from 'react';

const CircleCheck: FC = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="18" rx="9" fill="#967621" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7426 5.03063C13.6749 5.04774 13.5746 5.08265 13.5198 5.10826C13.4507 5.14051 12.4758 6.03995 10.3422 8.03993L7.26425 10.9251L5.92157 9.66796C4.9768 8.78342 4.54762 8.39618 4.47337 8.36134C3.84839 8.06807 3.13043 8.40978 3.01228 9.05676C2.97896 9.23933 3.01402 9.44205 3.11059 9.62524C3.17243 9.74252 3.38375 9.94892 4.78303 11.2585L6.38466 12.7575L6.584 12.8465C7.0424 13.0511 7.48484 13.0512 7.94418 12.8468L8.14352 12.758L11.4804 9.63083C15.1227 6.21744 14.9209 6.42063 14.9841 6.10203C15.1137 5.44806 14.4291 4.85721 13.7426 5.03063Z"
      fill="#FFF8E7"
    />
  </svg>
);

export default CircleCheck;
