import get from 'lodash/get';
import moment from 'moment';

import * as Types from './types';

export const textureTypes: string[] = ['albedoMap', 'normalMap', 'roughnessMap', 'metallicMap'];

export const textureParamTypes: string[] = ['scale', 'offset', 'rotation'];

export const Template = (item: any): Types.IEntity.Template => {
  if (Array.isArray(item.materials)) {
    item.materials?.forEach((material: any) => {
      textureParamTypes.forEach((param: string) => {
        if (!material[param]) {
          if (param === 'rotation') {
            material[param] = 0;
          } else {
            material[param] = 1;
          }
        }
      });
      textureTypes.forEach((textureType: string) => {
        if (!material[textureType]) {
          material[textureType] = '';
        }
      });
    });
  }

  if (item.variants.length) {
    item.variants?.forEach((variant: any) => {
      if (!variant.zrpjProjectUrl) {
        variant.zrpjProjectUrl = '';
      }
      if (!variant.zrpjProjectUrlDefaultPose) {
        variant.zrpjProjectUrlDefaultPose = '';
      }
      if (!variant.objectUrlDefaultPose) {
        variant.objectUrlDefaultPose = '';
      }
    });
  }

  return {
    id: get(item, 'id'),
    createdat: {
      value: get(item, 'createdat'),
      label: moment(get(item, 'createdat')).format('MMMM DD, YYYY')
    },
    updatedat: {
      value: get(item, 'updateat'),
      label: moment(get(item, 'updateat')).format('MMMM DD, YYYY')
    },
    alias: get(item, 'alias') || '',
    ispublic: get(item, 'ispublic') || false,
    title: get(item, 'title') || '',
    title_ru: get(item, 'title_ru') || '',
    name: get(item, 'name') || '',
    gender: get(item, 'gender') || '',
    category: get(item, 'category') || '',
    subcategory: get(item, 'subcategory') || '',
    garmenttype: get(item, 'garmenttype') || '',
    materials: get(item, 'materials') || [],
    options: get(item, 'options') || [],
    fabrics: get(item, 'fabrics') || [],
    images: get(item, 'images') || [],
    variants: get(item, 'variants') || [],
    silhouette: get(item, 'silhouette') || { front: '', back: '' },
    requires_styled_simulation: get(item, 'requires_styled_simulation') || false
  };
};
