import React from 'react';

const Email: React.FC = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="80" height="80" fill="white" />
    <g filter="url(#filter0_d_3691_29494)">
      <mask id="mask0_3691_29494" maskUnits="userSpaceOnUse" x="6" y="3" width="69" height="72">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 3C18.4772 3 14 7.47715 14 13V19.9037C13.2459 19.9097 12.5331 19.9163 11.8668 19.9229C8.5958 19.9557 6 22.6207 6 25.8919V69C6 72.3137 8.68629 75 12 75H68.0745C71.359 75 74.0322 72.3723 74.0662 69.088C74.183 57.8024 74.3794 34.0355 74 31C73.5788 27.6308 71.7388 26.39 68.181 23.9909C67.5149 23.5418 66.7885 23.052 66 22.5V13C66 7.47715 61.5228 3 56 3H24Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_3691_29494)">
        <mask id="path-2-inside-1_3691_29494" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.1014 8.22119C41.5979 6.59293 38.37 6.59294 35.8666 8.22119L9.44807 25.4039C6.92303 27.0462 5.87599 29.6017 6.01161 32.0748V65.4991H73.9771V31.7681H73.9671C74.003 29.3923 72.9407 26.9784 70.5199 25.4039L44.1014 8.22119Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.1014 8.22119C41.5979 6.59293 38.37 6.59294 35.8666 8.22119L9.44807 25.4039C6.92303 27.0462 5.87599 29.6017 6.01161 32.0748V65.4991H73.9771V31.7681H73.9671C74.003 29.3923 72.9407 26.9784 70.5199 25.4039L44.1014 8.22119Z"
          fill="white"
        />
        <path
          d="M35.8666 8.22119L34.7761 6.54462L34.7761 6.54462L35.8666 8.22119ZM44.1014 8.22119L45.1918 6.54462V6.54462L44.1014 8.22119ZM9.44807 25.4039L10.5385 27.0804L9.44807 25.4039ZM6.01161 32.0748H8.01161V32.02L8.00861 31.9652L6.01161 32.0748ZM6.01161 65.4991H4.01161V67.4991H6.01161V65.4991ZM73.9771 65.4991V67.4991H75.9771V65.4991H73.9771ZM73.9771 31.7681H75.9771V29.7681H73.9771V31.7681ZM73.9671 31.7681L71.9673 31.7379L71.9366 33.7681H73.9671V31.7681ZM70.5199 25.4039L71.6103 23.7273V23.7273L70.5199 25.4039ZM36.957 9.89777C38.7975 8.70074 41.1705 8.70074 43.0109 9.89777L45.1918 6.54462C42.0253 4.48513 37.9426 4.48513 34.7761 6.54462L36.957 9.89777ZM10.5385 27.0804L36.957 9.89777L34.7761 6.54462L8.35761 23.7273L10.5385 27.0804ZM8.00861 31.9652C7.90921 30.1527 8.65761 28.3038 10.5385 27.0804L8.35761 23.7273C5.18845 25.7885 3.84276 29.0507 4.01461 32.1843L8.00861 31.9652ZM8.01161 65.4991V32.0748H4.01161V65.4991H8.01161ZM73.9771 63.4991H6.01161V67.4991H73.9771V63.4991ZM71.9771 31.7681V65.4991H75.9771V31.7681H71.9771ZM73.9671 33.7681H73.9771V29.7681H73.9671V33.7681ZM69.4294 27.0804C71.2363 28.2556 71.9935 30.0033 71.9673 31.7379L75.9668 31.7984C76.0125 28.7812 74.6451 25.7011 71.6103 23.7273L69.4294 27.0804ZM43.0109 9.89777L69.4294 27.0804L71.6103 23.7273L45.1918 6.54462L43.0109 9.89777Z"
          fill="black"
          mask="url(#path-2-inside-1_3691_29494)"
        />
        <rect x="15" y="4" width="50" height="61" rx="9" fill="white" stroke="black" strokeWidth="2" />
        <rect x="21" y="27" width="20" height="2" rx="1" fill="black" />
        <rect x="21" y="33.5684" width="25" height="2" rx="1" fill="black" />
        <path
          d="M21.706 16.3498C21.7794 16.0129 22.1121 15.7992 22.449 15.8726L24.1477 16.2427C24.6634 16.3551 25.0772 15.8166 24.8359 15.3472L24.0943 13.9046C23.9367 13.5979 24.0575 13.2214 24.3642 13.0638L24.8361 12.8212C25.1428 12.6635 25.5193 12.7843 25.6769 13.091L26.4448 14.5847C26.6771 15.0366 27.3231 15.0367 27.5554 14.5847L28.3233 13.091C28.481 12.7843 28.8574 12.6635 29.1641 12.8212L29.6361 13.0638C29.9428 13.2214 30.0636 13.5979 29.9059 13.9046L29.1643 15.3472C28.923 15.8166 29.3369 16.3551 29.8526 16.2427L31.5511 15.8726C31.888 15.7992 32.2207 16.0129 32.2941 16.3498L32.4071 16.8683C32.4805 17.2052 32.2669 17.5379 31.9299 17.6113L30.2659 17.9739C29.7497 18.0863 29.5978 18.7491 30.0134 19.0752L31.2845 20.0725C31.5558 20.2853 31.6032 20.6778 31.3904 20.9491L31.0628 21.3667C30.8499 21.638 30.4574 21.6854 30.1861 21.4725L28.9066 20.4686C28.4968 20.1471 27.8968 20.439 27.8968 20.9598V22.6252C27.8968 22.9701 27.6172 23.2496 27.2724 23.2496H26.7417C26.3969 23.2496 26.1173 22.9701 26.1173 22.6252V20.9491C26.1173 20.4282 25.5172 20.1363 25.1075 20.4578L23.8142 21.4725C23.5429 21.6853 23.1504 21.638 22.9375 21.3667L22.61 20.9491C22.3971 20.6778 22.4445 20.2853 22.7158 20.0725L23.9869 19.0752C24.4025 18.7491 24.2506 18.0864 23.7344 17.9739L22.0702 17.6113C21.7333 17.5379 21.5196 17.2052 21.5931 16.8683L21.706 16.3498Z"
          fill="black"
        />
        <mask id="path-8-inside-2_3691_29494" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9828 62.1822L6 73.9932V32.207L39.9828 54.7931L73.9655 32.207V73.9932L39.9828 62.1822Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.9828 62.1822L6 73.9932V32.207L39.9828 54.7931L73.9655 32.207V73.9932L39.9828 62.1822Z"
          fill="white"
        />
        <path
          d="M6 73.9932H4C4 74.6406 4.31336 75.248 4.84095 75.6232C5.36854 75.9983 6.04509 76.0949 6.65659 75.8824L6 73.9932ZM39.9828 62.1822L40.6394 60.293C40.2141 60.1452 39.7514 60.1452 39.3262 60.293L39.9828 62.1822ZM6 32.207L7.10706 30.5414C6.49319 30.1334 5.70467 30.0961 5.05504 30.4444C4.40542 30.7926 4 31.47 4 32.207H6ZM39.9828 54.7931L38.8757 56.4588C39.5464 56.9046 40.4191 56.9046 41.0898 56.4588L39.9828 54.7931ZM73.9655 32.207H75.9655C75.9655 31.4699 75.5601 30.7926 74.9105 30.4443C74.2609 30.0961 73.4723 30.1334 72.8585 30.5414L73.9655 32.207ZM73.9655 73.9932L73.3089 75.8824C73.9204 76.0949 74.597 75.9983 75.1246 75.6231C75.6522 75.248 75.9655 74.6406 75.9655 73.9932H73.9655ZM6.65659 75.8824L40.6394 64.0713L39.3262 60.293L5.34341 72.1041L6.65659 75.8824ZM4 32.207V73.9932H8V32.207H4ZM41.0898 53.1275L7.10706 30.5414L4.89294 33.8727L38.8757 56.4588L41.0898 53.1275ZM41.0898 56.4588L75.0726 33.8727L72.8585 30.5414L38.8757 53.1275L41.0898 56.4588ZM71.9655 32.207V73.9932H75.9655V32.207H71.9655ZM74.6221 72.1041L40.6394 60.293L39.3262 64.0713L73.3089 75.8824L74.6221 72.1041Z"
          fill="black"
          mask="url(#path-8-inside-2_3691_29494)"
        />
        <path
          d="M36.4036 52.3487C38.5789 50.9299 41.3868 50.9299 43.5621 52.3487L70.2625 69.7637C75.7206 73.3237 73.1997 81.8031 66.6833 81.8031H13.2824C6.76601 81.8031 4.24513 73.3237 9.70323 69.7637L36.4036 52.3487Z"
          fill="white"
          stroke="black"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <rect x="7" y="3" width="65.9655" height="71" rx="4.5" stroke="url(#paint0_linear_3691_29494)" strokeWidth="2" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_3691_29494"
        x="6"
        y="3"
        width="71.2222"
        height="74"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="3" dy="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.317647 0 0 0 0 0.988235 0 0 0 0 0.560784 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3691_29494" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3691_29494" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_3691_29494" x1="39.9828" y1="2" x2="39.9828" y2="75" gradientUnits="userSpaceOnUse">
        <stop offset="0.661458" stopOpacity="0" />
        <stop offset="0.71875" />
      </linearGradient>
    </defs>
  </svg>
);

export default Email;
