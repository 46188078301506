import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Avatar, Card, Select, Space } from 'antd';
import { Button, Icon, TopBar, Typography } from 'comfi-ui-kit/lib/components';
import { Col, Row } from 'comfi-ui-kit/lib/components/grid';
import { find } from 'modern-async';
import { Constants, Form, Types } from 'modules/simulations';
import useSimulationsList from 'modules/simulations/hooks/use-list';
import useTemplateList from 'modules/templates-main/hooks/use-list';
import { IEntity } from 'modules/templates-main/types';
import { convertUrlToUploadFileFormat } from 'services/functions';

import { ObjUpload, PageLoader } from 'components';
import { Input, TagInput } from 'components/fields';
import OptionSelector from 'components/fields/option-selector';
import SimulationPreview from 'components/simulation-preview';

import cls from './simulation-editor.module.scss';

const sampleSimulation: Types.IEntity.Simulation = {
  id_template: '',
  template_alias: '',
  alias: '',
  url: [],
  style: Constants.StyleType.flat
};

const { Option } = Select;

const SimulationEditor: FC = () => {
  const { id } = useParams();
  const { isLoading, items, refetch } = useSimulationsList();
  const { items: templates } = useTemplateList();
  const [currentItem, setCurrentItem] = useState<Types.IEntity.Simulation>();

  useEffect(() => {
    const fetchSetSim = async () => {
      if (id) {
        if (items.length) {
          await refetch();
        }

        const simulation = await find(items, item => item.id === id);

        if (simulation) {
          const updatedSimulation = {
            ...simulation,
            // @ts-ignore
            url: [await convertUrlToUploadFileFormat(simulation.url)]
          };

          // @ts-ignore
          setCurrentItem(updatedSimulation);
        }
      } else {
        setCurrentItem(sampleSimulation);
      }
    };

    fetchSetSim();
  }, [isLoading, id]);

  function getOptions() {
    if (templates.length) {
      return templates.map((template: IEntity.Template) => (
        <Option key={template.id} value={template.id} label={template.name}>
          <Space>
            <Avatar size="large" src={<img src={template.images[0]?.url ?? ''} alt="avatar" />} />
            {template.name}
          </Space>
        </Option>
      ));
    }
    return [];
  }

  return isLoading || !currentItem ? (
    <PageLoader />
  ) : (
    <div className={cls.wrapper}>
      <Form.Update
        item={currentItem}
        component={({ control, register, formState: { errors, isSubmitting }, resetField, watch, setValue }) => (
          <>
            <TopBar
              childrenLeft={
                <div className={cls['content-left--title']}>
                  <Link to="/simulations">
                    <Icon name="back" size={14} />
                  </Link>
                  <div className={cls['content-left--divider']} />
                  <Typography size={22} weight={500} lineHeight="25" className={cls.title}>
                    Edit simulations
                  </Typography>
                </div>
              }
              className={cls['top-bar']}
              childrenRight={
                <Button loading={isSubmitting} className={cls['top-bar--btn']} type="green" htmlType="submit" disabled={isSubmitting}>
                  {id ? 'Update' : 'Create'}
                </Button>
              }
            />
            <div className={cls.content}>
              <div className={cls['content-left']}>
                <div className={cls['content-left-form']}>
                  <Row gutter={[24, 24]}>
                    <Typography
                      className={cls.subtitle}
                      size={22}
                      infoContent="Fill in information about the template you are trying to create/update"
                    >
                      Simulations details
                    </Typography>
                    <Col span={24}>
                      <OptionSelector
                        placeholder="Select template"
                        options={getOptions()}
                        control={control}
                        name="id_template"
                        errorMsg={errors?.id_template?.message}
                      />
                    </Col>
                    <Col span={24}>
                      <Input
                        control={control}
                        name="alias"
                        type="text"
                        withCount
                        maxLength={50}
                        placeholder="Alias"
                        errorMsg={errors?.alias?.message}
                      />
                    </Col>
                    <Col span={24}>
                      <TagInput
                        options={Constants.styleTypeOptions}
                        control={control}
                        name="style"
                        type="text"
                        maxLength={100}
                        placeholder="Choose style"
                        errorMsg={errors?.style?.message}
                      />
                    </Col>
                    <Col span={24}>
                      <Card title="Upload simulation file" size="small">
                        <ObjUpload
                          maxCount={10}
                          control={control}
                          name="url"
                          resetField={resetField}
                          errorMsg={errors?.url?.message}
                          fileTypes={['fbx', 'obj', 'pc2', 'mdd', 'abc', 'glb']}
                        />
                      </Card>
                    </Col>
                  </Row>
                  <div style={{ margin: 20 }} />
                </div>
              </div>
              <div className={cls['content-right']}>
                <SimulationPreview
                  control={control}
                  items={templates}
                  initialSim={currentItem.url ? currentItem.url : null}
                  initialTemplate={currentItem.id_template}
                />
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default SimulationEditor;
