import React from 'react';
import { FileOutlined, InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { message, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload';
import { Control, Controller, UseFormResetField } from 'react-hook-form';

import cls from './obj-upload.module.scss';

const { Dragger } = Upload;

export interface ObjUploadProps {
  className?: string;
  errorMsg?: string;
  control: Control<any>;
  name: string;
  resetField: UseFormResetField<any>;
  maxCount?: number;
  fileTypes?: string[] | null;
}

const ObjUpload: React.FC<ObjUploadProps> = ({ control, name, maxCount = 1, resetField, errorMsg, fileTypes }) => {
  const props: UploadProps = {
    name,
    onChange(info: any) {
      const { status } = info.file;

      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {
      console.log('Dropped files', e.dataTransfer.files);
    }
  };

  const handleChange = (file: UploadFile, fileList: UploadFile[], field: any) => {
    if (fileTypes) {
      // @ts-ignore
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const isValid = fileTypes.includes(fileExtension);

      if (!isValid) {
        message.error(`${file.name} is not a valid file type`);
        // Return appropriate value or action to ignore the file
        return Upload.LIST_IGNORE; // Uncomment or modify based on your implementation
      }
    }

    // Assuming field.value is always an array.
    let updatedFileList = maxCount > 1 ? [...field.value] : fileList[fileList.length - 1];

    if (maxCount > 1) {
      // Add the new file to the existing list.
      // @ts-ignore
      updatedFileList = [...updatedFileList, ...fileList];
    }

    field.onChange(updatedFileList);
    return false;
  };

  const handleRemove = (file: UploadFile, field: any) => {
    const updatedValue = maxCount > 1 ? field.value.filter((obj: UploadFile) => obj.name !== file.name) : null;

    field.onChange(updatedValue);
  };

  function getFilesNames(field: any) {
    console.log('test', control);
    if (maxCount > 1 && field.value.length) {
      return (
        <div>
          <p className="ant-upload-drag-icon">
            <FileOutlined />
          </p>
          <p className="ant-upload-hint">{field.value.length} simulation file(s)</p>
        </div>
      );
    }
    if (maxCount === 1) {
      return (
        <div>
          <p className="ant-upload-drag-icon">
            <FileOutlined />
          </p>
          <p className="ant-upload-hint">Uploaded</p>
        </div>
      );
    }
    return (
      <div>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
        </p>
      </div>
    );
  }

  const getFileList = (field: any) =>
    maxCount > 1 ? field.value : [{ ...field.value, name: field.value.name || field.value, url: field.value.name ? null : field.value }];

  return (
    <div className={cls.wrapper}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          console.log('obj-uploader field = ', field);
          return (
            <Dragger
              {...props}
              multiple={maxCount > 1}
              beforeUpload={(file: UploadFile, fileList: UploadFile[]) => handleChange(file, fileList, field)}
              onRemove={file => handleRemove(file, field)}
              maxCount={maxCount}
              fileList={field.value ? getFileList(field) : []}
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}
            >
              {getFilesNames(field)}
            </Dragger>
          );
        }}
      />
      {errorMsg && (
        <div className={cls.message}>
          <span>{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

export default ObjUpload;
