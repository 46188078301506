/* eslint-disable react/no-unknown-property */
import React, { FC, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Center, Html, OrbitControls, PerspectiveCamera, Plane, useTexture } from '@react-three/drei';
import { Canvas, useLoader } from '@react-three/fiber';
import { Avatar, Select, Space } from 'antd';
import { Types } from 'modules/fabrics';
import useTemplateList from 'modules/templates-main/hooks/use-list';
import { IEntity } from 'modules/templates-main/types';
import { Mesh, Texture } from 'three';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { degrees_to_radians } from 'utils/helpers';

import cls from './material-preview.module.scss';

export interface MaterialPreviewProps {
  data: MaterialProps;
}

export interface MaterialProps {
  material: Types.IEntity.Material;
}

const { Option } = Select;

const MaterialPreview: FC<MaterialPreviewProps> = React.memo(({ data }) => {
  const [textures, setTextures] = useState<any>({});
  const { items } = useTemplateList();
  const [object, setObject] = useState<any>();
  const meshRef = useRef();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    items.length && setObject(items[0].variants[0].objectUrl);
  }, [items]);

  useMemo(() => {
    const textures: any = {};

    const renameTextures: any = {
      metallicmap: 'metalnessMap',
      roughnessmap: 'roughnessMap',
      normalmap: 'normalMap',
      albedomap: 'map',
      displacementmap: 'displacementMap'
    };

    Object.keys(data.material)
      .filter(x => x.includes('map'))
      // eslint-disable-next-line array-callback-return
      .map((x: string) => {
        /* @ts-ignore */
        const texture = data.material[x];

        if (texture !== '') {
          textures[renameTextures[x]] = texture instanceof Object ? URL.createObjectURL(texture) : texture;
        }
      });
    setTextures(textures);
    console.log('textures in material preview =', textures);
  }, [
    data.material.albedomap,
    data.material.metallicmap,
    data.material.normalmap,
    data.material.roughnessmap,
    data.material.displacementmap
  ]);

  function Geometry() {
    const ref = useRef();
    const garmentObj = useLoader(OBJLoader, object);

    // console.log('garmentObj', garmentObj);
    const garmentGeometry = useMemo(() => {
      let g;
      /* @ts-ignore */

      garmentObj.traverse(c => {
        if (c.type === 'Mesh') {
          // eslint-disable-next-line no-underscore-dangle
          const _c = c as Mesh;

          _c.matrixWorldAutoUpdate = true;
          _c.castShadow = true;
          g = _c.geometry;
        }
        // console.log('_c !== Mesh', c);
      });
      return g;
    }, [garmentObj]);

    const textureMaps = useTexture(textures, (textures: Texture | Texture[]) => {
      const { scale, off_set, rotation } = data.material;

      console.log('textures', textures);
      /* @ts-ignore */
      textures.forEach((texture: Texture) => {
        texture.needsUpdate = true;
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(scale * 1, scale * 1);
        texture.offset.set(off_set * 1, off_set * 1);
        texture.rotation = degrees_to_radians(rotation);
      });
    });

    const props = {
      ...(Object.keys(textures).length ? textureMaps : null)
    };

    return (
      <Center getObjectsByProperty="obj" top>
        {/* @ts-ignore */}
        <group ref={ref} dispose={null}>
          {/* @ts-ignore */}
          <mesh ref={meshRef} receiveShadow castShadow geometry={garmentGeometry}>
            <meshPhysicalMaterial
              displacementScale={data.material.displacementscale ?? 0}
              side={THREE.DoubleSide}
              needsUpdate={true}
              transparent={true}
              metalness={0.5}
              color="transparent"
              {...props}
            />
          </mesh>
        </group>
      </Center>
    );
  }

  const handleChange = (value: string) => {
    const template = items.find(item => item.id === value);

    setObject(template?.variants[0].objectUrl);
  };

  function getOptions() {
    if (items.length) {
      return items.map((template: IEntity.Template) => (
        <Option key={template.id} value={template.id} label={template.name}>
          <Space>
            <Avatar size="large" icon={<UserOutlined />} />
            {template.name}
          </Space>
        </Option>
      ));
    }
    return [];
  }

  return (
    <div className={cls.wrapper}>
      {items.length ? (
        <Select
          placeholder="select template"
          defaultValue={items[0].name}
          onChange={handleChange}
          showSearch
          optionLabelProp="label"
          /* @ts-ignore */
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          /* @ts-ignore */
          filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
        >
          {getOptions()}
        </Select>
      ) : null}
      {object ? (
        <Canvas camera={{ position: [6, 9, 18], fov: 5, zoom: 0.5 }} shadows>
          <color attach="background" args={['#cccccc']} />
          <fog attach="fog" args={['#cccccc', 30, 40]} />
          <directionalLight
            castShadow
            shadow-mapSize={[512, 512]}
            receiveShadow
            intensity={0.7}
            args={[0xffffff, 1]}
            position={[1, 1, 1]}
          />
          <hemisphereLight position={[10, 10, 10]} args={[0xffffff, 0x444444, 0.5]} />
          <ambientLight intensity={0.7} color="#FFFFFF" />
          <spotLight position={[10, 10, 10]} intensity={1} angle={0.5} penumbra={1} castShadow />
          <Suspense
            fallback={
              <Html center>
                <p style={{ width: '100%' }}>LOADING 3D model</p>
              </Html>
            }
          >
            <PerspectiveCamera getObjectsByProperty="obj" args={[45, window.innerWidth / window.innerHeight, 1, 100]}>
              <Geometry />
            </PerspectiveCamera>
          </Suspense>
          <OrbitControls enableZoom enablePan enableRotate target={[0, 0.1, 0]} />
          <gridHelper args={[50, 100, '#9B9B9B', '#808080']} />
          {/* @ts-ignore */}
          <Plane args={[50, 50]} receiveShadow={true} rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
            <meshStandardMaterial color="#9B9B9B" depthWrite={false} />
          </Plane>
        </Canvas>
      ) : null}
    </div>
  );
});

export default MaterialPreview;
