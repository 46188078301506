import get from 'lodash/get';
import moment from 'moment';
import { groupBy } from 'services/functions/helpers';

import * as Types from './types';
import { Constants } from '.';

export const mapAnimations = async (items: any[]): Promise<Types.IEntity.Simulation[]> => {
  const groupedItems = groupBy(items, 'id_template');

  const mappedGroups = await Promise.all(
    Object.keys(groupedItems).map(async id_template => {
      const templateAlias = get(groupedItems[id_template][0], 'template_alias', '');

      const mappedAnimations = Constants.predefinedAnimations.map(predefined => {
        const matchingItem = groupedItems[id_template].find(
          (item: any) => item.alias === predefined.alias && item.style === predefined.style
        );
        const newUrl = matchingItem ? [matchingItem.url] : []; // Keep URL as string

        return {
          id: matchingItem ? get(matchingItem, 'id') : '',
          style: predefined.style,
          url: newUrl,
          createdat: {
            value: matchingItem ? get(matchingItem, 'createdat', null) : null,
            label: matchingItem ? moment(get(matchingItem, 'createdat')).format('MMMM DD, YYYY') : ''
          },
          updatedat: {
            value: matchingItem ? get(matchingItem, 'updatedat', null) : null,
            label: matchingItem ? moment(get(matchingItem, 'updatedat')).format('MMMM DD, YYYY') : ''
          },
          kind: predefined.kind,
          alias: predefined.alias
        };
      });

      return {
        id_template,
        template_alias: templateAlias,
        animations: mappedAnimations
      };
    })
  );

  return mappedGroups;
};
