import React from 'react';
import { Badge, Card, Divider } from 'antd';
import { InputProps as BaseInputProps } from 'antd/lib/input';
import { Types } from 'modules/fabrics';
import { Control, Controller, UseFormRegister, UseFormResetField } from 'react-hook-form';

import { TextureUpload } from 'components';
import { InputSlider } from 'components/fields';

import cls from './material-editor.module.scss';

export interface UploadProps extends Pick<BaseInputProps, 'type' | 'disabled' | 'placeholder'> {
  className?: string;
  errorMsg?: string;
  control: Control<any>;
  name: string;
  resetField: UseFormResetField<any>;
  register: UseFormRegister<any>;
}

const MaterialEditor: React.FC<UploadProps> = ({ control, name, resetField, errorMsg, register }) => {
  const getMaterialView = (material: Types.IEntity.Material) => {
    console.log('material', material);
    return (
      <Badge.Ribbon text={material.type}>
        <Card size="small">
          <span>Textures:</span>
          <div className={cls.upload}>
            {Object.keys(material)
              .filter(x => x.includes('map'))
              .map((textureName: string) => (
                <TextureUpload
                  key={textureName}
                  resetField={resetField}
                  control={control}
                  name={`${name}.${textureName}`}
                  errorMsg={errorMsg}
                  textureName={textureName}
                />
              ))}
            <TextureUpload resetField={resetField} control={control} name="thumbnailurl" errorMsg={errorMsg} textureName="Preview" />
          </div>
          <Divider />
          <InputSlider control={control} name={`${name}.scale`} errorMsg={errorMsg} placeholder="Scale" min={1} max={50} />
          <InputSlider control={control} name={`${name}.off_set`} errorMsg={errorMsg} placeholder="Offset" min={0} max={50} />
          <InputSlider control={control} name={`${name}.rotation`} errorMsg={errorMsg} placeholder="Rotation" min={0} max={360} />
          {material.displacementmap !== '' ? (
            <InputSlider
              control={control}
              name={`${name}.displacementscale`}
              errorMsg={errorMsg}
              placeholder="Displacement scale"
              min={0}
              max={1}
            />
          ) : null}
        </Card>
      </Badge.Ribbon>
    );
  };

  return (
    <div className={cls.wrapper}>
      <Controller name={name} control={control} render={({ field }) => <div key={field.value.name}>{getMaterialView(field.value)}</div>} />
      {errorMsg && (
        <div className={cls.message}>
          <span>{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

export default MaterialEditor;
