import { useNavigate } from 'react-router-dom';
import { auth } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken, getIsFetched, getIsLogined, getIsVerified, getUser, getUserID, IAuth, logout as baseLogout } from 'store';

interface IReturn extends IAuth {
  methods: {
    logout: () => void;
  };
}

const useAuth = (): IReturn => {
  const dispatch = useDispatch();
  const user = useSelector(getUser)!;
  const accessToken = useSelector(getAccessToken)!;
  const isLogined = useSelector(getIsLogined);
  const isFetched = useSelector(getIsFetched);
  const isVerified = useSelector(getIsVerified);
  const userID = useSelector(getUserID);
  const navigate = useNavigate();

  const logout = () => {
    auth.signOut();
    dispatch(baseLogout());
    navigate('/');
  };

  return { user, accessToken, isLogined, isFetched, isVerified, userID, methods: { logout } };
};

export default useAuth;
