/* eslint-disable react/no-unknown-property */
import React, { FC, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { Center, Html, OrbitControls, PerspectiveCamera, Plane } from '@react-three/drei';
import { Canvas, useLoader } from '@react-three/fiber';
import { UploadFile } from 'antd';
import { Leva, useControls } from 'leva';
import { Types } from 'modules/templates-main';
import { Control, useWatch } from 'react-hook-form';
import { Mesh } from 'three';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

import cls from './simulation-preview.module.scss';

export interface SimulationPreviewProps {
  control: Control<any>;
  items: Types.IEntity.Template[];
  initialSim?: string | UploadFile<any>[] | null;
  initialTemplate: string;
}

const SimulationPreview: FC<SimulationPreviewProps> = React.memo(({ control, items, initialSim, initialTemplate }) => {
  const meshRef = useRef();
  const [object, setObject] = useState<any>();

  const id_template = useWatch({
    control,
    name: 'id_template', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: initialTemplate // default value before the render
  });

  const data = useWatch({
    control,
    name: 'url',
    defaultValue: initialSim
  });

  const simulationController = useControls(
    {
      sim: {
        options: data ? ['default', ...data.map((item: any) => item.name)] : ['default']
      }
    },
    [data]
  );

  useEffect(() => {
    async function loadObject() {
      if (items.length) {
        if (simulationController.sim === 'default') {
          const objUrl = items.find(item => item?.id === id_template)?.variants[0].objectUrl;

          if (objUrl) {
            setObject(objUrl);
          }
        } else {
          const obj = data.find((item: UploadFile) => item.name === simulationController.sim);

          if (obj) {
            setObject(URL.createObjectURL(obj));
          }
        }
      }
    }

    loadObject();
  }, [id_template, simulationController, items, data]);

  function Geometry() {
    const ref = useRef();

    const garmentObj = useLoader(OBJLoader, object);

    const garmentGeometry = useMemo(() => {
      let g: THREE.BufferGeometry;
      /* @ts-ignore */

      garmentObj.traverse(c => {
        if (c.type === 'Mesh') {
          // eslint-disable-next-line no-underscore-dangle
          const _c = c as Mesh;

          _c.matrixWorldAutoUpdate = true;
          _c.castShadow = true;
          g = _c.geometry;
          g!.computeBoundingBox();
        }
      });

      return g!;
    }, [garmentObj]);

    return (
      <Center getObjectsByProperty="obj" top>
        {/* @ts-ignore */}
        <group ref={ref} dispose={null}>
          {/* @ts-ignore */}
          <mesh ref={meshRef} receiveShadow castShadow geometry={garmentGeometry}>
            <meshPhysicalMaterial side={THREE.DoubleSide} needsUpdate={true} transparent={true} metalness={0.5} color="white" />
          </mesh>
        </group>
      </Center>
    );
  }

  return (
    <div className={cls.wrapper}>
      {object ? (
        <>
          <Leva hidden={window.screen.width <= 750} titleBar={{ position: { y: 80, x: 0 } }} />
          <Canvas camera={{ position: [6, 9, 18], fov: 5, zoom: 0.5 }} shadows>
            <color attach="background" args={['#cccccc']} />
            <fog attach="fog" args={['#cccccc', 30, 40]} />
            <directionalLight
              castShadow
              shadow-mapSize={[512, 512]}
              receiveShadow
              intensity={0.7}
              args={[0xffffff, 1]}
              position={[1, 1, 1]}
            />
            <hemisphereLight position={[10, 10, 10]} args={[0xffffff, 0x444444, 0.5]} />
            <ambientLight intensity={0.7} color="#FFFFFF" />
            <spotLight position={[10, 10, 10]} intensity={1} angle={0.5} penumbra={1} castShadow />
            <Suspense
              fallback={
                <Html center>
                  <p style={{ width: '100%' }}>LOADING 3D model</p>
                </Html>
              }
            >
              <PerspectiveCamera getObjectsByProperty="obj" args={[45, window.innerWidth / window.innerHeight, 1, 100]}>
                <Geometry />
              </PerspectiveCamera>
            </Suspense>
            <OrbitControls enableZoom enablePan enableRotate target={[0, 0.1, 0]} />
            <gridHelper args={[50, 100, '#9B9B9B', '#808080']} />
            {/* @ts-ignore */}
            <Plane args={[50, 50]} receiveShadow={true} rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
              <meshStandardMaterial color="#9B9B9B" depthWrite={false} />
            </Plane>
          </Canvas>
        </>
      ) : (
        <div style={{ flex: 1, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Please choose template first
        </div>
      )}
    </div>
  );
});

export default SimulationPreview;
