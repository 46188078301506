import React from 'react';

const Stripe: React.FC = () => (
  <svg width="46" height="20" viewBox="0 0 46 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.8308 10.3171C45.8308 7.07009 44.258 4.50801 41.252 4.50801C38.2333 4.50801 36.4069 7.07009 36.4069 10.2917C36.4069 14.1095 38.5631 16.0374 41.6579 16.0374C43.1673 16.0374 44.3088 15.6949 45.1713 15.213V12.6762C44.3088 13.1075 43.3195 13.3738 42.0638 13.3738C40.8335 13.3738 39.7427 12.9426 39.6032 11.4459H45.8054C45.8054 11.281 45.8308 10.6215 45.8308 10.3171ZM39.5651 9.11215C39.5651 7.67891 40.4403 7.08278 41.2394 7.08278C42.013 7.08278 42.8375 7.67891 42.8375 9.11215H39.5651Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.511 4.50801C30.2681 4.50801 29.469 5.09146 29.0251 5.49733L28.8602 4.71095H26.0698V19.5L29.2407 18.8278L29.2534 15.2383C29.71 15.5681 30.3822 16.0374 31.4984 16.0374C33.7687 16.0374 35.8361 14.2109 35.8361 10.1903C35.8235 6.51202 33.7307 4.50801 31.511 4.50801ZM30.75 13.247C30.0017 13.247 29.5578 12.9806 29.2534 12.6509L29.2407 7.94526C29.5705 7.57744 30.0271 7.32377 30.75 7.32377C31.9042 7.32377 32.7033 8.61749 32.7033 10.279C32.7033 11.9786 31.9169 13.247 30.75 13.247Z"
      fill="currentColor"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.7066 3.75968L24.8902 3.07477V0.5L21.7066 1.17223V3.75968Z" fill="currentColor" />
    <path d="M24.8902 4.72363H21.7066V15.8218H24.8902V4.72363Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2948 5.66222L18.0918 4.72363H15.3522V15.8218H18.5231V8.3004C19.2714 7.32376 20.5397 7.50133 20.9329 7.64085V4.72363C20.5271 4.57143 19.0431 4.29239 18.2948 5.66222Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.953 1.97129L8.85817 2.63084L8.84549 12.7904C8.84549 14.6676 10.2534 16.0501 12.1305 16.0501C13.1706 16.0501 13.9316 15.8598 14.3502 15.6315V13.0567C13.9443 13.2216 11.9403 13.8051 11.9403 11.9279V7.42523H14.3502V4.72363H11.9403L11.953 1.97129Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.37887 7.94526C3.37887 7.4506 3.78474 7.26035 4.45697 7.26035C5.42092 7.26035 6.63855 7.55207 7.6025 8.0721V5.09145C6.54976 4.6729 5.50971 4.50801 4.45697 4.50801C1.88221 4.50801 0.169922 5.85247 0.169922 8.09746C0.169922 11.5981 4.98968 11.0401 4.98968 12.5494C4.98968 13.1328 4.48234 13.3231 3.77206 13.3231C2.71932 13.3231 1.37486 12.8919 0.309441 12.3084V15.3271C1.48901 15.8344 2.68127 16.0501 3.77206 16.0501C6.41024 16.0501 8.22399 14.7437 8.22399 12.4733C8.21131 8.69359 3.37887 9.36582 3.37887 7.94526Z"
      fill="currentColor"
    />
  </svg>
);

export default Stripe;
