import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';

export function degrees_to_radians(degrees: number) {
  const pi = Math.PI;

  return degrees * (pi / 180);
}

export function downloadGLB(ref: any) {
  const exporter = new GLTFExporter();

  if (ref.current) {
    exporter.parse(
      ref.current,
      exported => {
        // @ts-ignore
        const data = new Blob([exported], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(data);
        const tempLink = document.createElement('a');

        tempLink.href = url;

        tempLink.setAttribute('download', 'obj.glb');

        tempLink.click();
      },
      error => {
        console.log('error', error);
      },
      { binary: true }
    );
  }
}
