import cx from 'classnames';
import { Button, Icon, Typography } from 'comfi-ui-kit/lib/components';

import cls from './empty.module.scss';

interface IProps {
  title: string;
  subtitle: string;
  buttonTitle?: string;
  onButtonClick?: () => void;
  className?: string;
  iconName?: 'createRequest' | 'createProduct';
}

const Empty: React.FC<IProps> = ({ title, subtitle, buttonTitle, onButtonClick, className = '', iconName = 'createRequest' }) => (
  <div className={cx(cls.wrapper, className)}>
    {/* <div className={cls.left}> */}
    <Icon name={iconName} size={60} />
    {/* </div> */}
    <div className={cls.text}>
      <Typography size={20} weight={650}>
        {title}
      </Typography>
      <Typography size={14} weight={400} lineHeight="20">
        {subtitle}
      </Typography>
      {buttonTitle && (
        <Button cy="empty-add-request-btn" className={cls.btn} onClick={onButtonClick}>
          {buttonTitle}
        </Button>
      )}
    </div>
  </div>
);

export default Empty;
