import React from 'react';

const NoAccess: React.FC = () => (
  <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_3813_22056)">
      <rect x="19" y="1" width="23.4211" height="53.9263" rx="11.7105" fill="white" stroke="black" strokeWidth="2" />
      <rect x="1" y="32" width="59" height="36" rx="18" fill="white" stroke="black" strokeWidth="2" />
      <g clipPath="url(#clip0_3813_22056)">
        <path
          d="M10.3826 47.1064C10.0304 46.7542 9.45937 46.7542 9.10717 47.1064C8.75496 47.4586 8.75496 48.0297 9.10717 48.3819L10.3826 47.1064ZM14.6174 53.8921C14.9696 54.2443 15.5406 54.2443 15.8929 53.8921C16.2451 53.5399 16.2451 52.9689 15.8929 52.6166L14.6174 53.8921ZM9.10717 52.6166C8.75496 52.9689 8.75496 53.5399 9.10717 53.8921C9.45937 54.2443 10.0304 54.2443 10.3826 53.8921L9.10717 52.6166ZM15.8929 48.3819C16.2451 48.0297 16.2451 47.4586 15.8929 47.1064C15.5406 46.7542 14.9696 46.7542 14.6174 47.1064L15.8929 48.3819ZM9.10717 48.3819L14.6174 53.8921L15.8929 52.6166L10.3826 47.1064L9.10717 48.3819ZM10.3826 53.8921L15.8929 48.3819L14.6174 47.1064L9.10717 52.6166L10.3826 53.8921Z"
          fill="black"
        />
      </g>
      <g clipPath="url(#clip1_3813_22056)">
        <path
          d="M22.3826 47.1064C22.0304 46.7542 21.4594 46.7542 21.1072 47.1064C20.755 47.4586 20.755 48.0297 21.1072 48.3819L22.3826 47.1064ZM26.6174 53.8921C26.9696 54.2443 27.5406 54.2443 27.8929 53.8921C28.2451 53.5399 28.2451 52.9689 27.8929 52.6166L26.6174 53.8921ZM21.1072 52.6166C20.755 52.9689 20.755 53.5399 21.1072 53.8921C21.4594 54.2443 22.0304 54.2443 22.3826 53.8921L21.1072 52.6166ZM27.8929 48.3819C28.2451 48.0297 28.2451 47.4586 27.8929 47.1064C27.5406 46.7542 26.9696 46.7542 26.6174 47.1064L27.8929 48.3819ZM21.1072 48.3819L26.6174 53.8921L27.8929 52.6166L22.3826 47.1064L21.1072 48.3819ZM22.3826 53.8921L27.8929 48.3819L26.6174 47.1064L21.1072 52.6166L22.3826 53.8921Z"
          fill="black"
        />
      </g>
      <g clipPath="url(#clip2_3813_22056)">
        <path
          d="M34.3826 47.1064C34.0304 46.7542 33.4594 46.7542 33.1072 47.1064C32.755 47.4586 32.755 48.0297 33.1072 48.3819L34.3826 47.1064ZM38.6174 53.8921C38.9696 54.2443 39.5406 54.2443 39.8929 53.8921C40.2451 53.5399 40.2451 52.9689 39.8929 52.6166L38.6174 53.8921ZM33.1072 52.6166C32.755 52.9689 32.755 53.5399 33.1072 53.8921C33.4594 54.2443 34.0304 54.2443 34.3826 53.8921L33.1072 52.6166ZM39.8929 48.3819C40.2451 48.0297 40.2451 47.4586 39.8929 47.1064C39.5406 46.7542 38.9696 46.7542 38.6174 47.1064L39.8929 48.3819ZM33.1072 48.3819L38.6174 53.8921L39.8929 52.6166L34.3826 47.1064L33.1072 48.3819ZM34.3826 53.8921L39.8929 48.3819L38.6174 47.1064L33.1072 52.6166L34.3826 53.8921Z"
          fill="black"
        />
      </g>
      <g clipPath="url(#clip3_3813_22056)">
        <path
          d="M46.3826 47.1064C46.0304 46.7542 45.4594 46.7542 45.1072 47.1064C44.755 47.4586 44.755 48.0297 45.1072 48.3819L46.3826 47.1064ZM50.6174 53.8921C50.9696 54.2443 51.5406 54.2443 51.8929 53.8921C52.2451 53.5399 52.2451 52.9689 51.8929 52.6166L50.6174 53.8921ZM45.1072 52.6166C44.755 52.9689 44.755 53.5399 45.1072 53.8921C45.4594 54.2443 46.0304 54.2443 46.3826 53.8921L45.1072 52.6166ZM51.8929 48.3819C52.2451 48.0297 52.2451 47.4586 51.8929 47.1064C51.5406 46.7542 50.9696 46.7542 50.6174 47.1064L51.8929 48.3819ZM45.1072 48.3819L50.6174 53.8921L51.8929 52.6166L46.3826 47.1064L45.1072 48.3819ZM46.3826 53.8921L51.8929 48.3819L50.6174 47.1064L45.1072 52.6166L46.3826 53.8921Z"
          fill="black"
        />
      </g>
    </g>
    <defs>
      <filter id="filter0_d_3813_22056" x="0" y="0" width="64" height="71" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="3" dy="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.317647 0 0 0 0 0.988235 0 0 0 0 0.560784 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3813_22056" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3813_22056" result="shape" />
      </filter>
      <clipPath id="clip0_3813_22056">
        <rect width="9" height="9" fill="white" transform="translate(8 46)" />
      </clipPath>
      <clipPath id="clip1_3813_22056">
        <rect width="9" height="9" fill="white" transform="translate(20 46)" />
      </clipPath>
      <clipPath id="clip2_3813_22056">
        <rect width="9" height="9" fill="white" transform="translate(32 46)" />
      </clipPath>
      <clipPath id="clip3_3813_22056">
        <rect width="9" height="9" fill="white" transform="translate(44 46)" />
      </clipPath>
    </defs>
  </svg>
);

export default NoAccess;
