import React from 'react';

const Note: React.FC = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5002 14.3666C11.2557 14.3666 14.3002 11.3221 14.3002 7.5666C14.3002 3.81107 11.2557 0.766602 7.5002 0.766602C3.74466 0.766602 0.700195 3.81107 0.700195 7.5666C0.700195 11.3221 3.74466 14.3666 7.5002 14.3666ZM6.4631 3.57867C6.35112 3.13977 6.6512 2.67556 7.15279 2.51175C7.409 2.42806 7.79891 2.46406 8.03704 2.59342C8.44041 2.8125 8.64222 3.20287 8.54634 3.57867C8.2935 4.56977 6.71594 4.56977 6.4631 3.57867ZM5.56864 6.74738C5.1939 6.27233 5.38166 5.63193 5.97143 5.37377C6.17999 5.28247 6.21243 5.27936 6.96052 5.27972C7.68277 5.28005 7.74785 5.28573 7.94274 5.36546C8.05755 5.41245 8.21467 5.51408 8.29188 5.59135C8.35054 5.65003 8.39669 5.69277 8.4331 5.74261C8.57413 5.93562 8.56923 6.23524 8.5799 7.98035L8.59199 9.96718H8.70717C8.87092 9.96718 9.23374 10.1353 9.36547 10.2722C9.56733 10.482 9.64328 10.6842 9.62135 10.9534C9.59913 11.2264 9.52464 11.3765 9.32383 11.5529C9.04422 11.7985 8.95472 11.8167 8.02249 11.8167H7.18539L6.98174 11.706C6.71635 11.5618 6.52334 11.3358 6.46635 11.1023C6.43679 10.9812 6.42083 10.2577 6.42083 9.04012V7.16448L6.2158 7.1217C5.95613 7.0675 5.70796 6.92397 5.56864 6.74738Z"
      fill="currentColor"
    />
  </svg>
);

export default Note;
