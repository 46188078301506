import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const User = (): AxiosPromise<Types.IApi.User.Response> => http.request.post('/user');

export const CreateUser = ({
  name,
  email,
  phone,
  avatarUrl
}: Types.IApi.CreateUser.Request): AxiosPromise<Types.IApi.CreateUser.Response> =>
  http.request.put('/create_user', {
    name,
    email,
    phone,
    avatarUrl
  });
