import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { TableColumnsType } from 'antd';
import { Button, Space, Typography } from 'antd';
import { Table, TableSkeleton, Tag } from 'comfi-ui-kit/lib/components';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import * as Types from 'modules/simulations/types';
import moment from 'moment';
import { blobPromise } from 'utils/blobpromise';

import Empty from 'components/empty/empty';

import cls from './list.module.scss';

interface ListProps {
  items: Types.IEntity.Simulation[];
  loading: boolean;
  tableLoading: boolean;
  onDelete: (selectedRowKeys: any) => void;
  selectedRowKeys: string[];
  handleRowChange: (selectedRowKeys: any) => void;
  setTableLoading: (state: boolean) => void;
}

interface ExpandedDataType {
  key: React.Key;
  id: string;
  createdat: string;
  updatedat: string;
  style: string;
  name: string;
  kind: string;
}

const List: FC<ListProps> = ({ items, loading, tableLoading, onDelete, selectedRowKeys, handleRowChange, setTableLoading }) => {
  const [data, setData] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Count the occurrences of each id_template
    const templateCount = items.reduce((acc: any, item: any) => {
      acc[item.id_template] = (acc[item.id_template] || 0) + 1;
      return acc;
    }, {});

    // Use a map to store unique items by id_template
    const uniqueItemsMap = items.reduce((acc: any, item: any) => {
      if (!acc[item.id_template]) {
        acc[item.id_template] = {
          key: item.id_template,
          templatename: item.template_alias,
          count: templateCount[item.id_template],
          simulations: []
        };
      }
      acc[item.id_template].simulations.push({
        id: item.id,
        key: item.id_template,
        name: item.alias,
        kind: item.kind,
        url: item.url,
        templatename: item.template_alias,
        updatedat: item.updatedat,
        createdat: item.createdat,
        style: item.style
      });
      return acc;
    }, {});

    // Add latest createdat and updatedat to each unique item
    const data = Object.values(uniqueItemsMap).map((item: any) => {
      const latestCreatedat = item.simulations.reduce((latest: string, sim: any) => (latest > sim.createdat ? latest : sim.createdat), '');
      const latestUpdatedat = item.simulations.reduce((latest: string, sim: any) => (latest > sim.updatedat ? latest : sim.updatedat), '');

      return {
        ...item,
        createdat: latestCreatedat,
        updatedat: latestUpdatedat
      };
    });

    setData(data);
  }, [items]);

  const handleDownload = async (item: any) => {
    const zip = new JSZip();
    const folder = zip.folder(`${item.templatename}_${item.name}`);
    const filename = `${item.templatename}_${item.name}_${item.id}.zip`;

    setTableLoading(true);

    if (item.url !== '') {
      const name = `${item.templatename}_${item.name}.obj`;

      // @ts-ignore
      folder?.file(name, blobPromise(item.url));
    }
    setTableLoading(false);
    zip.generateAsync({ type: 'blob', mimeType: 'application/octet-stream' }).then(blob => saveAs(blob, filename));
  };

  const expandedRowRender = (record: any) => {
    const columns: TableColumnsType<ExpandedDataType> = [
      {
        title: 'Name',
        fixed: true,
        dataIndex: '',
        render: (item: any) => (
          <a href={`/simulations/editor/${item.id}`} target="_blank" rel="noreferrer">
            <Typography>{item.name}</Typography>
          </a>
        )
      },
      {
        title: 'Type',
        dataIndex: '',
        render: (item: any) => (
          <a href={`/simulations/editor/${item.id}`} target="_blank" rel="noreferrer">
            <Tag variant="green">{item.kind}</Tag>
          </a>
        )
      },
      {
        title: 'Style',
        dataIndex: '',
        render: (item: any) => (
          <a href={`/simulations/editor/${item.id}`} target="_blank" rel="noreferrer">
            <Tag variant="orange">{item.style}</Tag>
          </a>
        )
      },
      {
        title: 'Created',
        dataIndex: 'createdat',
        key: 'createdat',
        render: createdat => createdat.label,
        defaultSortOrder: 'descend',
        sorter: (a, b) => moment(a.createdat).unix() - moment(b.createdat).unix()
      },
      // {
      //   title: 'Updated',
      //   dataIndex: 'updatedat',
      //   key: 'updatedat',
      //   render: updatedat => updatedat.label,
      //   sorter: (a, b) => moment(a.updatedat).unix() - moment(b.updatedat).unix()
      // },
      {
        title: 'Action',
        dataIndex: '',
        key: 'operation',
        render: (item: any) => (
          <Space size="middle">
            <Button onClick={() => handleDownload(item)}>Download</Button>
            <Button onClick={() => onDelete([item.id])}>Delete</Button>
          </Space>
        )
      }
    ];

    return <Table columns={columns} dataSource={record.simulations} pagination={false} loading={tableLoading} />;
  };

  const columns: TableColumnsType<any> = [
    { title: 'Template name', dataIndex: 'templatename', key: 'templatename' },
    { title: 'Simulations', dataIndex: 'count', key: 'count' },
    {
      title: 'Created At',
      dataIndex: 'createdat',
      key: 'createdat',
      render: createdat => createdat.label,
      sorter: (a, b) => moment(a.createdat?.value).unix() - moment(b.createdat?.value).unix(),
      defaultSortOrder: 'descend'
    },
    {
      title: 'Latest update',
      dataIndex: 'updatedat',
      key: 'updatedat',
      render: updatedat => updatedat.label,
      sorter: (a, b) => moment(a.updatedat?.value).unix() - moment(b.updatedat?.value).unix()
    }
  ];

  return loading ? (
    <TableSkeleton />
  ) : (
    <div className={cls.wrapper}>
      {items?.length ? (
        <Table
          className={cls.table}
          columns={columns}
          expandable={{ expandedRowRender }}
          dataSource={data}
          scroll={{ x: true }}
          pagination={false}
          size="small"
          loading={tableLoading}
          rowKey="key"
        />
      ) : (
        <div className={cls.empty}>
          <Empty
            buttonTitle="Add a simulation"
            subtitle="Easily create a simulation"
            title="Create your first simulation"
            onButtonClick={() => navigate('/simulations/editor')}
          />
        </div>
      )}
    </div>
  );
};

export default List;
