import { SelectOption } from 'pages/template-editor/template-editor';

export enum SimulationType {
  static = 'static',
  dynamic = 'dynamic'
}

export enum StyleType {
  flat = 'flat',
  middle = 'middle',
  high = 'high'
}

export enum AnimationType {
  animation_01 = 'animation_01',
  animation_02 = 'animation_02'
}

export const ANIMATIONS = 'ANIMATIONS';
export const SINGLEANIMATION = 'SINGLEANIMATION';

export const styleTypeOptions: SelectOption[] = [
  {
    label: StyleType.flat,
    value: StyleType.flat
  },
  {
    label: StyleType.middle,
    value: StyleType.middle
  },
  {
    label: StyleType.high,
    value: StyleType.high
  }
];

export const predefinedAnimations = [
  { url: [], alias: 'animation_01', style: StyleType.flat, kind: SimulationType.dynamic },
  { url: [], alias: 'animation_01', style: StyleType.middle, kind: SimulationType.dynamic },
  { url: [], alias: 'animation_01', style: StyleType.high, kind: SimulationType.dynamic },
  { url: [], alias: 'animation_02', style: StyleType.flat, kind: SimulationType.dynamic },
  { url: [], alias: 'animation_02', style: StyleType.middle, kind: SimulationType.dynamic },
  { url: [], alias: 'animation_02', style: StyleType.high, kind: SimulationType.dynamic }
];
