import React from 'react';
import { Select } from 'antd';
import { InputProps as BaseInputProps } from 'antd/lib/input';
import { Icon } from 'comfi-ui-kit/lib/components';
import { Control, Controller } from 'react-hook-form';

import cls from './option-selector.module.scss';

export interface InputKeyValueProps
  extends Pick<BaseInputProps, 'type' | 'disabled' | 'placeholder' | 'maxLength' | 'min' | 'pattern' | 'step'> {
  errorMsg?: string;
  control: Control<any>;
  name: string;
  options: any;
}

// eslint-disable-next-line arrow-body-style
const OptionSelector: React.FC<InputKeyValueProps> = ({ errorMsg, control, name, placeholder, options }) => {
  return (
    <div className={cls.wrapper}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            placeholder={placeholder}
            defaultOpen={field.value === ''}
            showSearch
            value={field.value}
            optionLabelProp="label"
            /* @ts-ignore */
            filterOption={(iv, op) => op?.label?.toLocaleLowerCase().includes((iv || '').toLocaleLowerCase())}
            /* @ts-ignore */
            filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              field.onChange(e);
            }}
            className={cls.antSelector}
          >
            {options}
          </Select>
        )}
      />
      {errorMsg && (
        <div className={cls.message}>
          <Icon name="exclamation" /> <span>{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

export default OptionSelector;
