import React from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import { useAuth } from 'modules/auth/hooks';
import * as Pages from 'pages';

import ProtectedRoute from './protected-route';

const Routes: React.FC = () => {
  const { user, isLogined } = useAuth();

  return (
    <Switch>
      <Route path="/templates" element={<ProtectedRoute navigate="/login" allow={!!user} />}>
        <Route index element={<Pages.Templates />} />
        <Route path="editor/:id" element={<Pages.TemplateEditor />} />
        <Route path="editor" element={<Pages.TemplateEditor />} />
      </Route>
      <Route path="/simulations" element={<ProtectedRoute navigate="/login" allow={!!user} />}>
        <Route index element={<Pages.Simulations />} />
        <Route path="editor/:id" element={<Pages.SimulationEditor />} />
        <Route path="editor" element={<Pages.SimulationEditor />} />
      </Route>
      <Route path="/animations" element={<ProtectedRoute navigate="/login" allow={!!user} />}>
        <Route index element={<Pages.Animations />} />
        <Route path="editor/:id" element={<Pages.AnimationEditor />} />
        <Route path="editor" element={<Pages.AnimationEditor />} />
      </Route>
      <Route path="/fabrics" element={<ProtectedRoute navigate="/login" allow={!!user} />}>
        <Route index element={<Pages.Fabrics />} />
        <Route path="editor/:id" element={<Pages.FabricEditor />} />
        <Route path="editor" element={<Pages.FabricEditor />} />
      </Route>
      <Route path="/patterns" element={<ProtectedRoute navigate="/login" allow={!!user} />}>
        <Route index element={<Pages.Patterns />} />
        <Route path="editor/:id" element={<Pages.PatternsEditor />} />
        <Route path="editor" element={<Pages.PatternsEditor />} />
      </Route>
      <Route path="login" element={user ? <Navigate to="/templates" /> : <Pages.Auth />} />
      <Route path="*" element={user ? <Navigate to="/templates" /> : <Navigate to="/login" />} />
    </Switch>
  );
};

export default Routes;
