import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { message, Upload as AntUpload, UploadFile } from 'antd';
import { InputProps as BaseInputProps } from 'antd/lib/input';
import { Control, Controller, UseFormResetField } from 'react-hook-form';

import cls from './sillhoute-upload.module.scss';

export interface SilhouetteUploadProps extends Pick<BaseInputProps, 'type' | 'disabled' | 'placeholder'> {
  className?: string;
  errorMsg?: string;
  control: Control<any>;
  name: string;
  resetField: UseFormResetField<any>;
  side: string;
}

const SilhouetteUpload: React.FC<SilhouetteUploadProps> = ({ control, name, resetField, errorMsg, side }) => {
  const uploadButton = () => (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload {side}</div>
    </div>
  );

  const handleChange = (file: UploadFile, fileList: UploadFile[], field: any) => {
    const isPNG = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg';

    if (!isPNG) {
      message.error(`${file.name} is not a png/jpg/jpeg file`);
      return isPNG || AntUpload.LIST_IGNORE;
    }

    const newFile = fileList.length ? fileList[fileList.length - 1] : '';

    field.onChange(newFile);

    return false;
  };

  return (
    <div className={cls.wrapper}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <AntUpload
            key={side}
            beforeUpload={(file: UploadFile, fileList: UploadFile[]) => handleChange(file, fileList, field)}
            listType="picture-card"
            showUploadList={false}
            multiple={false}
            fileList={[field.value]}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}
          >
            {field.value ? (
              <div>
                <img
                  src={field.value instanceof Object ? URL.createObjectURL(field.value) : field.value}
                  alt="texture"
                  style={{ width: '100%' }}
                />
                {side}
              </div>
            ) : (
              uploadButton()
            )}
          </AntUpload>
        )}
      />
      {errorMsg && (
        <div className={cls.message}>
          <span>{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

export default SilhouetteUpload;
