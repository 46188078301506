import { config } from 'config';
import { auth } from 'config/firebase';
import { http } from 'services';

http.init({
  configFn: async () => {
    const token = await auth.currentUser?.getIdToken();

    return {
      baseURL: config.api.baseURL,
      headers: {
        ...(token ? { token } : {})
      }
    };
  }
});
