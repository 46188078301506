import React from 'react';

const Hide: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.45 5.01732C6.47814 5.18999 3.67324 6.79508 1.43703 9.60265C0.853149 10.3357 0.853606 10.466 1.44273 11.2076C3.67433 14.017 6.4889 15.6212 9.47478 15.7855C12.8542 15.9715 16.0474 14.3615 18.563 11.2032C19.1469 10.4701 19.1464 10.3399 18.5573 9.59822C16.9271 7.54597 14.9876 6.13595 12.8429 5.44379C12.1766 5.22876 11.3694 5.07165 10.7381 5.03405C10.0492 4.99305 9.90064 4.99113 9.45 5.01732ZM10.7434 6.66739C11.7536 6.85912 12.7243 7.55196 13.2501 8.45652C13.8846 9.54814 13.9515 10.8686 13.4289 11.9844C13.225 12.4198 13.0301 12.703 12.6944 13.0519C10.8155 15.0042 7.61445 14.4073 6.52478 11.9014C6.13154 10.9971 6.12413 9.89245 6.50499 8.94983C6.87553 8.03275 7.68127 7.22977 8.59649 6.86554C9.2925 6.58857 9.98921 6.52424 10.7434 6.66739ZM9.63094 8.38135C9.05936 8.506 8.55344 8.86551 8.26371 9.35297C8.05071 9.71131 7.97925 9.97737 7.98171 10.4029C7.98414 10.8135 8.02038 10.9791 8.18315 11.3235C8.33274 11.6398 8.7696 12.0789 9.0899 12.2349C10.2178 12.7841 11.5298 12.2255 11.9357 11.0231C12.0519 10.6792 12.0551 10.1399 11.9432 9.7812C11.6877 8.96248 10.942 8.39198 10.0879 8.36182C9.91389 8.35569 9.70827 8.36448 9.63094 8.38135Z"
      fill="currentColor"
    />
  </svg>
);

export default Hide;
