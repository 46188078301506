import { object, string } from 'yup';

import { IForm } from '../types';

export const updateSchema = object().shape({
  name: string().required().label('Name').trim().max(50)
});

export const getMetaSchema = (item: string, key: 'key' | 'value') =>
  string().when(`meta.${item}.${key}` as keyof IForm.Update, val => {
    if (val) return string().notRequired().label(key.toUpperCase());
    return string().required().label(key.toUpperCase());
  });
