import React from 'react';

const Tower: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3089_1071)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.62285 2.11448L1.37042 4.24406L10.0127 4.24607L18.6549 4.24807L14.4241 2.13291C11.2063 0.524184 10.1553 0.0138503 10.0343 0.00136451C9.88182 -0.0143922 9.70034 0.072551 5.62285 2.11448ZM1.02264 5.39993C1.16345 6.00104 1.89122 7.12729 2.27268 7.33434L2.41845 7.41348H10H17.5815L17.7273 7.33434C18.1088 7.12729 18.8365 6.00104 18.9774 5.39993L19 5.30321H10H1L1.02264 5.39993ZM1.96644 15.939C1.68903 16.0602 1.00134 17.1463 1.00134 17.4632C1.00134 17.6462 1.12275 17.8481 1.28163 17.9292C1.41953 17.9996 1.4706 18 10 18C18.5294 18 18.5805 17.9996 18.7184 17.9292C18.8784 17.8475 18.9987 17.646 18.9987 17.4594C18.9987 17.1983 18.3931 16.1926 18.1085 15.9811L18.0093 15.9073L10.0414 15.8998C3.47773 15.8936 2.0546 15.9005 1.96644 15.939Z"
        fill="black"
      />
      <rect x="3.57471" y="8.70703" width="2.47985" height="5.97432" fill="black" />
      <rect x="8.75977" y="8.70703" width="2.47985" height="5.97432" fill="black" />
      <rect x="13.9448" y="8.70703" width="2.47985" height="5.97432" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_3089_1071">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Tower;
