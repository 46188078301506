import * as yup from 'yup';

export const emailSchema = yup.object().shape({
  email: yup.string().trim().email('Enter a valid email').required()
});

export const loginSchema = yup.object().shape({
  email: yup.string().email('Enter a valid email').required(),
  password: yup.string().min(8, 'Password is too short - should be 8 chars minimum.').required()
});

export const registerSchema = yup.object().shape({
  email: yup.string().email('Enter a valid email').required(),
  password: yup.string().min(8, 'Password is too short - should be 8 chars minimum.').required(),
  confirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
});

export const resetSchema = yup.object().shape({
  email: yup.string().email('Enter a valid email').required()
});

export const newSchema = yup.object().shape({
  password: yup.string().min(8, 'Password is too short - should be 8 chars minimum.').required(),
  confirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
});
