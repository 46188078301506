import { FC } from 'react';
import { Form } from 'modules/firebase';

import { Input } from 'containers/fields';

import { Button } from 'components';

import cls from './reset.module.scss';

const ResetPassword: FC = () => (
  <Form.Reset>
    {({ control, watch, formState: { errors } }) => (
      <div className={cls.wrapper}>
        <div className={cls.form}>
          <h1 className={cls.title}>Enter your email to reset password</h1>
          <Input errorMsg={errors.email?.message} placeholder="Email" control={control} className={cls.input} name="email" />
          <Button disabled={!watch('email')} className={cls.btn} htmlType="submit">
            Continue
          </Button>
        </div>
      </div>
    )}
  </Form.Reset>
);

export default ResetPassword;
