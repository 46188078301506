import React, { ComponentType } from 'react';
import { useNavigate } from 'react-router-dom';
import { uuidv4 } from '@firebase/util';
import { yupResolver } from '@hookform/resolvers/yup';
import { message } from 'antd';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { uploadFile } from 'services/functions';

import * as Constants from '../constants';
import * as Types from '../types';
import { Api } from '..';

import { updateSchema } from './schema';

interface UpdateProps {
  defaultValues?: Partial<Types.IForm.Update>;
  onSuccess?: (values: Types.IForm.Update) => void;
  onError?: (values: Types.IUpdateApi.Update.Error) => void;
  component: ComponentType<UseFormReturn<Types.IForm.Update>>;
  item: Types.IForm.Update;
}

const Update: React.FC<UpdateProps> = ({ onSuccess, onError, defaultValues = {}, component: Component, item }) => {
  const navigate = useNavigate();
  // const { user } = useAuth();

  const queryClient = useQueryClient();

  const data = useForm<Types.IForm.Update>({
    resolver: yupResolver(updateSchema),
    defaultValues: {
      id: item?.id,
      name: item?.name,
      title: item?.title,
      ispublic: item?.ispublic,
      tags: item?.tags,
      masks: item?.masks,
      material: item?.material,
      thumbnailurl: item?.thumbnailurl
    }
  });

  const mutate = useMutation<Types.IForm.Update, Types.IUpdateApi.Update.Error, Types.IUpdateApi.Update.Request, any>(
    async (values: any) => {
      await Api.UpdatePattern(values);

      return values;
    },
    {
      onError: (err: any) => {
        message.error(err.data.error);
        onError?.(err);
      },
      onSuccess: values => {
        onSuccess?.(values);

        queryClient.invalidateQueries([Constants.PATTERNS, 'list']);
        message.success('Pattern was successfully updated');
        navigate('/patterns');
      }
    }
  );

  const onSubmit = async (data: Types.IForm.Update) => {
    const storagePathId = data?.id ? data?.id : uuidv4();

    /* @ts-ignore */
    if (data.thumbnailurl.type) {
      /* @ts-ignore */
      await uploadFile(`patterns/${storagePathId}/`, data.thumbnailurl, 0.5, 512).then(async (e: any) => {
        data.thumbnailurl = e;
      });
      /* @ts-ignore */
    } else if (data?.material?.albedomap?.type) {
      /* @ts-ignore */
      await uploadFile(`patterns/${storagePathId}/`, data.material.albedomap, 0.5, 512).then(async (e: any) => {
        data.thumbnailurl = e;
      });
    }

    /* @ts-ignore */
    if (data.material.albedomap.type) {
      /* @ts-ignore */
      await uploadFile(`patterns/${storagePathId}/`, data.material.albedomap).then(async (e: any) => {
        data.material.albedomap = e;
      });
    }

    await mutate.mutateAsync({
      ...(data.id && { id: data.id }),
      title: data.title,
      name: data.name,
      ispublic: data.ispublic,
      thumbnailurl: data.thumbnailurl,
      tags: data.tags,
      masks: data.masks,
      scale: data.material.scale,
      off_set: data.material.off_set,
      rotation: data.material.rotation,
      albedomap: data.material.albedomap,
      type: data.material.type
    });
  };

  return (
    <form onSubmit={data.handleSubmit(onSubmit)}>
      <Component {...data} />
    </form>
  );
};

export default Update;
