import React from 'react';
import { Input } from 'antd';
import { InputProps as BaseInputProps } from 'antd/lib/input';
import cx from 'classnames';
import { Icon } from 'comfi-ui-kit/lib/components';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';

import cls from './small-input.module.scss';

export interface InputProps extends Pick<BaseInputProps, 'type' | 'disabled' | 'placeholder' | 'maxLength' | 'min' | 'pattern' | 'step'> {
  className?: string;
  errorMsg?: string;
  control: Control<any>;
  name: string;
  render?: (field: ControllerRenderProps) => React.ReactElement;
  type?: string;
}

const SmallInput: React.FC<InputProps> = ({
  placeholder,
  errorMsg,
  className,
  control,
  name,
  render,
  disabled,
  maxLength,
  type,
  ...inputArgs
}) => {
  console.log('placeholder', control);
  return (
    <div className={cls.wrapper}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          console.log('field SmallInput', field);
          return (
            <>
              <Input
                className={cx(cls.input, className)}
                placeholder={placeholder}
                disabled={disabled}
                maxLength={maxLength}
                allowClear
                id={name}
                {...inputArgs}
                {...field}
                type={type}
              />
              {render && render(field)}
            </>
          );
        }}
      />
      {errorMsg && (
        <div className={cls.message}>
          <Icon name="exclamation" /> <span>{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

export default SmallInput;
