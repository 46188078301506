import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Containers } from 'modules/auth';
import { useAuth } from 'modules/auth/hooks';
import { New } from 'pages/auth/components';
import Routes from 'routes';

import { PageLoader } from 'components';

import 'antd/dist/antd.css';

const App: FC = () => {
  const [params] = useSearchParams();
  const { isFetched, user, isVerified } = useAuth();
  const oobCode = params.get('oobCode') || '';

  const getPage = () => {
    if (oobCode) return <New oobCode={oobCode} />;

    if (!isFetched || (isVerified && !user)) return <PageLoader />;

    return <Routes />;
  };

  return <Containers.Auth>{getPage()}</Containers.Auth>;
};

export default App;
