import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const List = (): AxiosPromise<Types.IApi.List.Response> => http.request.post(`/simulations`, { kind: 'dynamic' });

export const Single = (id_template: string): AxiosPromise<Types.IApi.List.Response> =>
  http.request.post(`/simulations`, { id_template, kind: 'dynamic' });
// @ts-ignore
export const DeleteSimulations = (templateIDs: Types.IApi.DeleteList.Request): AxiosPromise<Types.IApi.DeleteList.Response> =>
  http.request.delete(`/simulations`, { data: templateIDs });

export const UpdateSimulation = (data: Types.IUpdateApi.Update.Request): Promise<Types.IUpdateApi.Update.Response> =>
  http.request.put(`/simulation`, data);
