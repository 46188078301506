import get from 'lodash/get';
import moment from 'moment';

import { SimulationType } from './constants';
import * as Types from './types';
import { Constants } from '.';

export const Simulation = async (item: any): Promise<Types.IEntity.Simulation> => ({
  id: get(item, 'id'),
  createdat: {
    value: get(item, 'createdat'),
    label: moment(get(item, 'createdat')).format('MMMM DD, YYYY')
  },
  updatedat: {
    value: get(item, 'updatedat'),
    label: moment(get(item, 'updatedat')).format('MMMM DD, YYYY')
  },
  kind: get(item, 'kind') || SimulationType.static,
  url: get(item, 'url') || '',
  id_template: get(item, 'id_template') || '',
  alias: get(item, 'alias') || '',
  template_alias: get(item, 'template_alias') || '',
  style: get(item, 'style') || Constants.StyleType.flat
});
