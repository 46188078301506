import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { Button, TopBar, Typography } from 'comfi-ui-kit/lib/components';
import { queryClient } from 'index';
import { Home } from 'layouts';
import { Api, Constants, Types } from 'modules/simulations';
import useSimulationList from 'modules/simulations/hooks/use-list';
// import { Api as Update, Types as UpdateTypes } from 'modules/template-editor';
import { useMutation } from 'react-query';

// import { blockingParser } from 'utils/PC2Parser';
import { Profile } from 'components';

import List from './list/list';

// import anim from './garment_fixed.pc2';
import cls from './simulations.module.scss';

const Simulations: FC = () => {
  const navigate = useNavigate();
  const { isLoading, items, refetch } = useSimulationList();
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [animationData, setAnimationData] = useState<any>(null);

  // const handleFile = (file: File) => {
  //   const reader = new FileReader();

  //   reader.onload = event => {
  //     const data = event.target?.result as ArrayBuffer;
  //     const frames = blockingParser(data);

  //     setAnimationData(frames);
  //     console.log('frames', frames);
  //   };
  //   reader.readAsArrayBuffer(file);
  // };

  // useEffect(() => {
  //   fetch(anim) // Fetch the .pc2 file from the public folder
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       return response.blob(); // Here we get the file as a Blob
  //     })
  //     // @ts-ignore
  //     .then(handleFile)
  //     .catch(error => {
  //       console.error('There has been a problem with your fetch operation:', error);
  //     });
  // }, []);

  const deleteMutate = useMutation<Types.IQuery.DeleteList, Types.IApi.DeleteList.Error, Types.IApi.DeleteList.Request, any>(
    async (selectedRowKeys: Types.IApi.DeleteList.Request) => {
      setTableLoading(true);
      await Api.DeleteSimulations(selectedRowKeys).then(e => console.log('response', e));

      return selectedRowKeys;
    },
    {
      onError: (err: any) => {
        message.error('Error');
        setTableLoading(false);
      },
      onSuccess: async values => {
        await refetch();
        setSelectedRowKeys([]);
        queryClient.invalidateQueries([Constants.SIMULATIONS, 'list']);
        message.success('Simulation(s) successfully deleted');
        setTableLoading(false);
      }
    }
  );

  const handleDeleteRows = async (selectedRowKeys: any) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete 1 item?')) {
      await deleteMutate.mutateAsync(selectedRowKeys);
      refetch();
    }
  };

  const handleRowChange = (e: string[]) => {
    setSelectedRowKeys(e);
  };

  return (
    <Home>
      <div className={cls.wrapper}>
        <TopBar
          className={cls.wrap}
          childrenRight={
            <div className={cls['right-header']}>
              {items.length! > 0 && (
                <Button className={cls.button} onClick={() => navigate('/simulations/editor')}>
                  Add new simulation
                </Button>
              )}
              <Profile />
            </div>
          }
        />
        <Typography size={24} weight={650} className={cls.title} lineHeight="28">
          All Simulations
        </Typography>
        <List
          items={items}
          loading={isLoading}
          tableLoading={tableLoading}
          setTableLoading={setTableLoading}
          onDelete={handleDeleteRows}
          selectedRowKeys={selectedRowKeys}
          handleRowChange={handleRowChange}
        />
      </div>
    </Home>
  );
};

export default Simulations;
