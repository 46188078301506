import React from 'react';

const LightClock: React.FC = () => (
  <svg width="7" height="8" viewBox="0 0 7 8" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.26958 0.77239C1.97462 0.874842 0.897228 1.6991 0.476363 2.90939C0.0851432 4.0344 0.363173 5.29347 1.19177 6.14902C1.70374 6.67767 2.3283 6.99509 3.05627 7.09662C3.3028 7.13101 3.78628 7.12233 4.0223 7.0793C4.48941 6.99412 4.93454 6.80597 5.33393 6.52487C5.52227 6.39233 5.91492 6.00223 6.04525 5.81821C6.6499 4.96444 6.81059 3.91669 6.48639 2.94194C6.11062 1.81215 5.11705 0.97456 3.95125 0.804764C3.77256 0.778733 3.40811 0.761428 3.26958 0.77239ZM3.9644 1.52414C4.461 1.62873 4.86051 1.84648 5.22222 2.20972C5.51327 2.50204 5.69056 2.78695 5.8154 3.16304C5.91618 3.46667 5.93307 3.57962 5.93307 3.9496C5.93307 4.23584 5.92786 4.30411 5.89463 4.45268C5.84238 4.6862 5.7734 4.8773 5.66793 5.08062C5.54389 5.31973 5.41506 5.49431 5.21343 5.69656C4.46458 6.44772 3.32759 6.63202 2.37641 6.15646C1.69449 5.81552 1.22815 5.21281 1.05811 4.45268C1.02488 4.30411 1.01967 4.23584 1.01967 3.9496C1.01967 3.69119 1.0263 3.58757 1.04987 3.47806C1.16111 2.96127 1.37103 2.57031 1.73052 2.2103C2.13761 1.80263 2.60478 1.56949 3.17032 1.49178C3.35335 1.46663 3.77237 1.4837 3.9644 1.52414ZM3.31094 2.22331C3.26999 2.2452 3.21416 2.29485 3.18686 2.33363L3.13723 2.40414L3.13218 3.20171C3.12863 3.76539 3.1327 4.0187 3.1461 4.06551C3.16151 4.11939 3.23963 4.20691 3.56473 4.53456C3.78454 4.75612 3.99203 4.95172 4.02578 4.96924C4.09227 5.00375 4.23899 5.01226 4.3089 4.98565C4.37498 4.9605 4.47195 4.86551 4.5043 4.79426C4.54132 4.71267 4.54347 4.6062 4.50972 4.5245C4.49348 4.48515 4.36522 4.34336 4.15444 4.13175L3.82459 3.80057L3.82004 3.10364L3.81551 2.40672L3.77028 2.34193C3.69792 2.23825 3.6218 2.19611 3.49472 2.18933C3.40386 2.1845 3.37281 2.19023 3.31094 2.22331Z"
      fill="currentColor"
    />
  </svg>
);

export default LightClock;
