import React from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Badge, Button, Card } from 'antd';
import { InputProps as BaseInputProps } from 'antd/lib/input';
import { Types } from 'modules/templates-main';
import { MaterialType } from 'modules/templates-main/constants';
import { Control, Controller, useFieldArray, UseFormRegister, UseFormResetField } from 'react-hook-form';
import { generateUUID } from 'three/src/math/MathUtils';

import { TextureUpload } from 'components';
import { InputHex, InputSlider, SmallInput } from 'components/fields';

import cls from './multi-material-editor.module.scss';

export interface UploadProps extends Pick<BaseInputProps, 'type' | 'disabled' | 'placeholder'> {
  className?: string;
  errorMsg?: string;
  control: Control<any>;
  name: string;
  resetField: UseFormResetField<any>;
  register: UseFormRegister<any>;
}

const sampleMaterial: Types.IEntity.Material = {
  id: generateUUID(),
  name: 'New material',
  color: '#FFFFFF',
  type: MaterialType.main,
  albedoMap: '',
  normalMap: '',
  roughnessMap: '',
  metallicMap: '',
  offset: 1,
  rotation: 0,
  scale: 1
};

const MultiMaterialEditor: React.FC<UploadProps> = ({ control, name, resetField, errorMsg, register }) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'materials'
  });

  const getAddButton = () => (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: 16 }}>
      <Button onClick={() => append(sampleMaterial)} type="primary" size="large">
        <PlusOutlined />
        Add material
      </Button>
    </div>
  );

  const getMaterialView = (material: Types.IEntity.Material, materialIndex: number) => (
    <Badge.Ribbon text={material.type}>
      <Card
        extra={<SmallInput placeholder="Material name" control={control} maxLength={50} name={`materials.${materialIndex}.name`} />}
        size="small"
        actions={[<DeleteOutlined key="delete" onClick={() => remove(materialIndex)} />]}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
          <span>Color:</span>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'flex-end' }}>
            <div style={{ width: 20, height: 20, borderRadius: 20, backgroundColor: material.color, cursor: 'pointer' }} />
            <InputHex control={control} name={`materials.${materialIndex}.color`} />
          </div>
        </div>
        <span>Textures:</span>
        <div className={cls.upload}>
          {Object.keys(material)
            .filter(x => x.includes('Map'))
            .map((textureName: string) => (
              <TextureUpload
                key={textureName}
                resetField={resetField}
                control={control}
                name={`materials.${materialIndex}.${textureName}`}
                errorMsg={errorMsg}
                textureName={textureName}
              />
            ))}
        </div>
        <InputSlider control={control} name={`materials.${materialIndex}.scale`} errorMsg={errorMsg} placeholder="Scale" min={1} max={20} />
        <InputSlider
          control={control}
          name={`materials.${materialIndex}.offset`}
          errorMsg={errorMsg}
          placeholder="Offset"
          min={1}
          max={20}
        />
        <InputSlider
          control={control}
          name={`materials.${materialIndex}.rotation`}
          errorMsg={errorMsg}
          placeholder="Rotation"
          min={0}
          max={360}
        />
      </Card>
    </Badge.Ribbon>
  );

  return (
    <div className={cls.wrapper}>
      {fields.map((material: any, index: number) => (
        <Controller
          key={material.id}
          name={`materials.${index}`}
          control={control}
          render={({ field }) => {
            console.log('material-editor field.value = ', field.value);
            console.log('material fields = ', fields);
            return <div key={field.value.id}>{getMaterialView(field.value, index)}</div>;
          }}
        />
      ))}
      {getAddButton()}
      {errorMsg && (
        <div className={cls.message}>
          <span>{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

export default MultiMaterialEditor;
