import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { Button, TopBar, Typography } from 'comfi-ui-kit/lib/components';
import { queryClient } from 'index';
import { Home } from 'layouts';
import { Api as Update, Types as UpdateTypes } from 'modules/template-editor';
import { Api, Constants, Types } from 'modules/templates-main';
import useTemplateList from 'modules/templates-main/hooks/use-list';
import { useMutation } from 'react-query';

// import { blockingParser } from 'utils/PC2Parser';
import { Profile } from 'components';

import List from './list/list';

// import anim from './garment_fixed.pc2';
import cls from './templates.module.scss';

const Templates: FC = () => {
  const navigate = useNavigate();
  const { isLoading, items, refetch } = useTemplateList();
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [animationData, setAnimationData] = useState<any>(null);

  // const handleFile = (file: File) => {
  //   const reader = new FileReader();

  //   reader.onload = event => {
  //     const data = event.target?.result as ArrayBuffer;
  //     const frames = blockingParser(data);

  //     setAnimationData(frames);
  //     console.log('frames', frames);
  //   };
  //   reader.readAsArrayBuffer(file);
  // };

  // useEffect(() => {
  //   fetch(anim) // Fetch the .pc2 file from the public folder
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       return response.blob(); // Here we get the file as a Blob
  //     })
  //     // @ts-ignore
  //     .then(handleFile)
  //     .catch(error => {
  //       console.error('There has been a problem with your fetch operation:', error);
  //     });
  // }, []);

  const deleteMutate = useMutation<Types.IQuery.DeleteList, Types.IApi.DeleteList.Error, Types.IApi.DeleteList.Request, any>(
    async (selectedRowKeys: Types.IApi.DeleteList.Request) => {
      setTableLoading(true);
      await Api.DeleteTemplates(selectedRowKeys).then(e => console.log('response', e));

      return selectedRowKeys;
    },
    {
      onError: (err: any) => {
        message.error('Error');
        setTableLoading(false);
      },
      onSuccess: async values => {
        await refetch();
        setSelectedRowKeys([]);
        queryClient.invalidateQueries([Constants.TEMPLATES, 'list']);
        message.success('Template successfully deleted');
        setTableLoading(false);
      }
    }
  );

  const updateVisibility = useMutation<UpdateTypes.IForm.Update, UpdateTypes.IApi.Update.Error, UpdateTypes.IApi.Update.Request, any>(
    async (updatedItem: Types.IEntity.Template) => {
      setTableLoading(true);
      await Update.Template(updatedItem);

      return updatedItem;
    },
    {
      onError: (err: any) => {
        message.error('Error');
        setTableLoading(false);
      },
      onSuccess: async values => {
        await refetch();
        queryClient.invalidateQueries([Constants.TEMPLATES, 'list']);
        message.success('Template successfully updated');
        setTableLoading(false);
      }
    }
  );

  const handleDeleteRows = async (selectedRowKeys: any) => {
    await deleteMutate.mutateAsync(selectedRowKeys);
    refetch();
  };

  const handleRowChange = (e: string[]) => {
    setSelectedRowKeys(e);
  };

  const handleVisibilityChange = async (e: boolean, item: Types.IEntity.Template) => {
    if (item.ispublic !== e) {
      const updatedItem: Types.IEntity.Template = {
        id: item.id,
        variants: item.variants,
        materials: item.materials,
        images: item.images,
        options: item.options,
        fabrics: item.fabrics,
        title: item.title,
        title_ru: item.title_ru,
        name: item.name,
        alias: item.alias,
        category: item.category,
        subcategory: item.subcategory,
        ispublic: e,
        silhouette: item.silhouette,
        gender: item.gender,
        requires_styled_simulation: item.requires_styled_simulation
      };

      await updateVisibility.mutateAsync(updatedItem);
    }
  };

  return (
    <Home>
      <div className={cls.wrapper}>
        <TopBar
          className={cls.wrap}
          childrenRight={
            <div className={cls['right-header']}>
              {items.length! > 0 && (
                <Button className={cls.button} onClick={() => navigate('/templates/editor')}>
                  Create a new template
                </Button>
              )}
              <Profile />
            </div>
          }
        />
        <Typography size={24} weight={650} className={cls.title} lineHeight="28">
          All Templates
        </Typography>
        <List
          items={items}
          loading={isLoading}
          tableLoading={tableLoading}
          setTableLoading={setTableLoading}
          onDelete={handleDeleteRows}
          selectedRowKeys={selectedRowKeys}
          handleRowChange={handleRowChange}
          handleVisibilityChange={handleVisibilityChange}
        />
      </div>
    </Home>
  );
};

export default Templates;
