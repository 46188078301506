import { FC } from 'react';
import { Form } from 'modules/firebase';

import { Input, Password } from 'containers/fields';

import { Button } from 'components';

import cls from './register.module.scss';

const Register: FC = () => (
  <Form.Register>
    {({ control, formState: { errors, isSubmitting } }) => (
      <div className={cls.wrapper}>
        <h1 className={cls.title}>Let’s get you started</h1>
        <Input errorMsg={errors.email?.message} placeholder="Email" control={control} className={cls.input} name="email" disabled />
        <Password errorMsg={errors.password?.message} placeholder="Password" control={control} className={cls.input} name="password" />
        <Password
          errorMsg={errors.confirm?.message}
          placeholder="Confirm Password"
          control={control}
          className={cls.input}
          name="confirm"
        />
        <Button className={cls.btn} htmlType="submit" loading={isSubmitting}>
          Continue
        </Button>
      </div>
    )}
  </Form.Register>
);

export default Register;
