import React from 'react';

const Plus: React.FC = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.0998 5.09988C0.601699 5.09988 0.197912 5.50367 0.197913 6.00177C0.197913 6.49986 0.601699 6.90365 1.09979 6.90365L1.0998 5.09988ZM10.9002 6.90365C11.3983 6.90365 11.8021 6.49986 11.8021 6.00177C11.8021 5.50367 11.3983 5.09989 10.9002 5.09989L10.9002 6.90365ZM5.09812 10.902C5.09812 11.4001 5.50191 11.8039 6 11.8039C6.4981 11.8039 6.90188 11.4001 6.90188 10.902L5.09812 10.902ZM6.90188 1.10156C6.90188 0.603466 6.4981 0.19968 6 0.19968C5.5019 0.19968 5.09812 0.603467 5.09812 1.10156L6.90188 1.10156ZM1.09979 6.90365L10.9002 6.90365L10.9002 5.09989L1.0998 5.09988L1.09979 6.90365ZM6.90188 10.902L6.90188 1.10156L5.09812 1.10156L5.09812 10.902L6.90188 10.902Z"
      fill="black"
    />
  </svg>
);

export default Plus;
