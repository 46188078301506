import get from 'lodash/get';

export const pluralize = (count: number, noun: string, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;

export function getFileName(filePath: string) {
  // Split the URL by '/'
  const parts = filePath.split('/');
  // Get the last part of the URL (the file name with extension)
  const fileNameWithExtension = parts.pop();
  // Split the file name by '.' and remove the last part (the extension)
  const fileNameParts = fileNameWithExtension!.split('.');

  fileNameParts.pop();
  // Rejoin the remaining parts (in case the file name itself contains dots)
  return fileNameParts.join('.');
}

export function getFileNameWithExtension(filePath: string) {
  // Split the URL by '/'
  const parts = filePath.split('/');
  // Get the last part of the URL (the file name with extension)
  const fileNameWithExtension = parts.pop() || '';

  return fileNameWithExtension;
}

export function getExtension(filePath: string): string {
  // Split the URL by '/'
  const parts = filePath.split('/');
  // Get the last part of the URL (the file name with extension)
  const fileNameWithExtension = parts.pop() || '';
  // Split the file name by '.' to get the extension
  const fileNameParts = fileNameWithExtension.split('.');
  // Return the last part, which is the extension, or an empty string if no extension is found

  return fileNameParts.length > 1 ? fileNameParts.pop() || '' : '';
}

// Function to group items by a specific key
export const groupBy = (items: any[], key: string) =>
  items.reduce((result, item) => {
    const groupKey = get(item, key);

    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(item);
    return result;
  }, {} as { [key: string]: any[] });

export async function convertUrlToUploadFileFormat(url: string) {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.statusText}`);
    }
    const blob = await response.blob();
    const fileName = url.split('/').pop() || 'download';

    // Create a File object directly from the blob
    const file = new File([blob], `${getFileNameWithExtension(url)}`, { type: blob.type });

    return file;
  } catch (error) {
    console.error('Error converting URL to UploadFile format:', error);
    return null;
  }
}
