import React from 'react';

const List: React.FC = () => (
  <svg width="70" height="89" viewBox="0 0 70 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3067_25077)">
      <path
        d="M13.2982 88.0321C6.21053 88.0321 0.464844 82.2536 0.464844 75.1255V13.8832C0.464844 6.75508 6.21053 0.976562 13.2982 0.976562H56.7024C63.79 0.976562 69.5357 6.75508 69.5357 13.8832V75.1255C69.5357 82.2536 63.79 88.0321 56.7024 88.0321H13.2982Z"
        fill="black"
        fillOpacity="0.03"
      />
      <g opacity="0.980685">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5666 63.5996C22.6106 63.5771 22.691 63.5464 22.7453 63.5313C23.296 63.3787 23.8451 63.8987 23.7412 64.4742C23.7352 64.5068 23.7322 64.5332 23.7268 64.5591C23.6864 64.756 23.5125 64.9251 20.9309 67.5795L18.2544 70.3314L18.0945 70.4095C17.7261 70.5894 17.3712 70.5893 17.0036 70.4093L16.8437 70.331L15.559 69.0119C14.4367 67.8594 14.2672 67.6778 14.2176 67.5746C14.1402 67.4134 14.112 67.235 14.1388 67.0743C14.2335 66.505 14.8094 66.2043 15.3107 66.4623C15.3702 66.493 15.7145 66.8338 16.4722 67.6122L17.5492 68.7184L20.0179 66.1795C21.7293 64.4195 22.5112 63.628 22.5666 63.5996ZM18.5039 74.0644C22.3699 74.0644 25.5039 70.9125 25.5039 67.0244C25.5039 63.1363 22.3699 59.9844 18.5039 59.9844C14.6379 59.9844 11.5039 63.1363 11.5039 67.0244C11.5039 70.9125 14.6379 74.0644 18.5039 74.0644Z"
          fill="black"
        />
      </g>
      <g opacity="0.980685">
        <path
          d="M32.5033 64.6777C31.2146 64.6777 30.1699 63.6271 30.1699 62.331C30.1699 61.035 31.2146 59.9844 32.5033 59.9844H51.1699C52.4586 59.9844 53.5033 61.035 53.5033 62.331C53.5033 63.6271 52.4586 64.6777 51.1699 64.6777H32.5033Z"
          fill="black"
          fillOpacity="0.6"
        />
      </g>
      <g opacity="0.980685">
        <path
          d="M32.5033 74.0703C31.2146 74.0703 30.1699 73.0196 30.1699 71.7236C30.1699 70.4276 31.2146 69.377 32.5033 69.377H57.0033C58.2919 69.377 59.3366 70.4276 59.3366 71.7236C59.3366 73.0196 58.2919 74.0703 57.0033 74.0703H32.5033Z"
          fill="black"
          fillOpacity="0.2"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5666 41.5137C22.6106 41.4912 22.691 41.4605 22.7453 41.4454C23.296 41.2928 23.8451 41.8127 23.7412 42.3882C23.7352 42.4208 23.7322 42.4472 23.7268 42.4732C23.6864 42.6701 23.5125 42.8392 20.9309 45.4936L18.2544 48.2455L18.0945 48.3236C17.7261 48.5035 17.3712 48.5034 17.0036 48.3234L16.8437 48.2451L15.559 46.9259C14.4367 45.7735 14.2672 45.5919 14.2176 45.4886C14.1402 45.3274 14.112 45.1491 14.1388 44.9884C14.2335 44.419 14.8094 44.1183 15.3107 44.3764C15.3702 44.4071 15.7145 44.7478 16.4722 45.5262L17.5492 46.6325L20.0179 44.0936C21.7293 42.3336 22.5112 41.5421 22.5666 41.5137ZM18.5039 51.9784C22.3699 51.9784 25.5039 48.8265 25.5039 44.9384C25.5039 41.0504 22.3699 37.8984 18.5039 37.8984C14.6379 37.8984 11.5039 41.0504 11.5039 44.9384C11.5039 48.8265 14.6379 51.9784 18.5039 51.9784Z"
        fill="black"
      />
      <path
        d="M32.5033 42.5918C31.2146 42.5918 30.1699 41.5411 30.1699 40.2451C30.1699 38.9491 31.2146 37.8984 32.5033 37.8984H51.1699C52.4586 37.8984 53.5033 38.9491 53.5033 40.2451C53.5033 41.5411 52.4586 42.5918 51.1699 42.5918H32.5033Z"
        fill="black"
        fillOpacity="0.6"
      />
      <path
        d="M32.5033 51.9765C31.2146 51.9765 30.1699 50.9259 30.1699 49.6299C30.1699 48.3338 31.2146 47.2832 32.5033 47.2832H57.0033C58.2919 47.2832 59.3366 48.3338 59.3366 49.6299C59.3366 50.9259 58.2919 51.9765 57.0033 51.9765H32.5033Z"
        fill="black"
        fillOpacity="0.2"
      />
      <g opacity="0.951034">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.369 20.6563C16.105 20.6954 15.7384 20.8124 15.4566 20.9477C14.4328 21.4389 13.7685 22.387 13.6473 23.5297C13.6011 23.9651 13.6076 24.6986 13.6588 24.8202C13.7256 24.979 13.8231 25.0486 13.9785 25.0486C14.1421 25.0486 14.2609 24.9524 14.308 24.782C14.3252 24.7198 14.3393 24.4042 14.3394 24.0806C14.3397 23.3824 14.3837 23.1575 14.6114 22.6924C14.8696 22.165 15.4324 21.6697 15.9983 21.4717C16.3886 21.3352 16.5516 21.3265 18.4801 21.3387C20.4762 21.3514 20.4231 21.3467 20.8715 21.5511C21.3675 21.7773 21.8179 22.2115 22.0595 22.6963C22.2865 23.1519 22.3314 23.3801 22.3316 24.0806C22.3318 24.7236 22.3532 24.8563 22.4755 24.9718C22.5882 25.0783 22.7978 25.0788 22.91 24.9727C23.049 24.8414 23.0656 24.7286 23.0448 24.0538C23.0235 23.3642 22.9842 23.1199 22.827 22.6989C22.6154 22.1325 22.1491 21.5283 21.6745 21.2058C21.407 21.0241 20.9577 20.8134 20.6481 20.7246C20.3991 20.6533 20.3751 20.6524 18.4299 20.6474C17.3483 20.6446 16.4209 20.6486 16.369 20.6563L16.369 20.6563ZM18.776 15.6403C19.3973 15.7863 19.9483 16.35 20.1006 16.9953C20.1522 17.2143 20.1518 17.5912 20.0995 17.8181C19.9488 18.4733 19.3964 19.0289 18.7445 19.1809C18.1448 19.3208 17.502 19.133 17.0605 18.689C16.7092 18.3357 16.5283 17.898 16.5304 17.4067C16.5355 16.218 17.6163 15.3678 18.776 15.6403V15.6403ZM17.9749 14.9083C17.6829 14.9544 17.4857 15.021 17.1694 15.1802C16.9266 15.3025 16.826 15.3785 16.5725 15.6313C16.3006 15.9023 16.2501 15.9713 16.1068 16.2675C15.8986 16.6979 15.8402 16.9466 15.8391 17.4067C15.838 17.8657 15.896 18.1129 16.1046 18.5373C16.4197 19.1785 17.0057 19.6601 17.7017 19.8498C18.0146 19.9351 18.5967 19.9416 18.9176 19.8634C19.8079 19.6465 20.5597 18.8885 20.7792 17.9864C20.8561 17.6702 20.8491 17.0823 20.7648 16.7693C20.5316 15.9042 19.8186 15.1865 18.9758 14.9686C18.6852 14.8935 18.239 14.8666 17.9749 14.9083Z"
          fill="black"
        />
      </g>
      <g opacity="0.951034">
        <path
          d="M16.369 20.6563C16.105 20.6954 15.7384 20.8124 15.4566 20.9477C14.4328 21.4389 13.7684 22.387 13.6473 23.5297C13.6011 23.9651 13.6076 24.6986 13.6588 24.8202C13.7256 24.979 13.8231 25.0486 13.9785 25.0486C14.1421 25.0486 14.2609 24.9524 14.308 24.782C14.3252 24.7198 14.3393 24.4042 14.3394 24.0806C14.3397 23.3824 14.3837 23.1575 14.6114 22.6924C14.8696 22.165 15.4324 21.6697 15.9983 21.4717C16.3886 21.3352 16.5516 21.3265 18.4801 21.3387C20.4762 21.3514 20.4231 21.3467 20.8715 21.5511C21.3675 21.7773 21.8179 22.2115 22.0595 22.6963C22.2865 23.1519 22.3314 23.3801 22.3316 24.0806C22.3318 24.7236 22.3532 24.8563 22.4755 24.9718C22.5882 25.0783 22.7978 25.0788 22.91 24.9727C23.049 24.8414 23.0656 24.7286 23.0448 24.0538C23.0235 23.3642 22.9842 23.1199 22.827 22.6989C22.6154 22.1325 22.1491 21.5283 21.6745 21.2058C21.407 21.0241 20.9577 20.8134 20.6481 20.7246C20.3991 20.6533 20.3751 20.6524 18.4299 20.6474C17.3483 20.6446 16.4209 20.6486 16.369 20.6563L16.369 20.6563ZM18.776 15.6403C19.3973 15.7863 19.9483 16.35 20.1006 16.9953C20.1522 17.2143 20.1518 17.5912 20.0995 17.8181C19.9488 18.4733 19.3964 19.0289 18.7445 19.1809C18.1448 19.3208 17.502 19.133 17.0605 18.689C16.7092 18.3357 16.5283 17.898 16.5304 17.4067C16.5355 16.218 17.6163 15.3678 18.776 15.6403V15.6403ZM17.9749 14.9083C17.6829 14.9544 17.4857 15.021 17.1694 15.1802C16.9266 15.3025 16.826 15.3785 16.5725 15.6313C16.3006 15.9023 16.2501 15.9713 16.1068 16.2675C15.8986 16.6979 15.8402 16.9466 15.8391 17.4067C15.838 17.8657 15.896 18.1129 16.1046 18.5373C16.4197 19.1785 17.0057 19.6601 17.7017 19.8498C18.0146 19.9351 18.5967 19.9416 18.9176 19.8634C19.8079 19.6465 20.5597 18.8885 20.7792 17.9864C20.8561 17.6702 20.8491 17.0823 20.7648 16.7693C20.5316 15.9042 19.8186 15.1865 18.9758 14.9686C18.6852 14.8935 18.239 14.8666 17.9749 14.9083Z"
          stroke="black"
          strokeWidth="0.696"
          strokeMiterlimit="0"
        />
      </g>
      <g opacity="0.951034">
        <path
          d="M14.5596 33.4657C10.6936 33.4657 7.55957 30.3138 7.55957 26.4257V14.6923C7.55957 10.8043 10.6936 7.65234 14.5596 7.65234H55.3929C59.2589 7.65234 62.3929 10.8043 62.3929 14.6923V26.4257C62.3929 30.3138 59.2589 33.4657 55.3929 33.4657H14.5596Z"
          stroke="black"
          strokeWidth="2.32"
          strokeMiterlimit="0"
        />
      </g>
      <g opacity="0.951034">
        <path
          d="M32.5072 22.5351C31.2185 22.5351 30.1738 21.4845 30.1738 20.1885C30.1738 18.8924 31.2185 17.8418 32.5072 17.8418H44.1738C45.4625 17.8418 46.5072 18.8924 46.5072 20.1885C46.5072 21.4845 45.4625 22.5351 44.1738 22.5351H32.5072Z"
          fill="black"
          fillOpacity="0.6"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3067_25077">
        <rect width="70" height="88" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default List;
