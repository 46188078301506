import React, { ReactNode } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { message } from 'antd';
import { config } from 'config';
import { useForm, UseFormReturn } from 'react-hook-form';
import { searchParams } from 'utils';

import { passwordReset } from '../api';
import { IForm } from '../types';

import { newSchema } from './schema';

interface NewProps {
  onSuccess?: (value: IForm.New) => void;
  children: (data: UseFormReturn<IForm.New>) => ReactNode;
  defaultValues?: IForm.New;
  oobCode: string;
}

const New: React.FC<NewProps> = ({ children, defaultValues = {}, oobCode }) => {
  const data = useForm<IForm.New>({ resolver: yupResolver(newSchema), defaultValues: { password: '', confirm: '', ...defaultValues } });
  const requestID = searchParams.get('id');

  const onSubmit = async ({ password }: IForm.New) => {
    try {
      await passwordReset(oobCode, password);

      message.success('Success change your password');
      window.location.href = `${config.api.redirectURL}?id=${requestID}`;
    } catch (err: any) {
      message.error(err.message);
    }
  };

  return <form onSubmit={data.handleSubmit(onSubmit)}>{children(data)}</form>;
};

export default New;
