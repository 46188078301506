import { Dispatch, SetStateAction } from 'react';

export namespace IContext {
  export type Step = 'email' | 'login' | 'register' | 'reset' | 'verification-message' | 'reset-message' | 'new';
  export interface State {
    step: Step;
    email?: string;
  }
  export interface Value {
    state: State;
    setState: Dispatch<SetStateAction<State>>;
  }
}

export namespace IForm {
  export interface Email {
    email: string;
  }

  export interface Login {
    email: string;
    password: string;
  }

  export interface Register {
    email: string;
    password: string;
    confirm: string;
  }

  export interface New {
    password: string;
    confirm: string;
  }
}
