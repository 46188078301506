import React, { ComponentType } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { message } from 'antd';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { getExtension } from 'services/functions/helpers';
import uploadObject from 'services/functions/upload-object';

import * as Constants from '../constants';
import * as Types from '../types';
import { Api } from '..';

import { updateSchema } from './schema';

interface UpdateProps {
  defaultValues?: Partial<Types.IForm.Update>;
  onSuccess?: (values: Types.IForm.Update) => void;
  onError?: (values: Types.IUpdateApi.Update.Error) => void;
  component: ComponentType<UseFormReturn<Types.IForm.Update>>;
  item: Types.IForm.Update;
}

const Update: React.FC<UpdateProps> = ({ onSuccess, onError, defaultValues = {}, component: Component, item }) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const data = useForm<Types.IForm.Update>({
    resolver: yupResolver(updateSchema),
    defaultValues: {
      id_template: item?.id_template,
      template_alias: item?.template_alias,
      animations: item.animations
    }
  });

  const mutate = useMutation<Types.IForm.Update, Types.IUpdateApi.Update.Error, Types.IUpdateApi.Update.Request, any>(
    async (values: any) => {
      // await Api.UpdateSimulation(values);
      console.log('onSubmit', values);

      return values;
    },
    {
      onError: (err: any) => {
        message.error(err.data.error);
        onError?.(err);
      },
      onSuccess: values => {
        onSuccess?.(values);

        queryClient.invalidateQueries([Constants.ANIMATIONS, 'list']);
        message.success('Animation was successfully updated');
        navigate('/animations');
      }
    }
  );

  const onSubmit = async (data: Types.IForm.Update) => {
    console.log('animation onSubmit', data);

    try {
      /* @ts-ignore */
      // eslint-disable-next-line max-len
      const payloadPromises = data.animations
        .filter(animation => animation.url.length) // Ignore empty animations
        .map(async (animation: Types.IEntity.SimulationEntity, index: number): Promise<Types.IEntity.UpdateSimulation> => {
          let newUrl: string = '';

          const { id, url, kind, alias, style } = animation;
          let recordId: string = id!;

          // Check if it's a new upload
          // @ts-ignore
          if (url[0].uid.includes('rc-upload')) {
            console.log('urlAnimation', animation);

            // First, create an empty record with an empty `url` field
            const emptyRecord: Types.IEntity.UpdateSimulation = {
              ...(id && { id }),
              id_template: data.id_template,
              alias,
              kind,
              style
            };

            if (!id) {
              const res = await Api.UpdateSimulation(emptyRecord);

              // @ts-ignore
              recordId = res.data.id!;
            }

            // Upload the file to a path containing the obtained record ID
            // @ts-ignore
            newUrl = await uploadObject(`uploads/simulations/${recordId}`, `${alias}.${getExtension(url[0].name)}`, url[0]);

            // Now, update the record with the new URL
            const updatedRecord: Types.IEntity.UpdateSimulation = {
              ...emptyRecord,
              url: newUrl
            };

            console.log('updatedRecord', updatedRecord);

            await Api.UpdateSimulation(updatedRecord);

            return updatedRecord;
          }
          // If it's not a new upload, just return the existing URL
          return {
            ...(id && { id }),
            alias,
            kind,
            id_template: data.id_template,
            style
          };
        });

      const payload = await Promise.all(payloadPromises);

      // @ts-ignore
      await mutate.mutateAsync(payload);
    } catch (error) {
      console.error('Error in onSubmit:', error);
      message.error('Couldnt bundle request successfully');
    }
  };

  return (
    <form onSubmit={data.handleSubmit(onSubmit)}>
      <Component {...data} />
    </form>
  );
};

export default Update;
