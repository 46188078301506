import { FC } from 'react';
import { Form } from 'modules/firebase';
import { useContext } from 'modules/firebase/context';

import { Input, Password } from 'containers/fields';

import { Button } from 'components';

import cls from './login.module.scss';

const Login: FC = () => {
  const { setState } = useContext();

  return (
    <Form.Login>
      {({ control, formState: { errors, isSubmitting } }) => (
        <div className={cls.wrapper}>
          <h1 className={cls.title}>Welcome back</h1>
          <Input errorMsg={errors.email?.message} placeholder="Email" control={control} className={cls.input} name="email" disabled />
          <Password errorMsg={errors.password?.message} placeholder="Password" control={control} className={cls.input} name="password" />
          <Button onClick={() => setState({ email: '', step: 'reset' })} className={cls.outlines}>
            <span className={cls.password}>Forgot password</span>
          </Button>
          <Button className={cls.btn} htmlType="submit" loading={isSubmitting}>
            Continue
          </Button>
        </div>
      )}
    </Form.Login>
  );
};

export default Login;
