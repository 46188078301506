import * as TemplateEntity from 'modules/templates-main';

export namespace IForm {
  export interface Update extends TemplateEntity.Types.IEntity.Template {}
}

export namespace IEntity {
  export interface Template extends TemplateEntity.Types.IEntity.Template {}
}

export namespace IApi {
  export namespace Update {
    export interface Request extends TemplateEntity.Types.IEntity.Template {}
    export interface Response extends TemplateEntity.Types.IEntity.Template {}

    export interface Error {
      error: string;
    }
  }
}
