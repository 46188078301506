export namespace IEntity {
  export namespace User {
    export interface Main {
      id: string;
      uid: string;
      name: string;
      phone: string;
      email: string;
      isAdmin: boolean;
      avatarUrl: string;
      accounts: Account[];
    }

    export interface Account {
      id: string;
      name: string;
      logo_url: string;
      description: string;
      // team: Team[];
    }

    export interface Team {
      name: string;
      phone: string;
      email: string;
      status: string;
    }
  }
}

export namespace IApi {
  export namespace User {
    export interface Request extends IEntity.User.Main {}
    export interface Response extends IEntity.User.Main {}
  }
  export namespace CreateUser {
    export interface Request {
      email: string;
      phone: string;
      avatarUrl: string;
      name: string;
    }
    export interface Response extends IEntity.User.Main {}
  }
}

export namespace IQuery {
  export interface User {
    user: IEntity.User.Main;
  }
}
