import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Collapse, Icon, TopBar, Typography } from 'comfi-ui-kit/lib/components';
import { Col, Row } from 'comfi-ui-kit/lib/components/grid';
import { Form, Types } from 'modules/fabrics';
import { MaterialType } from 'modules/fabrics/constants';
import useFabricsList from 'modules/fabrics/hooks/use-list';
import { getData } from 'modules/fabrics/mappers';

import { PageLoader } from 'components';
import { Input, OptionInputs } from 'components/fields';
import MaterialEditor from 'components/material-editor';
import MaterialPreview from 'components/material-preview';

import cls from './fabric-editor.module.scss';

export interface Options {
  categories: SelectOption[];
  subcategories: SelectOption[];
  fabrics: SelectOption[];
}

export interface SelectOption {
  label: string;
  value: string;
}

const sampleTemplate: Types.IEntity.Fabric = {
  title: '',
  name: '',
  ispublic: false,
  material: {
    type: MaterialType.detail,
    normalmap: '',
    albedomap: '',
    roughnessmap: '',
    metallicmap: '',
    displacementmap: '',
    displacementscale: 0.5,
    scale: 1.0,
    rotation: 0.0,
    off_set: 0.0
  },
  composition: [],
  density: 0,
  thickness: 0,
  thumbnailurl: ''
};

const FabricEditor: FC = () => {
  const { id } = useParams();
  const { isLoading, items, refetch } = useFabricsList();
  const [currentItem, setCurrentItem] = useState<Types.IEntity.Fabric>();

  useEffect(() => {
    if (id) {
      if (items.length) {
        refetch();
      }
      const fabric = items.find(item => item.id === id);

      setCurrentItem(fabric);
    } else {
      setCurrentItem(sampleTemplate);
    }
  }, [isLoading]);

  return isLoading || !currentItem ? (
    <PageLoader />
  ) : (
    <div className={cls.wrapper}>
      <Form.Update
        item={currentItem}
        component={({ control, register, formState: { errors, isSubmitting }, resetField, watch, setValue }) => (
          <>
            <TopBar
              childrenLeft={
                <div className={cls['content-left--title']}>
                  <Link to="/fabrics">
                    <Icon name="back" size={14} />
                  </Link>
                  <div className={cls['content-left--divider']} />
                  <Typography size={22} weight={500} lineHeight="25" className={cls.title}>
                    Edit fabric
                  </Typography>
                </div>
              }
              className={cls['top-bar']}
              childrenRight={
                <Button loading={isSubmitting} className={cls['top-bar--btn']} type="green" htmlType="submit" disabled={isSubmitting}>
                  {id ? 'Update' : 'Create'}
                </Button>
              }
            />
            <div className={cls.content}>
              <div className={cls['content-left']}>
                <div className={cls['content-left-form']}>
                  <Row gutter={[24, 24]}>
                    <Typography
                      className={cls.subtitle}
                      size={22}
                      infoContent="Fill in information about the template you are trying to create/update"
                    >
                      Fabric details
                    </Typography>
                    <Col span={24}>
                      <Input
                        control={control}
                        name="title"
                        type="text"
                        withCount
                        maxLength={50}
                        placeholder="Title"
                        errorMsg={errors?.title?.message}
                      />
                    </Col>
                    <Col span={24}>
                      <Input
                        disabled={!!id}
                        control={control}
                        name="name"
                        type="text"
                        withCount
                        maxLength={50}
                        placeholder="Identifier"
                        errorMsg={errors?.name?.message}
                      />
                    </Col>
                    <Col span={24}>
                      <Input
                        control={control}
                        name="thickness"
                        type="number"
                        withCount
                        maxLength={3}
                        placeholder="Thickness (mm)"
                        errorMsg={errors?.thickness?.message}
                      />
                    </Col>
                    <Col span={24}>
                      <Input
                        control={control}
                        name="density"
                        type="number"
                        withCount
                        maxLength={3}
                        placeholder="Density (g/m2)"
                        errorMsg={errors?.density?.message}
                      />
                    </Col>
                    {/* <Col span={24}>
                      {Object.keys(currentItem.composition).map((key: string) => (
                        <InputKeyValue control={control} name={`composition.${key}`} type="number" withCount maxLength={3} placeholder="Density (g/m2)" errorMsg={errors?.density?.message} />
                      ))}
                    </Col> */}
                    <Collapse
                      content={
                        <Col span={24} className={cls.addUpload}>
                          <MaterialEditor
                            control={control}
                            name="material"
                            resetField={resetField}
                            register={register}
                            errorMsg={errors?.material?.message}
                          />
                        </Col>
                      }
                    >
                      <Typography className={cls.subtitle} size={22} weight={500}>
                        Material
                      </Typography>
                    </Collapse>
                    <Collapse
                      content={
                        <Col span={24}>
                          <OptionInputs control={control} name="composition" />
                        </Col>
                      }
                    >
                      <Typography className={cls.subtitle} size={22} weight={500}>
                        Composition
                      </Typography>
                    </Collapse>
                  </Row>
                  <div style={{ margin: 20 }} />
                </div>
              </div>
              <div className={cls['content-right']}>
                <MaterialPreview data={getData({ ...watch() })} />
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default FabricEditor;
