import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { message } from 'antd';
import { useForm, UseFormReturn } from 'react-hook-form';

import { register } from '../api';
import { useContext } from '../context';
import { IForm } from '../types';

import { registerSchema } from './schema';

interface RegisterProps {
  onSuccess?: (value: IForm.Register) => void;
  children: (data: UseFormReturn<IForm.Register>) => React.ReactNode;
  onError?: (error: any) => void;
}

const Register: React.FC<RegisterProps> = ({ onSuccess, children }) => {
  const { state, setState } = useContext();
  const data = useForm<IForm.Register>({
    resolver: yupResolver(registerSchema),
    defaultValues: { email: state.email, password: '', confirm: '' }
  });

  const onSubmit = async (data: IForm.Register) => {
    try {
      const { user } = await register(data.email, data.password);

      if (!user.emailVerified) setState(p => ({ ...p, step: 'verification-message' }));
      else if (onSuccess) onSuccess(data);
    } catch (err: any) {
      message.error(err.message);
    }
  };

  return <form onSubmit={data.handleSubmit(onSubmit)}>{children(data)}</form>;
};

export default Register;
