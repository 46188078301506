import { UploadFile } from 'antd';

import { GenderType, MaterialType } from './constants';

export namespace IEntity {
  export interface Template {
    id?: string;
    gender: GenderType;
    alias: string;
    createdat?: {
      value: string;
      label: string;
    };
    garmenttype?: string;
    updatedat?: {
      value: string;
      label: string;
    };
    ispublic: boolean;
    title: string;
    title_ru: string;
    name: string;
    category: string;
    subcategory: string;
    fabrics: string[];
    materials: Material[];
    images: Image[];
    options: Option[];
    variants: Variant[];
    silhouette: Silhouette;
    requires_styled_simulation: boolean;
  }

  export interface Meta {
    neckline: NecklineOptions;
    sleeves: SleeveOptions;
    detail: DetailOptions;
    silhouette: SilhouetteOptions;
    clasp: ClaspOptions;
    length: LengthOptions;
  }

  export type NecklineOptions = 'Square Neck' | 'Roundneck' | 'V Neck' | 'One Shoulder' | 'Open Shoulders' | '';
  export type SleeveOptions =
    | 'Winglet Sleeve'
    | 'Tightsleeved'
    | 'Shirt Sleeve'
    | 'Longsleeved'
    | 'Shortsleeved'
    | 'Volume Sleeve'
    | '3/4 Sleeves'
    | 'Sleeveless'
    | 'Strapless'
    | 'Raglan'
    | 'One-piece'
    | 'Off shoulder'
    | 'Doublesleeved';
  export type SilhouetteOptions = 'Loose' | 'Semi-fitted' | 'Fitted';
  export type DetailOptions = 'Bow' | 'Basque' | 'Fringe' | 'Flounces / Ruffles / Frills' | 'Open back' | 'Cuts / Cutouts' | 'Pockets';
  export type ClaspOptions = 'Claspless' | 'Lock' | 'Buttons' | 'Snaps' | 'Hook and Loop' | 'Magnets' | 'Zip' | 'Button up' | 'Laces';
  export type LengthOptions = 'Long' | 'Mid-Length' | 'Short' | 'Knee';

  export interface Silhouette {
    front: string;
    back: string;
  }

  export interface Material {
    name: string;
    color: string;
    id: string;
    type: MaterialType;
    albedoMap?: string | UploadFile | null;
    normalMap?: string | UploadFile | null;
    roughnessMap: string | UploadFile | null;
    metallicMap: string | UploadFile | null;
    offset: number;
    rotation: number;
    scale: number;
  }

  export interface Image {
    id: string;
    url: string;
  }

  export interface Option {
    name: string;
    values: string[];
  }

  export interface Variant {
    imageId: string;
    materialId: string;
    zrpjProjectUrl: string;
    zrpjProjectUrlDefaultPose: string;
    objectUrl: string;
    objectUrlDefaultPose: string;
    options: string[];
  }

  //   export type IAction = 'edit' | 'archive' | 'remove';
}

export namespace IQuery {
  export interface List {
    items: IEntity.Template[];
  }
  export type DeleteList = Array<string>;
}

export namespace IApi {
  export namespace List {
    export interface Request {}
    export interface Response extends Array<IEntity.Template[]> {}
  }

  export namespace DeleteList {
    export type Request = Array<string>;
    export type Response = Array<string>;
    export interface Error {
      error: string;
    }
  }
}
