import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { Button, TopBar, Typography } from 'comfi-ui-kit/lib/components';
import { queryClient } from 'index';
import { Home } from 'layouts';
import { Api, Constants, Types } from 'modules/fabrics';
import useFabricsList from 'modules/fabrics/hooks/use-list';
import { useMutation } from 'react-query';

import { Profile } from 'components';

import List from './list/list';

import cls from './fabrics.module.scss';

const Templates: FC = () => {
  const navigate = useNavigate();
  const { isLoading, items, refetch } = useFabricsList();
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  useEffect(() => {
    refetch();
  }, []);

  // useEffect(() => {
  //   let newItem: Types.IEntity.Fabric;
  //   const newItems: Types.IEntity.Fabric[] = [];
  //   items.length &&
  //     map(items, async (item: Types.IEntity.Fabric) => {
  //       newItem = {
  //         id: item.id,
  //         material: {
  //           scale: item.material.scale,
  //           off_set: item.material.offset,
  //           rotation: item.material.rotation,
  //           albedomap: item.material.albedomap,
  //           roughnessmap: item.material.roughnessmap,
  //           metallicmap: item.material.metallicmap,
  //           type: item.material.type
  //         },
  //         ispublic: item.ispublic,
  //         name: item.name,
  //         title: item.title,
  //         thickness: item.thickness,
  //         thumbnailurl: item.thumbnailurl,
  //         composition: item.composition,
  //         density: item.density
  //       };
  //       delete newItem.material.offset;
  //       newItems.push(newItem);
  //       // @ts-ignore
  //     }).then(() => Api.UpdateFabric(newItems));
  // });

  const deleteMutate = useMutation<Types.IQuery.DeleteList, Types.IApi.DeleteList.Error, Types.IApi.DeleteList.Request, any>(
    async (selectedRowKeys: Types.IApi.DeleteList.Request) => {
      setTableLoading(true);
      await Api.DeleteFabrics(selectedRowKeys).then(e => console.log('response', e));

      return selectedRowKeys;
    },
    {
      onError: (err: any) => {
        console.log('err', err);
        message.error('Error');
        setTableLoading(false);
      },
      onSuccess: async values => {
        await refetch();
        setSelectedRowKeys([]);
        queryClient.invalidateQueries([Constants.FABRICS, 'list']);
        message.success('Template successfully deleted');
        setTableLoading(false);
      }
    }
  );

  const updateVisibility = useMutation<Types.IForm.Update, Types.IUpdateApi.Update.Error, Types.IUpdateApi.Update.Request, any>(
    async (updatedItem: Types.IEntity.Fabric) => {
      setTableLoading(true);
      await Api.UpdateFabric(updatedItem);

      return updatedItem;
    },
    {
      onError: (err: any) => {
        message.error('Error');
        setTableLoading(false);
      },
      onSuccess: async values => {
        await refetch();
        queryClient.invalidateQueries([Constants.FABRICS, 'list']);
        message.success('Fabric successfully updated');
        setTableLoading(false);
      }
    }
  );

  const handleDeleteRows = async (selectedRowKeys: any) => {
    await deleteMutate.mutateAsync(selectedRowKeys);
    refetch();
  };

  const handleRowChange = (e: string[]) => {
    setSelectedRowKeys(e);
  };

  const handleVisibilityChange = async (e: boolean, item: Types.IEntity.Fabric) => {
    if (item.ispublic !== e) {
      const updatedItem: Types.IEntity.Fabric = {
        id: item.id,
        material: item.material,
        thumbnailurl: item.thumbnailurl,
        title: item.title,
        name: item.name,
        density: item.density,
        thickness: item.thickness,
        ispublic: e,
        composition: item.composition
      };

      await updateVisibility.mutateAsync(updatedItem);
    }
  };

  return (
    <Home>
      <div className={cls.wrapper}>
        <TopBar
          className={cls.wrap}
          childrenRight={
            <div className={cls['right-header']}>
              {items.length! > 0 && (
                <Button className={cls.button} onClick={() => navigate('/fabrics/editor')}>
                  Create a new fabric
                </Button>
              )}
              <Profile />
            </div>
          }
        />
        <Typography size={24} weight={650} className={cls.title} lineHeight="28">
          Fabrics
        </Typography>
        <List
          items={items}
          loading={isLoading}
          tableLoading={tableLoading}
          setTableLoading={setTableLoading}
          onDelete={handleDeleteRows}
          selectedRowKeys={selectedRowKeys}
          handleRowChange={handleRowChange}
          handleVisibilityChange={handleVisibilityChange}
        />
      </div>
    </Home>
  );
};

export default Templates;
