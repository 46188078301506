import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { Icon, Sidebar } from 'comfi-ui-kit/lib/components';

import logo from 'assets/images/logo.svg';

import classes from './home.module.scss';

interface IProps {
  children: React.ReactNode;
}

export type AccountDetails = {
  logo: string;
  name: string;
  id: string;
};

const MainLayout: FC<IProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setIsOpen] = useState<boolean>(true);

  const handleToggle = () => {
    setIsOpen(!open);
  };

  return (
    <div className={classes.main}>
      <div className={cx(classes['main-sidebar'], open && classes['main-sidebar-open'])}>
        <Sidebar
          isOpen={() => handleToggle()}
          open={open}
          top={
            <div className={classes['main-sidebar-top']}>
              <img src={logo} alt="" />
              <p>BuduAR</p>
            </div>
          }
          items={[
            {
              key: '/templates',
              icon: <Icon name="request" size={18} />,
              label: 'Templates',
              onClick: () => navigate('/templates')
            },
            {
              key: '/fabrics',
              icon: <Icon name="polygon" size={18} />,
              label: 'Fabrics',
              onClick: () => navigate('/fabrics')
            },
            {
              key: '/patterns',
              icon: <Icon name="cards" size={18} />,
              label: 'Patterns',
              onClick: () => navigate('/patterns')
            },
            {
              key: '/simulations',
              icon: <Icon name="giftBox" size={18} />,
              label: 'Simulations',
              onClick: () => navigate('/simulations')
            },
            {
              key: '/animations',
              icon: <Icon name="circleLoader" size={18} />,
              label: 'Animations',
              onClick: () => navigate('/animations')
            }
          ]}
          selectedKeys={[location.pathname]}
          onLogo={() => window.open('http://buduar.space', '_blank')}
        />
      </div>
      <div className={classes['main-content']}>{children}</div>
    </div>
  );
};

export default MainLayout;
