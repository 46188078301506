import { UploadFile } from 'antd';

import { MaterialType } from './constants';

export namespace IEntity {
  export interface Fabric {
    id?: string;
    createdat?: {
      value: string;
      label: string;
    };
    updatedat?: {
      value: string;
      label: string;
    };
    ispublic: boolean;
    title: string;
    name: string;
    thumbnailurl: string | UploadFile;
    material: Material;
    density: number;
    composition: CompositionOption[];
    thickness: number;
  }

  export interface CompositionOption {
    name: string;
    value: number;
  }

  export interface Material {
    type: MaterialType;
    scale: number;
    off_set: number;
    rotation: number;
    albedomap?: string | UploadFile | null;
    normalmap?: string | UploadFile | null;
    roughnessmap?: string | UploadFile | null;
    metallicmap?: string | UploadFile | null;
    displacementmap?: string | UploadFile | null;
    displacementscale?: number;
  }

  export interface Image {
    id: string;
    url: string;
  }
  //   export type IAction = 'edit' | 'archive' | 'remove';
}

export namespace IQuery {
  export interface List {
    items: IEntity.Fabric[];
  }
  export type DeleteList = Array<string>;
}

export namespace IApi {
  export namespace List {
    export interface Request {}
    export interface Response extends Array<IEntity.Fabric[]> {}
  }

  export namespace DeleteList {
    export type Request = Array<string>;
    export type Response = Array<string>;
    export interface Error {
      error: string;
    }
  }
}

export namespace IForm {
  export interface Update extends IEntity.Fabric {}
}

export namespace IUpdateApi {
  export namespace Update {
    export interface Request extends IEntity.Fabric {}
    export interface Response extends IEntity.Fabric {}

    export interface Error {
      error: string;
    }
  }
}
