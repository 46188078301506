import React from 'react';

const MasterCard: React.FC = () => (
  <svg width="50" height="31" viewBox="0 0 50 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.0625 9.99805H21.9375V21.0036H28.0625V9.99805Z" fill="#FF5F00" />
    <path
      d="M22.3266 15.5C22.3266 13.2639 23.3766 11.2806 24.9905 9.99722C23.8044 9.06389 22.3072 8.5 20.6738 8.5C16.8044 8.5 13.6738 11.6306 13.6738 15.5C13.6738 19.3694 16.8044 22.5 20.6738 22.5C22.3072 22.5 23.8044 21.9361 24.9905 21.0028C23.3766 19.7389 22.3266 17.7361 22.3266 15.5Z"
      fill="#EB001B"
    />
    <path
      d="M36.3264 15.5C36.3264 19.3694 33.1959 22.5 29.3264 22.5C27.6931 22.5 26.1959 21.9361 25.0098 21.0028C26.6431 19.7194 27.6737 17.7361 27.6737 15.5C27.6737 13.2639 26.6237 11.2806 25.0098 9.99722C26.1959 9.06389 27.6931 8.5 29.3264 8.5C33.1959 8.5 36.3264 11.65 36.3264 15.5Z"
      fill="#F79E1B"
    />
    <rect x="0.5" y="1" width="49" height="29" rx="7.5" stroke="black" strokeOpacity="0.15" />
  </svg>
);

export default MasterCard;
