import { auth } from 'config/firebase';

export const getAccessToken = (): Promise<string> =>
  new Promise((resolve, reject) => {
    auth.onAuthStateChanged(async user => {
      if (!user) reject(Error('Not found firebase user'));
      else {
        const token = await user!.getIdToken();

        resolve(token);
      }
    });
  });
