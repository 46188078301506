import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { Button, TopBar, Typography } from 'comfi-ui-kit/lib/components';
import { queryClient } from 'index';
import { Home } from 'layouts';
import { Api, Constants, Types } from 'modules/patterns';
import usePatternsList from 'modules/patterns/hooks/use-list';
import { useMutation } from 'react-query';

import { Profile } from 'components';

import List from './list/list';

import cls from './patterns.module.scss';

const Templates: FC = () => {
  const navigate = useNavigate();
  const { isLoading, items, refetch } = usePatternsList();
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  useEffect(() => {
    refetch();
  }, []);

  // useEffect(() => {
  //   let newItem: Types.IEntity.UpdatePattern;

  //   const newItems: Types.IEntity.UpdatePattern[] = [];

  //   items.length &&
  //     map(items, async (item: Types.IEntity.Pattern) => {
  //       newItem = {
  //         id: item?.id,
  //         title: item?.title,
  //         name: item?.name,
  //         public: false,
  //         tags: item?.tags,
  //         masks: item?.masks,
  //         scale: item?.material.scale,
  //         thumbnailurl: item?.thumbnailurl,
  //         off_set: item?.material.off_set,
  //         rotation: item?.material.rotation,
  //         albedomap: item?.material.albedomap,
  //         type: item?.material.type
  //       };
  //       newItems.push(newItem);
  //       // @ts-ignore
  //     }).then(() => Api.UpdatePattern(newItems));
  // });

  const deleteMutate = useMutation<Types.IQuery.DeleteList, Types.IApi.DeleteList.Error, Types.IApi.DeleteList.Request, any>(
    async (selectedRowKeys: Types.IApi.DeleteList.Request) => {
      setTableLoading(true);
      await Api.DeletePatterns(selectedRowKeys).then(e => console.log('response', e));

      return selectedRowKeys;
    },
    {
      onError: (err: any) => {
        console.log('err', err);
        message.error('Error');
        setTableLoading(false);
      },
      onSuccess: async values => {
        await refetch();
        setSelectedRowKeys([]);
        queryClient.invalidateQueries([Constants.PATTERNS, 'list']);
        message.success('Template successfully deleted');
        setTableLoading(false);
      }
    }
  );

  const updateVisibility = useMutation<Types.IEntity.UpdatePattern, Types.IUpdateApi.Update.Error, Types.IUpdateApi.Update.Request, any>(
    async (updatedItem: Types.IEntity.UpdatePattern) => {
      setTableLoading(true);
      await Api.UpdatePattern(updatedItem);

      return updatedItem;
    },
    {
      onError: (err: any) => {
        message.error('Error');
        setTableLoading(false);
      },
      onSuccess: async values => {
        await refetch();
        queryClient.invalidateQueries([Constants.PATTERNS, 'list']);
        message.success('Fabric successfully updated');
        setTableLoading(false);
      }
    }
  );

  const handleDeleteRows = async (selectedRowKeys: any) => {
    await deleteMutate.mutateAsync(selectedRowKeys);
    refetch();
  };

  const handleRowChange = (e: string[]) => {
    setSelectedRowKeys(e);
  };

  const handleVisibilityChange = async (e: boolean, item: Types.IEntity.Pattern) => {
    if (item.ispublic !== e) {
      const updatedItem: Types.IEntity.UpdatePattern = {
        id: item?.id,
        title: item?.title,
        name: item?.name,
        ispublic: e,
        tags: item?.tags,
        masks: item?.masks,
        scale: item?.material.scale,
        thumbnailurl: item?.thumbnailurl,
        off_set: item?.material.off_set,
        rotation: item?.material.rotation,
        albedomap: item?.material.albedomap,
        type: item?.material.type
      };

      await updateVisibility.mutateAsync(updatedItem);
    }
  };

  return (
    <Home>
      <div className={cls.wrapper}>
        <TopBar
          className={cls.wrap}
          childrenRight={
            <div className={cls['right-header']}>
              {items.length! > 0 && (
                <Button className={cls.button} onClick={() => navigate('/patterns/editor')}>
                  Create a new pattern
                </Button>
              )}
              <Profile />
            </div>
          }
        />
        <Typography size={24} weight={650} className={cls.title} lineHeight="28">
          Patterns
        </Typography>
        <List
          items={items}
          loading={isLoading}
          tableLoading={tableLoading}
          setTableLoading={setTableLoading}
          onDelete={handleDeleteRows}
          selectedRowKeys={selectedRowKeys}
          handleRowChange={handleRowChange}
          handleVisibilityChange={handleVisibilityChange}
        />
      </div>
    </Home>
  );
};

export default Templates;
