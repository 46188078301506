import { FC, useState } from 'react';
import cx from 'classnames';

import logo from 'assets/images/logo.svg';

import cls from './overview.module.scss';

interface IProps {}

const AuthInfo: FC<IProps> = () => {
  const [view, setView] = useState(false);

  return (
    <div className={cx(cls.wrapper, view && cls['full-height'])}>
      <div className={cls['left-card']}>
        <div className={cls['star-card']}>
          <div className={cls.star}>{/* <img src={star7} alt="star" /> */}</div>
          <div className={cls.star}>{/* <img src={star7_small} alt="star" /> */}</div>
        </div>
        <div className={cls['text-card']}>
          <img src={logo} alt="" />
          <p className={cls.title}>Dashboard for BuduAR Modellers</p>
        </div>
      </div>
      <div className={cls['right-card']}>{/* <img src={circle} alt="circle" /> */}</div>
    </div>
  );
};

export default AuthInfo;
