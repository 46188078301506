import React from 'react';

import * as Types from '../types';

import Context from './context';

interface IProps extends Types.IContext.Value {
  children: React.ReactNode;
}

const Provider: React.FC<IProps> = ({ children, ...value }) => <Context.Provider value={{ ...value }}>{children}</Context.Provider>;

export default Provider;
