import get from 'lodash/get';
import moment from 'moment';

import { MaterialProps } from 'components/material-preview/material-preview';

import * as Types from './types';

export const textureTypes: string[] = ['albedomap', 'normalmap', 'roughnessmap', 'metallicmap', 'displacementmap'];

export const Fabric = (item: any): Types.IEntity.Fabric => {
  textureTypes.forEach((textureType: string) => {
    if (!item.material[textureType]) {
      item.material[textureType] = '';
    }
  });

  return {
    id: get(item, 'id'),
    createdat: {
      value: get(item, 'createdat'),
      label: moment(get(item, 'createdat')).format('MMMM DD, YYYY')
    },
    updatedat: {
      value: get(item, 'updateat'),
      label: moment(get(item, 'updateat')).format('MMMM DD, YYYY')
    },
    ispublic: get(item, 'ispublic') || false,
    title: get(item, 'title') || '',
    name: get(item, 'name') || '',
    material: get(item, 'material') || {},
    thumbnailurl: get(item, 'thumbnailurl') || '',
    density: get(item, 'density') || 0,
    thickness: get(item, 'thickness') || 0,
    composition: get(item, 'composition') || {}
  };
};

export const getData = (item: Types.IEntity.Fabric): MaterialProps => ({
  material: get(item, 'material')
});
