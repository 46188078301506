import { UploadFile } from 'antd';
import { http } from 'services';

export const Upload = (path: string, data: any): Promise<any> => http.request.put(`/${path}`, data);

export const getDownloadUrl = (url: string) => `${process.env.REACT_APP_BASE_URL}/${url[0]}`;

const uploadObject = async (path: string, fileName: string, file: UploadFile) => {
  const formData = new FormData();
  // @ts-ignore
  const newFile = new File([file], fileName);
  // @ts-ignore

  formData.append('files', newFile);

  const url = await Upload(path, formData);

  return `${process.env.REACT_APP_BASE_URL}/${url.data[0]}`;
};

export default uploadObject;
