import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card } from 'antd';
import { Button, Collapse, Icon, TopBar, Typography } from 'comfi-ui-kit/lib/components';
import { Col, Row } from 'comfi-ui-kit/lib/components/grid';
import { findIndex } from 'lodash';
import { Types as FabricTypes } from 'modules/fabrics';
import useFabricsList from 'modules/fabrics/hooks/use-list';
import { Form } from 'modules/template-editor';
import { getData } from 'modules/template-editor/mappers';
import { Types } from 'modules/templates-main';
import { garmentTypeSelectOptions, sampleTemplate } from 'modules/templates-main/constants';
import useTemplateList from 'modules/templates-main/hooks/use-list';
import ModelPreview from 'pages/template-editor/components/model-preview';

import { ImageUpload, ObjUpload, PageLoader, SilhouetteUpload } from 'components';
import { Input, TagInput } from 'components/fields';
import MultiMaterialEditor from 'components/multi-material-editor/multi-material-editor';
import Switcher from 'components/switcher/switcher';

import cls from './template-editor.module.scss';

export interface Options {
  categories: SelectOption[];
  subcategories: SelectOption[];
  fabrics: SelectOption[];
  garmenttype: SelectOption[];
}

export interface SelectOption {
  label: string;
  value: string;
}

const AddRequest: FC = () => {
  const { id } = useParams();
  const { isLoading, items, refetch } = useTemplateList();
  const { isLoading: isFabricsLoading, items: fabrics, refetch: fetchFabrics } = useFabricsList();
  const [options, setOptions] = useState<Options>({
    categories: [],
    subcategories: [],
    fabrics: [],
    garmenttype: garmentTypeSelectOptions
  });
  const [sindex, setSIndex] = useState<number>(0);
  const [currentItem, setCurrentItem] = useState<Types.IEntity.Template>();

  const getSIndex = (template: Types.IEntity.Template) => {
    const SIndex = findIndex(template?.variants, x => x.options.includes('S'));

    setSIndex(SIndex);
  };

  const getTagOptions = () => {
    const tempCats: SelectOption[] = [];
    const tempSubCats: SelectOption[] = [];
    const tempFabrics: SelectOption[] = [];

    items.forEach((item: Types.IEntity.Template) => {
      if (!tempCats.some(({ label }) => label === item.category)) {
        tempCats.push({ label: item.category, value: item.category });
      }
      if (!tempSubCats.some(({ label }) => label === item.subcategory)) {
        tempSubCats.push({ label: item.subcategory, value: item.subcategory });
      }
    });
    fabrics.forEach((item: FabricTypes.IEntity.Fabric) => {
      if (!tempFabrics.some(({ value }) => value === item.name)) {
        tempFabrics.push({ label: item.title, value: item.name });
      }
    });

    setOptions({ categories: tempCats, subcategories: tempSubCats, fabrics: tempFabrics, garmenttype: garmentTypeSelectOptions });
  };

  useEffect(() => {
    if (id) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      items.length ?? refetch();
      const template = items.find(item => item.id === id);

      setCurrentItem(template);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      template && getSIndex(template);
      console.log('templateNow', template);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      items.length && getTagOptions();
    } else {
      setCurrentItem(sampleTemplate);
      getSIndex(sampleTemplate);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    items.length && getTagOptions();
  }, [isLoading]);

  return isLoading || !currentItem ? (
    <PageLoader />
  ) : (
    <div className={cls.wrapper}>
      <Form.Update
        item={currentItem}
        component={({ control, register, formState: { errors, isSubmitting }, resetField, watch, setValue }) => (
          <>
            <TopBar
              childrenLeft={
                <div className={cls['content-left--title']}>
                  <Link to="/templates">
                    <Icon name="back" size={14} />
                  </Link>
                  <div className={cls['content-left--divider']} />
                  <Typography size={22} weight={500} lineHeight="25" className={cls.title}>
                    Edit a template
                  </Typography>
                </div>
              }
              className={cls['top-bar']}
              childrenRight={
                <Button loading={isSubmitting} className={cls['top-bar--btn']} type="green" htmlType="submit" disabled={isSubmitting}>
                  {id ? 'Update' : 'Create'}
                </Button>
              }
            />
            <div className={cls.content}>
              <div className={cls['content-left']}>
                <div className={cls['content-left-form']}>
                  <Row gutter={[24, 24]}>
                    <Typography
                      className={cls.subtitle}
                      size={22}
                      infoContent="Fill in information about the template you are trying to create/update"
                    >
                      Template details
                    </Typography>
                    <Col span={24}>
                      <Input
                        control={control}
                        name="title"
                        type="text"
                        withCount
                        maxLength={50}
                        placeholder="Title EN"
                        errorMsg={errors?.title?.message}
                      />
                      <Input
                        control={control}
                        name="title_ru"
                        type="text"
                        withCount
                        maxLength={50}
                        placeholder="Title RU"
                        errorMsg={errors?.title_ru?.message}
                      />
                    </Col>
                    <Col span={24}>
                      <Input
                        control={control}
                        name="name"
                        type="text"
                        withCount
                        maxLength={50}
                        placeholder="Name"
                        errorMsg={errors?.name?.message}
                      />
                    </Col>
                    <Col span={24}>
                      <TagInput
                        options={options.categories}
                        control={control}
                        name="category"
                        type="text"
                        maxLength={50}
                        placeholder="Category"
                        errorMsg={errors?.category?.message}
                      />
                    </Col>
                    <Col span={24}>
                      <TagInput
                        options={options.subcategories}
                        control={control}
                        name="subcategory"
                        type="text"
                        maxLength={50}
                        placeholder="Subcategory"
                        errorMsg={errors?.subcategory?.message}
                      />
                    </Col>
                    <Col span={24}>
                      <TagInput
                        options={options.fabrics}
                        control={control}
                        name="fabrics"
                        type="text"
                        maxLength={100}
                        placeholder="Choose fabrics"
                        errorMsg={errors?.fabrics?.message}
                      />
                    </Col>
                    <Col span={24}>
                      <TagInput
                        options={options.garmenttype}
                        control={control}
                        name="garmenttype"
                        type="text"
                        maxLength={100}
                        placeholder="Choose type"
                        errorMsg={errors?.garmenttype?.message}
                      />
                    </Col>
                    <Col span={24}>
                      <Switcher
                        control={control}
                        name="requires_styled_simulation"
                        errorMsg={errors?.requires_styled_simulation?.message}
                      />
                    </Col>
                    <Collapse
                      content={
                        <Col span={24} className={cls.addUpload}>
                          <ImageUpload
                            control={control}
                            name="images"
                            resetField={resetField}
                            index={sindex}
                            errorMsg={errors?.images?.message}
                          />
                        </Col>
                      }
                    >
                      <Typography className={cls.subtitle} size={22} weight={500}>
                        Images
                      </Typography>
                    </Collapse>
                    <Collapse
                      content={
                        <Col span={24} className={cls.addUpload}>
                          <MultiMaterialEditor
                            control={control}
                            name="materials"
                            resetField={resetField}
                            register={register}
                            errorMsg={errors?.materials?.message}
                          />
                        </Col>
                      }
                    >
                      <Typography className={cls.subtitle} size={22} weight={500}>
                        Materials
                      </Typography>
                    </Collapse>
                    <Collapse
                      content={
                        <>
                          <Col span={24}>
                            <Card title="S-size A-pose .obj" size="small">
                              <ObjUpload
                                fileTypes={['obj']}
                                maxCount={1}
                                control={control}
                                name={`variants.${sindex}.objectUrl`}
                                resetField={resetField}
                                errorMsg={errors?.variants?.message}
                              />
                            </Card>
                            <Card title="S-size Default Pose .obj" size="small">
                              <ObjUpload
                                fileTypes={['obj']}
                                maxCount={1}
                                control={control}
                                name={`variants.${sindex}.objectUrlDefaultPose`}
                                resetField={resetField}
                                errorMsg={errors?.variants?.message}
                              />
                            </Card>
                          </Col>
                        </>
                      }
                    >
                      <Typography className={cls.subtitle} size={22} weight={500}>
                        Objects
                      </Typography>
                    </Collapse>
                    <Collapse
                      content={
                        <>
                          <Col span={24}>
                            <Card title=".zprj project A-pose" size="small">
                              <ObjUpload
                                control={control}
                                name={`variants.${sindex}.zrpjProjectUrl`}
                                resetField={resetField}
                                fileTypes={['zprj']}
                                errorMsg={errors?.variants?.message}
                              />
                            </Card>
                            <Card title=".zprj project default-pose" size="small">
                              <ObjUpload
                                control={control}
                                name={`variants.${sindex}.zrpjProjectUrlDefaultPose`}
                                resetField={resetField}
                                fileTypes={['zprj']}
                                errorMsg={errors?.variants?.message}
                              />
                            </Card>
                          </Col>
                        </>
                      }
                    >
                      <Typography className={cls.subtitle} size={22} weight={500}>
                        Zrpj Project
                      </Typography>
                    </Collapse>
                    {id ? (
                      <Collapse
                        content={
                          <>
                            <Col span={24}>
                              <Card title="Silhouettes" size="small">
                                {Object.keys(currentItem?.silhouette!).map((side: string, index: number) => (
                                  <SilhouetteUpload
                                    key={side}
                                    control={control}
                                    name={`silhouette.${side}`}
                                    side={side}
                                    resetField={resetField}
                                    errorMsg={errors?.silhouette?.message}
                                  />
                                ))}
                              </Card>
                            </Col>
                          </>
                        }
                      >
                        <Typography className={cls.subtitle} size={22} weight={500}>
                          Silhouettes
                        </Typography>
                      </Collapse>
                    ) : null}
                  </Row>
                  <div style={{ margin: 20 }} />
                </div>
              </div>
              <div className={cls['content-right']}>
                <ModelPreview data={getData({ ...watch() })} />
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default AddRequest;
