import React from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Row, Select } from 'antd';
import { InputProps as BaseInputProps } from 'antd/lib/input';
import { Icon } from 'comfi-ui-kit/lib/components';
import { Types } from 'modules/fabrics';
import { Control, Controller, useFieldArray } from 'react-hook-form';

import cls from './option-inputs.module.scss';

export interface InputKeyValueProps
  extends Pick<BaseInputProps, 'type' | 'disabled' | 'placeholder' | 'maxLength' | 'min' | 'pattern' | 'step'> {
  errorMsg?: string;
  control: Control<any>;
  name: string;
}

const sampleComposition: Types.IEntity.CompositionOption = {
  name: 'Cotton',
  value: 100
};

const fabricOptions = [
  'Cotton',
  'Spandex',
  'Wool',
  'Stretch Yarn',
  'Polyurethane',
  'Rayon',
  'Leather',
  'Linen',
  'Polyester',
  'Acetat',
  'Polyvinylchlorid',
  'Cupro',
  'Viscose',
  'Elastan',
  'Laycra',
  'Microfibra',
  'Metallised',
  'Modal',
  'Nylon',
  'Acrylic',
  'Paper',
  'Polimede',
  'Polyuretan',
  'Silk',
  'Viscose',
  'Kashmir',
  'Fleece Wool',
  'Polyamide'
];

const OptionInputs: React.FC<InputKeyValueProps> = ({ errorMsg, control, name, placeholder }) => {
  const { fields, append, remove } = useFieldArray({ name, control });

  const getAddButton = () => (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: 16 }}>
      <Button onClick={() => append(sampleComposition)} type="primary" size="large">
        <PlusOutlined />
        Add composition
      </Button>
    </div>
  );

  return (
    <div className={cls.wrapper}>
      {fields.map((field, index) => (
        <Row key={field.id}>
          <Input.Group compact style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <Controller
              control={control}
              name={`${name}.${index}.name`}
              render={({ field }) => (
                <Select style={{ height: 'auto' }} className={cls.antSelector} {...field}>
                  {fabricOptions.map((fabric: string) => (
                    <Select.Option key={fabric} value={fabric}>
                      {fabric}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
            <Controller
              control={control}
              name={`${name}.${index}.value`}
              render={({ field }) => <Input style={{ width: '45%' }} allowClear id={name} {...field} type="number" />}
            />
            <Button
              style={{ marginLeft: 8 }}
              onClick={() => remove(index)}
              type="primary"
              shape="round"
              icon={<DeleteOutlined />}
              size="small"
            />
          </Input.Group>
        </Row>
      ))}
      {getAddButton()}
      {errorMsg && (
        <div className={cls.message}>
          <Icon name="exclamation" /> <span>{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

export default OptionInputs;
