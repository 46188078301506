import React, { ComponentType } from 'react';
import { useNavigate } from 'react-router-dom';
import { uuidv4 } from '@firebase/util';
import { yupResolver } from '@hookform/resolvers/yup';
import { message } from 'antd';
import { filter, map } from 'modern-async';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { uploadFile } from 'services/functions';

import * as Constants from '../constants';
import * as Types from '../types';
import { Api } from '..';

import { updateSchema } from './schema';
// import { deleteAndReuploadFirebaseFile } from 'services/functions/upload-file';

interface UpdateProps {
  defaultValues?: Partial<Types.IForm.Update>;
  onSuccess?: (values: Types.IForm.Update) => void;
  onError?: (values: Types.IUpdateApi.Update.Error) => void;
  component: ComponentType<UseFormReturn<Types.IForm.Update>>;
  item: Types.IForm.Update;
}

const Update: React.FC<UpdateProps> = ({ onSuccess, onError, defaultValues = {}, component: Component, item }) => {
  const navigate = useNavigate();
  // const { user } = useAuth();
  const queryClient = useQueryClient();

  const data = useForm<Types.IForm.Update>({
    resolver: yupResolver(updateSchema),
    defaultValues: {
      id: item?.id,
      title: item?.title,
      name: item?.name,
      ispublic: item?.ispublic,
      material: item?.material,
      composition: item?.composition,
      density: item?.density,
      thickness: item?.thickness,
      thumbnailurl: item?.thumbnailurl
    }
  });

  const mutate = useMutation<Types.IForm.Update, Types.IUpdateApi.Update.Error, Types.IUpdateApi.Update.Request, any>(
    async (values: any) => {
      await Api.UpdateFabric(values);

      return values;
    },
    {
      onError: (err: any) => {
        message.error(err.data.error);
        onError?.(err);
      },
      onSuccess: values => {
        onSuccess?.(values);

        queryClient.invalidateQueries([Constants.FABRICS, 'list']);
        message.success('Fabric was successfully updated');
        navigate('/fabrics');
      }
    }
  );

  const onSubmit = async (data: Types.IForm.Update) => {
    const storagePathId = data?.id ? data?.id : uuidv4();

    await map(await filter(Object.keys(data.material), (m: string) => m.includes('map')), async (m: string) => {
      /* @ts-ignore */
      if (data.material[m].type) {
        /* @ts-ignore */
        await uploadFile(`fabrics/${storagePathId}/textures`, data.material[m]).then(async (e: any) => {
          // @ts-ignore
          data.material[m] = e;
        });
      }
      return data.material;
    });

    /* @ts-ignore */
    if (data.thumbnailurl.type) {
      /* @ts-ignore */
      await uploadFile(`fabrics/${storagePathId}`, data.thumbnailurl, 0.5, 512).then(async (e: any) => {
        // @ts-ignore
        data.thumbnailurl = e;
      });
    }

    await mutate.mutateAsync({
      ...(data.id && { id: data.id }),
      title: data.title,
      name: data.name,
      ispublic: data.ispublic,
      material: data.material,
      composition: data.composition,
      density: data.density,
      thickness: data.thickness,
      thumbnailurl: data.thumbnailurl
    });
  };

  return (
    <form onSubmit={data.handleSubmit(onSubmit)}>
      <Component {...data} />
    </form>
  );
};

export default Update;
