import { Navigate, Outlet } from 'react-router-dom';

export interface ProtectedRouteProps {
  allow: boolean;
  navigate: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ navigate, allow }) => {
  if (!allow) return <Navigate to={navigate} />;

  return <Outlet />;
};

export default ProtectedRoute;
