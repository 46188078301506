import { combineReducers } from '@reduxjs/toolkit';

/** reducers */
import auth from './auth';

/** Main reducer function */
export default combineReducers({ auth });

/** Export selectors and action functions */
export * from './auth';
export * from './entities';
