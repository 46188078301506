import { auth } from 'config/firebase';
import {
  ActionCodeSettings,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword
} from 'firebase/auth';

export const sendPasswordReset = (email: string, options?: ActionCodeSettings) =>
  sendPasswordResetEmail(auth, email, { url: window.location.href, handleCodeInApp: true });

export const login = (email: string, password: string) => signInWithEmailAndPassword(auth, email, password);

export const register = (email: string, password: string) => createUserWithEmailAndPassword(auth, email, password);

export const sendVerification = () => sendEmailVerification(auth.currentUser!, { url: window.location.href, handleCodeInApp: true });

export const passwordReset = (oobCode: string, newPassword: string) => confirmPasswordReset(auth, oobCode, newPassword);
