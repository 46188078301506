import React from 'react';
import { PlusOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { message, Upload as AntUpload } from 'antd';
import { InputProps as BaseInputProps } from 'antd/lib/input';
import { Control, Controller, useController, UseFormResetField } from 'react-hook-form';

import cls from './image-upload.module.scss';

export interface UploadProps extends Pick<BaseInputProps, 'type' | 'disabled' | 'placeholder'> {
  className?: string;
  errorMsg?: string;
  control: Control<any>;
  name: string;
  resetField: UseFormResetField<any>;
  index: number;
}

const ImageUpload: React.FC<UploadProps> = ({ control, name, resetField, errorMsg, index }) => {
  const { field: variantField } = useController({
    name: `variants.${0}`,
    control
  });

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleCoverUpdate = (file: any | null) => {
    const tempField = variantField.value;
    const id = file?.name ? file.uid : file.id;

    if (file) {
      if (tempField.imageId === id) {
        tempField.imageId = '';
      } else tempField.imageId = id;
    } else {
      tempField.imageId = '';
    }
    variantField.onChange(tempField);
  };

  const handleChange = (newList: any, field: any) => {
    console.log('newList', newList.file);
    const id = newList?.file.name ? newList?.file.uid : newList?.file.id;

    if (variantField.value.imageId === id && newList.file?.status === 'removed') {
      handleCoverUpdate(null);
    }
    field.onChange(newList.fileList);
  };

  return (
    <div className={cls.wrapper}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <AntUpload
            beforeUpload={file => {
              const isPNG = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg';

              if (!isPNG) {
                message.error(`${file.name} is not a png/jpg/jpeg file`);
              }

              return isPNG || AntUpload.LIST_IGNORE;
            }}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            fileList={field.value}
            iconRender={() => <span>hello</span>}
            multiple
            onPreview={handleCoverUpdate}
            showUploadList={{
              previewIcon: (file: any) => {
                const id = file.name ? file.uid : file.id;

                return variantField.value.imageId === id ? <StarFilled /> : <StarOutlined />;
              }
            }}
            onChange={newList => handleChange(newList, field)}
          >
            {uploadButton}
          </AntUpload>
        )}
      />
      {errorMsg && (
        <div className={cls.message}>
          <span>{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
