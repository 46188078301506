import { FC } from 'react';

const Lock: FC = () => (
  <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.03989 12.5C5.68044 12.5 3.32122 12.5 0.961767 12.5C0.952392 12.4937 0.94372 12.4843 0.933642 12.4817C0.32497 12.3345 0.00106389 11.9237 0.00106389 11.2972C0.000829513 10.1609 0.00106389 9.02469 0.00106389 7.88867C0.00106389 7.29898 -0.00198299 6.70953 0.00223576 6.11984C0.00598576 5.59906 0.352158 5.16406 0.854658 5.03492C0.969033 5.00563 1.08528 5.00094 1.2027 4.99883C1.2988 4.99719 1.42419 5.04266 1.48349 4.97937C1.54138 4.91727 1.49989 4.79328 1.50036 4.69672C1.50247 4.27906 1.49755 3.86117 1.5027 3.44328C1.51817 2.19055 2.35138 1.05664 3.53849 0.658906C3.76841 0.581563 4.00653 0.546875 4.24302 0.5C4.41481 0.5 4.58685 0.5 4.75864 0.5C4.8695 0.519453 4.98083 0.536094 5.09099 0.559062C6.5031 0.853203 7.49192 2.0607 7.50036 3.50187C7.50294 3.96641 7.50247 4.43117 7.49919 4.8957C7.49872 4.97422 7.51395 5.0082 7.6002 5.00164C7.70122 4.99414 7.80317 4.99836 7.90466 5.00094C8.50184 5.01617 8.99567 5.49875 8.99778 6.09617C9.00388 7.865 9.00247 9.63359 8.99825 11.4024C8.99708 11.8648 8.69474 12.272 8.2595 12.4299C8.18731 12.4564 8.11302 12.4768 8.03989 12.5ZM4.48888 5C5.11747 5 5.7463 4.99695 6.37489 5.00281C6.4827 5.00375 6.50216 4.9707 6.50099 4.87203C6.49583 4.42297 6.49864 3.97391 6.49864 3.52508C6.49864 3.37648 6.48856 3.22883 6.45599 3.08328C6.22888 2.07078 5.31411 1.40797 4.28427 1.51063C3.2856 1.61 2.51614 2.46289 2.50395 3.48992C2.49833 3.95445 2.5063 4.41922 2.5002 4.88375C2.4988 4.98664 2.53419 5.00281 2.62653 5.00211C3.24716 4.99813 3.86802 5 4.48888 5Z"
      fill="black"
    />
  </svg>
);

export default Lock;
